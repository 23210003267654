import { createSelector } from '@reduxjs/toolkit';

import { IItemsCategory } from 'types/entities';
import { BasicGroup } from "config/constants.config";
import { AppSelector } from 'store/store.d';
import { createDefaultSelectors, CustomSelectors } from 'store/helpers/entities';


export interface ICategoriesSelectors extends CustomSelectors<IItemsCategory> {
  aggregatedCategories: AppSelector<IItemsCategory[]>;
  materialCategories: AppSelector<IItemsCategory[]>;
  laborCategories: AppSelector<IItemsCategory[]>;
  gutterCategories: AppSelector<IItemsCategory[]>;
}

const defaultSelectors = createDefaultSelectors('category', {
  getFullState: state => state.associatedItems.categories
});

const buildCategoriesByBasicGroupSelector = (basicGroup: BasicGroup) =>
  createSelector(
    defaultSelectors.getEntities,
    (categories) =>
      categories.filter(category => category.basicGroup === basicGroup)
  );

const aggregatedCategories = buildCategoriesByBasicGroupSelector(null);

const materialCategories = buildCategoriesByBasicGroupSelector(BasicGroup.MATERIALS);

const laborCategories = buildCategoriesByBasicGroupSelector(BasicGroup.LABOR);

const gutterCategories = buildCategoriesByBasicGroupSelector(BasicGroup.GUTTER);

export default {
  ...defaultSelectors,
  aggregatedCategories,
  materialCategories,
  laborCategories,
  gutterCategories
};
