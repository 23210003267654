import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as allSelectors from './selectors';


export enum EstimateWizardStep {
  customer = 'customer',
  items = 'items',
  materials = 'materials',
  labors = 'labors',
  gutter = 'gutter',
  summary = 'summary'
}

export interface EstimateWizardState {
  currentStep: EstimateWizardStep;
  customer: boolean;
  items: boolean;
  materials: boolean;
  labors: boolean;
  gutter: boolean;
  summary: boolean;
}

const initialState: EstimateWizardState = {
  currentStep: EstimateWizardStep.customer,
  customer: false,
  items: false,
  materials: false,
  labors: false,
  gutter: false,
  summary: false
};

export const { reducer, actions } = createSlice({
  name: 'estimateWizard',
  initialState,
  reducers: {
    updateStep(state, action: PayloadAction<EstimateWizardStep>) {
      state[action.payload] = true;
    },
    goToStep(state, action: PayloadAction<EstimateWizardStep>) {
      state.currentStep = action.payload;
    },
    saveSteps: state => ({
      ...initialState,
      currentStep: state.currentStep
    }),
    reset: (state, action: PayloadAction<EstimateWizardStep>) => ({
      ...initialState,
      currentStep: action.payload || initialState.currentStep
    })
  }
});

export const selectors = allSelectors;
