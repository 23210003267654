import { makeStyles } from "@material-ui/core/styles";
import { dangerColor, primaryColor, successColor, warningColor } from "assets/jss/common";


export const useStyles = makeStyles(theme => ({
  cardTitleIcon: {
    float: "left",
    marginRight: "10px",
    fontSize: "30px"
  },
  typeIcon: {
    color: theme.palette.grey["700"]
  },
  // TODO: avoid duplicating styles from estimatesStyle.js
  status: {
    display: "inline-block",
    padding: theme.spacing(.25, 1),
    wordBreak: "keep-all",
    borderRadius: 4,
    color: "white",
    opacity: .8,
    "&:hover": {
      opacity: 1
    }
  },
  statusDraft: {
    background: dangerColor[0]
  },
  statusPending: {
    background: warningColor[0]
  },
  statusSigned: {
    background: successColor[0]
  },
  statusClosed: {
    background: primaryColor[0]
  },
  income: {
    wordBreak: "keep-all",
    color: successColor[0]
  },
  costs: {
    wordBreak: "keep-all",
    color: dangerColor[0]
  }
}));
