import { matchPath } from 'react-router';
import { Location } from 'history';

import { allRoutes, pageTitles } from 'config/routing.config';


// first, try to find an exact match; if nothing is found, find the first matching path
export const getActiveRoutePath = (location: Location) => {
  const exactMatch = allRoutes.find(path => matchPath(location.pathname, {
    path,
    exact: true
  }));

  return exactMatch || allRoutes.find(path => matchPath(location.pathname, path)) || null;
};

export const getTitleByLocation = (location: Location) => {
  let match = '';

  for (let path in pageTitles) {
    if (matchPath(location.pathname, path))
      match = pageTitles[path];
  }

  return match;
};
