import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from 'store/store.d';
import { forgetAll } from "store/notifications";


export default function Notifier() {
  const { notifications, dismissedProgrammatically } = useSelector<AppState, AppState['notifications']>(state => state.notifications);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // whenever notifications are added to the store, enqueue them in the Notistack, and remove them from the store;
  // whenever dismissedProgrammatically array in store contains any values,
  //  dismiss the corresponding snackbars and reset the array in the store
  if (notifications.length || dismissedProgrammatically.length) {
    notifications.forEach(notification => {
      enqueueSnackbar(notification.message, notification);
    });

    dismissedProgrammatically.forEach(closeSnackbar);

    dispatch(forgetAll());
  }

  return null;
}
