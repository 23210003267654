import { UserRole } from "config/constants.config";
import { AppState } from 'store/store.d';


const getAuthToken = (state: AppState) => state.auth.token;

const isAuthenticated = (state: AppState) => !!state.auth.token;

const isLoading = (state: AppState) => state.auth.loading;

const getError = (state: AppState) => state.auth.error;

const getRole = (state: AppState) => state.auth.user.role;

const isOrdinaryUser = (state: AppState) => !isManagerOrAdmin(state);

const isManagerOrAdmin = (state: AppState) => {
  const role = getRole(state);
  return role === UserRole.ADMIN || role === UserRole.MANAGER;
};

const isAdmin = (state: AppState) => {
  return getRole(state) === UserRole.ADMIN;
};

export {
  getAuthToken,
  isAuthenticated,
  isLoading,
  getError,
  getRole,
  isOrdinaryUser,
  isManagerOrAdmin,
  isAdmin
};
