import { IItemsCategory } from 'types/entities';
import CategoriesService from 'services/entities/categories.service';
import { createEntitySlice, EntitiesState } from 'store/helpers/entities';
import categoriesSelectors, { ICategoriesSelectors } from './selectors';


export const { actions, reducer, selectors } = createEntitySlice<IItemsCategory, ICategoriesSelectors>({
  name: 'category',
  service: CategoriesService,
  skipDefaultSelectors: true,
  selectors: categoriesSelectors
});

export type CategoriesState = EntitiesState<IItemsCategory>;
