import { IUnit } from 'types/entities';
import UnitsService from 'services/entities/units.service';
import { createEntitySlice, EntitiesState } from 'store/helpers/entities';


export const { actions, reducer, selectors } = createEntitySlice<IUnit>({
  name: 'unit',
  service: UnitsService,
  selectors: {
    getFullState: state => state.associatedItems.units
  }
});

export type UnitsState = EntitiesState<IUnit>;
