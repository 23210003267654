import { connect } from 'react-redux';

import { IInAppNotification } from 'types/entities';
import { AppState } from 'store/store.d';
import { actions, selectors } from 'store/entities/notifications';
import { actions as notifierActions } from 'store/notifications';
import InAppNotifications, { IInAppNotificationsProps } from './InAppNotifications';


type StateProps = Pick<IInAppNotificationsProps, 'notifications'|'loading'>;
type DispatchProps = Pick<IInAppNotificationsProps, 'markNotificationsAsRead'|'loadNotifications'|'addNotification'|'closeNotification'>;

const mapStateToProps = (state: AppState): StateProps => ({
  notifications: selectors.getEntities(state) as IInAppNotification[], // why is it not working without type-cast?
  loading: selectors.isLoading(state)
});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  markNotificationsAsRead: (notifications: IInAppNotification[]) => dispatch(actions.markNotificationsAsRead(notifications)),
  loadNotifications: () => dispatch(actions.read()),
  addNotification: (notification: IInAppNotification, renderCb: (key: string) => React.ReactNode) => {
    dispatch(actions.addNotification(notification));
    dispatch(notifierActions.showNotification({
      message: notification.content.body,
      autoHideDuration: null,
      content: renderCb,
      anchorOrigin: {
        horizontal: "right",
        vertical: "bottom"
      }
    }));
  },
  closeNotification: (key: string) => {
    dispatch(notifierActions.closeNotification(key));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(InAppNotifications);
