import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";


interface ICreateActionOptions {
  defaults?: object;
}

export function useCreateAction(actionCreators, selectors, options: ICreateActionOptions = {}) {
  const { entity, loading, error } = useSelector(selectors.getCreatingState);
  const dispatch = useDispatch();

  const reset = useCallback(() => {
    if (entity)
      dispatch(actionCreators.createLocal(null));
  }, [entity]);

  const createLocal = useCallback((defaults = options.defaults) => {
    dispatch(actionCreators.createLocal(defaults || {}));
  }, [options.defaults]);

  const patchLocal = useCallback(patch => {
    dispatch(actionCreators.createLocal({
      ...entity,
      ...patch
    }));
  }, [entity]);

  const dispatchCreate = useCallback((payload = entity) => {
    dispatch(actionCreators.create(payload));
  }, [entity]);

  return [{
    entity,
    loading,
    error
  }, {
    create: createLocal,
    patch: patchLocal,
    commit: dispatchCreate,
    reset
  }];
}
