import { connect } from 'react-redux';

import { actions } from "store/associatedItems";
import AdminItemsModule, { IAdminItemsModuleProps } from './AdminItemsModule';


type DispatchProps = Pick<IAdminItemsModuleProps, 'loadEverything'>;

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  loadEverything: () => dispatch(actions.loadAssociatedItems())
});

export default connect(null, mapDispatchToProps)(AdminItemsModule);
