import React from 'react';
import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router';

import { adminRoutes } from 'config/routing.config';
import AdminItemsModule from 'modules/admin-items';
import UsersPage from './UsersPage/UsersPage';
import TemplatesPage from './TemplatesPage/TemplatesPage';


export interface IAdminModuleProps extends RouteChildrenProps {

}

const AdminModule: React.FunctionComponent<IAdminModuleProps> = () => {
  return (
    <Switch>
      <Route path={adminRoutes.USERS} component={UsersPage} />
      <Route path={adminRoutes.TEMPLATES} component={TemplatesPage} />
      <Route path={adminRoutes.ITEMS} component={AdminItemsModule} />
      <Redirect to={adminRoutes.DEFAULT} />
    </Switch>
  );
};

export default AdminModule;
