import { IFormula } from 'types/entities';
import { EntityService } from '../entity.service';


export interface IBulkUpdatePayload {
  create?: NotPersistedEntity<IFormula>[];
  update?: Partial<IFormula>[];
  delete?: EntityId[];
}

export interface IBulkUpdateResponse {
  created: IFormula[];
  updated: IFormula[];
  deleted: EntityId[];
}

class FormulasService extends EntityService<IFormula> {
  readonly entityName = 'formula';

  // this service uses only `read` action from `EntityService`
  create: null = null;
  update: null = null;
  delete: null = null;

  bulkUpdate(payload: IBulkUpdatePayload) {
    return this.api.post<IBulkUpdateResponse>("formulas/bulk-update", {
      create: payload.create || [],
      update: payload.update || [],
      delete: payload.delete || []
    });
  }
}

export default new FormulasService();
