import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Info } from '@material-ui/icons';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';

import { IInAppNotification } from 'types/entities';
import { useStyles } from './NotificationsListStyle';


interface INotificationsListProps {
  notifications: IInAppNotification[];
  loading: boolean;
  markAsRead: (notifications: IInAppNotification[], close?: true) => void;
}

export default function NotificationsList(props: INotificationsListProps) {
  const classes = useStyles();
  const { notifications, loading, markAsRead } = props;
  const unread = notifications.filter(n => !n.read);

  return (
    <>
      <div className={classes.header}>
        <h3 className={classes.title}>{notifications.length} notifications</h3>
        {unread.length > 0 && (
          <a className={classes.link} href="javascript:void(0)" onClick={() => markAsRead(notifications, true)}>
            Mark all as read
          </a>
        )}
      </div>
      <List>
        {loading && (
          <ListItem className={classes.loadingContainer}>
            <CircularProgress color="secondary" title="Loading notifications..." />
          </ListItem>
        )}
        {notifications.length === 0 && !loading && (
          <ListItem>
            <div className={classes.listPlaceholder}>
              You don't have any notifications yet...
            </div>
          </ListItem>
        )}
        {notifications.map((notification, i) => (
          <>
            {i > 0 && <Divider component="li" key={`notification-divider-${notification.id}`} />}
            <ListItem
              key={`notification-item-${notification.id}`}
              className={cn(classes.item, !notification.read && classes.clickableNotification)}
              onClick={!notification.read ? (() => markAsRead([notification])) : null}
            >
              <ListItemIcon>
                <Badge variant="dot" color="error" invisible={notification.read} overlap="circle">
                  <Info />
                </Badge>
              </ListItemIcon>
              <ListItemText
                primary={notification.content.body}
                secondary={moment(notification.createdAt).fromNow()}
              />
            </ListItem>
          </>
        ))}
      </List>
    </>
  );
}
