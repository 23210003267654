import { makeStyles } from '@material-ui/core/styles';


export default makeStyles(theme => ({
  container: {
    paddingRight: 4,
    display: "flex",
    justifyContent: "stretch",
    "&:hover $removeButton": {
      visibility: "visible"
    }
  },
  input: {
    width: 100,
    flexShrink: 0,
    background: "rgba(255, 255, 255, .7)"
  },
  select: {
    flexGrow: 1,
    background: "rgba(255, 255, 255, .7)"
  },
  inputAdorement: {
    marginLeft: -4,
    fontSize: "small",
    color: theme.palette.text.hint
  },
  separator: {
    display: "inline-block",
    margin: theme.spacing(0, 1),
    fontSize: 24,
    color: theme.palette.text.hint
  },
  removeButton: {
    visibility: "hidden",
    marginLeft: 4,
    color: theme.palette.grey["500"],
    transition: "color 200ms ease-in-out",

    "&:hover": {
      color: theme.palette.error.main
    }
  }
}));
