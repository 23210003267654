import { makeStyles } from "@material-ui/core/styles";
import { grayColor } from "assets/jss/common";


export const useStyles = makeStyles(theme => ({
  modalRoot: {
    overflow: "auto",
    alignItems: "unset",
    justifyContent: "unset"
  },
  modal: {
    width: "95%",
    borderRadius: "6px",
    marginTop: "100px !important",
    overflow: "visible",
    maxHeight: "unset",
    position: "relative",
    height: "fit-content",
    [theme.breakpoints.up("sm")]: {
      margin: "auto",
      width: "450px"
    }
  },
  modalHeader: {
    borderBottom: "none",
    paddingTop: "24px",
    paddingRight: "12px",
    paddingBottom: "0",
    paddingLeft: "24px",
    minHeight: "16.43px"
  },
  modalTitle: {
    margin: "0",
    lineHeight: "1.42857143",
    textTransform: "capitalize"
  },
  modalCloseButton: {
    color: grayColor[0],
    marginTop: "-12px",
    WebkitAppearance: "none",
    padding: "0",
    cursor: "pointer",
    background: "0 0",
    border: "0",
    fontSize: "inherit",
    opacity: ".9",
    textShadow: "none",
    fontWeight: 700,
    lineHeight: "1",
    float: "right"
  },
  modalClose: {
    width: "16px",
    height: "16px"
  },
  modalBody: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "16px",
    paddingLeft: "24px",
    position: "relative"
  },
  modalFooter: {
    padding: "15px",
    textAlign: "right",
    paddingTop: "0",
    margin: "0"
  },
  formControl: {
    flex: "0 0 100%",
    verticalAlign: "top",
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down("xs")]: {
      flexBasis: "100%",
      maxWidth: "100% !important",
      margin: theme.spacing(1, 0),
      marginLeft: "0 !important"
    }
  },
  formControlInline: {
    flex: "1 1 20%",
  },
  error: {
    marginTop: theme.spacing(0),
    textAlign: "right",
    fontWeight: 400,
    fontSize: "small",
    color: theme.palette.error.dark
  },
  fieldLabel: {
    color: grayColor[3]
  }
}));
