import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowDropDown, FileCopyTwoTone } from "@material-ui/icons";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";

import { showNotification } from "store/notifications";

import styles from "./clipboardLinkStyle";

const useStyles = makeStyles(styles);

export default function ClipboardLink(props) {
  const { options, url, text, className } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const anchorRef = useRef(null);
  const dispatch = useDispatch();

  const currentOption = options ? options[selectedIndex] : { url, text: text || url};

  function handleMenuItemClick(event, index) {
    setSelectedIndex(index);
    setOpen(false);
  }

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  function copy() {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(currentOption.url);

      dispatch(showNotification({
        message: "The URL is copied to your clipboard",
        variant: "info",
        autoHideDuration: 3000,
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      }));
    } else {
      dispatch(showNotification({
        message: window.location.protocol.match(/^https/)
          ? "This feature is not supported in your browser"
          : "This feature requires secure HTTPS connection",
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      }));
    }
  }

  return (
    <div className={className}>
      <ButtonGroup fullWidth variant="outlined" color="primary" aria-label="split button">
        <Tooltip title="Copy to clipboard" placement="top">
          <Button fullWidth={false} onClick={copy}>
            <FileCopyTwoTone />
          </Button>
        </Tooltip>

        <Tooltip title="Open in new tab" placement="top">
          <Button fullWidth className={classes.btnText} href={currentOption.url} target="_blank" disabled={currentOption.isDisabled}>
            {currentOption.text}
          </Button>
        </Tooltip>

        { options && Object.keys(options).length > 1 && (
          <Tooltip title="Chose link variant" placement="top">
            <Button
              fullWidth={false}
              ref={anchorRef}
              size="small"
              aria-owns={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <ArrowDropDown />
            </Button>
          </Tooltip>
        )}
      </ButtonGroup>

      { options && (
        <Popper open={open} anchorEl={anchorRef.current} placement="bottom-start" transition>
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "left bottom"
              }}
            >
              <Paper id="menu-list-grow">
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.url}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                      >
                        {option.text}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </div>
  );
}
