import React, { useMemo } from "react";
import { Redirect, Route } from 'react-router';

import { withStyles, WithStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";

import { DocumentType } from "config/constants.config";
import { CKEditorPDF, CKEditorEmail } from "config/ckeditor.config";
import { adminTemplatesRoutes } from 'config/routing.config';
import { useReadAction } from "common/hooks/crud/useReadAction";
import { useUpdateAction } from "common/hooks/crud/useUpdateAction";
import TabsLayout from 'layouts/TabsLayout/TabsLayout';
import { actions as templatesActions, selectors as templatesSelectors } from "store/entities/templates";
import TemplateEditor from "./TemplateEditor/TemplateEditor";
import { styles } from "./tamplateStyle";


const TemplatesPage: React.FunctionComponent<WithStyles<typeof styles>> = (props) => {
  const { classes } = props;

  const [templates] = useReadAction(templatesActions, templatesSelectors);
  const [updating, actions] = useUpdateAction(templatesActions, templatesSelectors);

  const contractTemplate = useMemo(() => {
    return templates.all.find(t => t.documentType === DocumentType.CONTRACT);
  }, [templates.all]);

  const invoiceTemplate = useMemo(() => {
    return templates.all.find(t => t.documentType === DocumentType.INVOICE);
  }, [templates.all]);

  const emailTemplate = useMemo(() => {
    return templates.all.find(t => t.documentType === DocumentType.EMAIL);
  }, [templates.all]);

  const editors = useMemo(() => [
    {
      path: adminTemplatesRoutes.CONTRACT,
      template: contractTemplate,
      config: CKEditorPDF
    },
    {
      path: adminTemplatesRoutes.INVOICE,
      template: invoiceTemplate,
      config: CKEditorPDF
    },
    {
      path: adminTemplatesRoutes.EMAIL,
      template: emailTemplate,
      config: CKEditorEmail
    }
  ], [templates.all]);

  const loading = templates.loading;

  return (
    <TabsLayout disabled={!!updating.entity}>
      {loading && (
        <div className={classes.launcher}>
          <CircularProgress size={60} color="secondary" />
        </div>
      )}
      {editors.map(options => (
        <Route path={options.path} key={'template-editor-' + options.path}>
          {!loading && options.template && (
            <TemplateEditor
              template={options.template}
              hasChanges={!!updating.entity}
              content={updating.entity ? updating.entity.content : options.template.content}
              loading={updating.loading}
              actions={actions}
              config={options.config}
            />
          )}
        </Route>
      )) as any}
      <Redirect to={adminTemplatesRoutes.DEFAULT} />
    </TabsLayout>
  );
};

export default withStyles(styles)(TemplatesPage);
