import { createStyles } from '@material-ui/core/styles';


export const styles = () => createStyles({
  launcher: {
    width: "100%",
    padding: "20vh 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});
