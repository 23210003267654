import { createStyles, Theme } from '@material-ui/core/styles';
import { containerFluid } from 'styles';


export const styles = (theme: Theme) => createStyles({
  appBar: {
    paddingBottom: theme.spacing(1)
  },
  container: {
    width: '100%',
    ...containerFluid
  },
  content: {
    flex: 1
  },
  title: {
    lineHeight: "30px",
    fontSize: "18px",
    fontWeight: 300,
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    margin: "0",
    "&:hover,&:focus": {
      background: "transparent"
    }
  },
  sidebarMiniIcon: {
    width: "20px",
    height: "17px"
  }
});
