/**
 * This util contains important formulas used for financial calculations of estimates balance.
 * Front-end calculations are used temporarily when user changes some values, before sending the estimate to the server.
 * The same calculations happen separately on back-end (Helpers::Finance), so make sure the same formulas are used
 * on front-end and back-end.
 *
 * Estimate balance object looks like this:
 * b = {
 *   items: 1234,        // readonly: total costs (for customer) of all the items in the estimate
 *   materials: 123,     // readonly: total costs of materials (without taxes)
 *   labor: 123,         // readonly: total costs of labor
 *   gutter: 123,        // readonly: total costs of gutter labor & materials
 *   materialTax: 0.08,  // material tax rate, added to the costs as well as to the incomes, so that the customer has to pay it
 *   overhead: 0.1,      // extra rate added on top of `items`
 *   profitRate: 0.1,    // extra rate added on top of `items`, separate from overhead for legal reasons
 *   discountAmount: 50, // absolute amount for discount, subtracted from the incomes
 *   totalIncome: 1234,  // readonly: calculated by formula based on other values
 *   totalCosts: 1234    // readonly: calculated by formula based on other values
 * }
 */

export const BALANCE_DEFAULTS = {
  materialTax: .08,
  overhead: .1,
  profitRate: .1,
  discountAmount: 10
};

export const recalculateTotals = b => ({
  ...b,
  totalIncome: getTotalIncome(b),
  totalCosts: getTotalCosts(b)
});

export const getTotalIncome = b =>
  getTaxedIncome(b)
  + getOverheadAmount(b)
  + getExtraProfitAmount(b)
  - (b.discountAmount || 0);

export const getTotalCosts = b => getSubtotalCosts(b) + getMaterialTaxAmount(b);

export const getTaxedIncome = b => getSubtotalIncome(b) + getMaterialTaxAmount(b);

export const getSubtotalIncome = b => b.items;
export const getSubtotalCosts = b => b.materials + b.labor + b.gutter;

export const getMaterialTaxAmount = b => b.materialTax * b.materials;

// note: overhead and extra profit are calculated before taxation, not after
export const getOverheadAmount = b => b.overhead * getSubtotalIncome(b);
export const getExtraProfitAmount = b => b.profitRate * getSubtotalIncome(b);

export const getNetProfit = b => b.totalIncome - b.totalCosts;
export const getCapOut = b => (b.totalIncome - b.totalCosts) / b.totalIncome;
