import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import compact from "lodash/compact";
import cn from "classnames";
import qs from "query-string";
import {
  House,
  Assessment,
  LineWeight,
  LockOpenTwoTone,
  LockTwoTone
} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";

import { EstimateStatus, EstimateType } from "config/constants.config";
import { formatCurrency } from "common/utils/format.util";
import { useReadAction } from "common/hooks/crud/useReadAction";
import { useUpdateAction } from "common/hooks/crud/useUpdateAction";
import { useDeleteAction } from "common/hooks/crud/useDeleteAction";
import TableCRUD from "common/views/TableCRUD/TableCRUD";
import GridContainer from "common/components/Grid/GridContainer";
import GridItem from "common/components/Grid/GridItem";

import { xsBreakpoint, lgBreakpoint } from "config/common.config";
import { selectors as authSelectors } from "store/auth";
import { actions as estimatesActions, selectors as estimatesSelectors } from "store/entities/estimates";
import { actions as customersActions, selectors as customersSelectors } from "store/entities/customers";
import { actions as usersActions, selectors as usersSelectors } from "store/entities/users";

import useStyles from "./estimatesStyle";


function EstimatesPage({ history, location }) {
  useReadAction(usersActions, usersSelectors);
  const [customers] = useReadAction(customersActions, customersSelectors);

  const classes = useStyles();
  const isAdmin = useSelector(authSelectors.isAdmin);
  const filters = qs.parse(location.search) || {};
  const [state] = useReadAction(estimatesActions, estimatesSelectors);
  const [updatingState, updateActions] = useUpdateAction(estimatesActions, estimatesSelectors);
  const [deletingEntity, deletionActions] = useDeleteAction(estimatesActions, {
    confirmDeletion: true
  });

  const selectedCustomer = useMemo(
    () => customers.byKey[+filters.customerId],
    [filters.customerId, customers.byKey]
  );

  const actions = useMemo(() => ({
    delete: deletingEntity ? deletionActions.commit : deletionActions.delete,
    reset: deletionActions.reset,
    update: estimate => history.push(`/estimates/${estimate ? estimate.id : "new"}/wizard`),
  }), [deletionActions]);

  const createEstimate = estimateType => () => {
    const customerId = filters.customerId ? '&customerId=' + filters.customerId : '';
    history.push(`/estimates/new/wizard?type=${estimateType}${customerId}`);
  };

  const openCloseActions = useCallback(estimate => {
    if (estimate.status !== EstimateStatus.DRAFT)
      return [{
        title: estimate.status === EstimateStatus.CLOSED ? "Reopen" : "Close",
        type: "secondary",
        Icon: estimate.status === EstimateStatus.CLOSED ? LockOpenTwoTone : LockTwoTone,
        callback: () => updateActions.commit({
          id: estimate.id,
          status: estimate.status === EstimateStatus.CLOSED ? null : EstimateStatus.CLOSED
        })
      }];
  }, []);

  const filteredEstimates = useMemo(
    () => selectedCustomer
      ? state.all.filter(estimate => estimate.customerId === selectedCustomer.id)
      : state.all,
    [state.all, selectedCustomer]
  );

  const tableProps = useMemo(() => ({
    defaultSortBy: [{ id: "updatedAt", desc: true }],
    recordAction: estimate => history.push(`/estimates/${estimate.id}/wizard?step=summary`)
  }), []);

  const columns = useMemo(
    () => compact([
      window.innerWidth > xsBreakpoint && {
        Header: "",
        accessor: "type",
        style: { width: 0, paddingTop: 4, paddingBottom: 0 },
        title: ({ value }) => value,
        disableFilters: true,
        Cell: ({ cell: { value }}) =>
          value === EstimateType.ROOFING
            ? <House className={classes.typeIcon} />
            : <LineWeight className={classes.typeIcon} />
      },
      {
        Header: "Customer",
        accessor: "customer.name",
        style: { minWidth: "12vw" }
      },
      {
        Header: "Address",
        accessor: "customer.address",
        title: ({ value }) => value,
        style: { maxWidth: "12vw", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }
      },
      {
        Header: "Updated",
        accessor: "updatedAt",
        sortDescFirst: true,
        disableFilters: true,
        style: { minWidth: "10vw" },
        title: ({ value }) => moment(value).format("DD MMM YYYY, HH:mm"),
        Cell: ({ cell: { value }}) => moment(value).fromNow()
      },
      window.innerWidth > lgBreakpoint && {
        Header: "Author",
        accessor: "user.name"
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value }}) => (
          <span
            className={cn(classes.status, {
              [classes.statusDraft]: value === EstimateStatus.DRAFT,
              [classes.statusPending]: value === EstimateStatus.PENDING,
              [classes.statusSigned]: value === EstimateStatus.SIGNED,
              [classes.statusClosed]: value === EstimateStatus.CLOSED
            })}
          >
            {value}
          </span>
        )
      }, {
        Header: "Income",
        accessor: "balance.totalIncome",
        sortType: "basic",
        Cell: ({ cell: { value }}) => <strong className={classes.income}>{formatCurrency(value)}</strong>
      }, {
        Header: "Costs",
        accessor: "balance.totalCosts",
        sortType: "basic",
        Cell: ({ cell: { value }}) => <strong className={classes.costs}>{formatCurrency(value)}</strong>
      },
      window.innerWidth > xsBreakpoint && {
        Header: "Views",
        accessor: "views",
        sortType: "basic"
      },
      window.innerWidth > lgBreakpoint && {
        Header: "Notes",
        accessor: "comment"
      }
    ]),
    [window.innerWidth]
  );

  return (
    <GridContainer>
      <GridItem xs={12}>
        <TableCRUD
          Icon={Assessment}
          title={selectedCustomer ? `Estimates for ${selectedCustomer.name}` : null}
          crudState={{
            ...state,
            all: filteredEstimates,
            loading: state.loading || updatingState.loading,
            deletingEntity
          }}
          actions={actions}
          extraActions={openCloseActions}
          columns={columns}
          entity="estimate"
          TableProps={tableProps}
          isAuthorizedToDelete={estimate => isAdmin || estimate.status === EstimateStatus.DRAFT}
          getRemovalConfirmation={estimate => `Are you sure you want to delete this estimate?` + (estimate.status === EstimateStatus.SIGNED ? ' It is already SIGNED!' : '')}
        />

        <Tooltip title="Create roofing estimate" placement="left">
          <Fab classes={{ root: cn(classes.creationButton, classes.roofingButton) }}
               onClick={createEstimate(EstimateType.ROOFING)}>
            <House />
          </Fab>
        </Tooltip>

        <Tooltip title="Create siding estimate" placement="left">
          <Fab classes={{ root: cn(classes.creationButton) }}
               onClick={createEstimate(EstimateType.SIDING)}>
            <LineWeight />
          </Fab>
        </Tooltip>
      </GridItem>
    </GridContainer>
  );
}

export default EstimatesPage;
