import { createStyles, Theme } from '@material-ui/core/styles';

import bgUrl from "assets/img/roofs/roof-9-tesla-solar.jpeg";


export const styles = (theme: Theme) => createStyles({
  drawerDesktop: {
    position: 'static',
    zIndex: 1500,
    width: theme.appDrawer.width,
    transition: theme.appDrawer.transition
  },
  drawerDesktopMinimized: {
    width: theme.appDrawer.minimizedWidth
  },
  drawerDesktopFixed: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0
  },
  drawerPaper: {
    position: "relative",
    width: "100%",
    border: "none",
    color: 'white',
    backgroundSize: "cover",
    backgroundPosition: "10% 20vh",
    backgroundRepeat: "no-repeat",
    backgroundColor: "black",
    backgroundImage: `url(${bgUrl})`,
    overflow: 'hidden'
  },
  drawerPaperMobile: {
    width: '75%',
    overflowY: 'visible'
  },
  backgroundOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.75)',
    zIndex: -1,
    pointerEvents: 'none'
  },
  brand: {
    position: "relative",
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0, 1),
    // bottom border
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      height: 1,
      right: theme.spacing(2),
      left: theme.spacing(2),
      backgroundColor: "rgba(255,255,255,0.3)"
    }
  },
  brandLogo: {
    margin: theme.spacing(0, 2, 0, 3),
    float: "left",
    width: 30,
    maxHeight: 30
  },
  brandLogoImg: {
    width: 35,
    verticalAlign: "middle"
  },
  brandText: {
    display: "block",
    padding: theme.spacing(0.5, 0),
    fontSize: 'large',
    whiteSpace: "nowrap",
    fontWeight: 400,
    lineHeight: '30px',
    overflow: "hidden",
    opacity: 1,
    transform: "translate3d(0, 0, 0)",
    transition: "all 300ms linear",
    transitionProperty: 'transform, opacity',
    "&,&:hover,&:focus": {
      color: "inherit"
    }
  },
  brandTextMini: {
    opacity: 0,
    transform: "translate3d(-25px, 0, 0)"
  },
  list: {
    margin: theme.spacing(2, 0, 0),
    padding: 0,
    listStyle: "none",
    color: "inherit"
  },
  nestedList: {
    margin: 0
  }
});
