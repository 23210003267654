import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistPartial } from "redux-persist/es/persistReducer";

import { AppState } from 'store/store.d';
import persist from '../helpers/persistReducer';


export interface UIState {
  minimizedSidebar: boolean|null;
}

export type PersistedUIState = PersistPartial & UIState;

const { actions, reducer: originalReducer } = createSlice({
  name: 'ui',
  initialState: {
    minimizedSidebar: null
  } as UIState,
  reducers: {
    setMinimizedSidebar(state, action: PayloadAction<boolean>) {
      state.minimizedSidebar = action.payload;
    }
  }
});

// save "minimizedSidebar" in the localStorage
const reducer = persist("ui", originalReducer, ["minimizedSidebar"]);

const selectors = {
  isSidebarMinimized: (state: AppState) => state.ui.minimizedSidebar
};

export {
  reducer,
  actions,
  selectors
};
