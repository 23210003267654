import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Build } from "@material-ui/icons";

import { BasicGroup } from "config/constants.config";
import { buildBasicItemsSelector } from "store/associatedItems";
import { selectors as categoriesSelectors} from "store/entities/categories";
import SubItemsTable from "common/views/SubItemsTable/SubItemsTable";


export default function LaborPage() {
  const categories = useSelector(categoriesSelectors.laborCategories);
  const laborSelector = useMemo(
    () => buildBasicItemsSelector(BasicGroup.LABOR),
    []
  );

  return (
    <SubItemsTable
      entity="labor"
      title="Labor"
      Icon={Build}
      getEntities={laborSelector}
      categories={categories}
    />
  );
}
