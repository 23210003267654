import { makeStyles } from "@material-ui/core/styles";

import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  grayColor
} from "assets/jss/common";


export const useStyles = makeStyles(theme => ({
  cardIcon: {
    display: "flex",
    alignItems: "center",
    borderRadius: "3px",
    backgroundColor: grayColor[0],
    padding: "15px",
    marginTop: "-20px",
    marginRight: "15px",
    float: "left",
    color: "white"
  },
  title: {
    margin: theme.spacing(0, 1),
    color: "white",
    fontSize: "large"
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader
}));
