import { IInAppNotification } from 'types/entities';
import { EntityService } from 'services/entity.service';
import NotificationsChannel from 'services/channels/notifications.channel';


class InAppNotificationsService extends EntityService<IInAppNotification> {
  readonly entityName = 'notification';

  // this service uses only `read` action from `EntityService`
  create: null = null;
  update: null = null;
  delete: null = null;

  markAsRead(notifications: IInAppNotification[]) {
    return this.api.post<{}>("notifications/mark-as-read", {
      notifications: notifications.map(n => n.id)
    });
  }

  listenToNotifications(callback: (notification: IInAppNotification) => void) {
    return NotificationsChannel.notification$.subscribe(callback);
  }
}

export default new InAppNotificationsService();
