import { createStyles, Theme } from "@material-ui/core/styles";
import { container, containerFluid } from 'styles';


export const styles = (theme: Theme) => createStyles({
  footer: {
    width: '100%',
    bottom: 0,
    borderTop: "1px solid #e7e7e7",
    padding: "15px 0",
    zIndex: 4
  },
  noBorder: {
    borderTop: 'none'
  },
  container: {
    zIndex: 3,
    ...container,
    position: "relative"
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: "relative"
  },
  link: {
    padding: "15px",
    [theme.breakpoints.down("xs")]: {
      padding: "5px 15px"
    }
  },
  left: {
    float: "left!important" as any,
    display: "block",
    [theme.breakpoints.down("xs")]: {
      float: "none!important" as any
    }
  },
  right: {
    margin: "0",
    fontSize: "14px",
    float: "right!important" as any,
    padding: "15px",
    [theme.breakpoints.down("xs")]: {
      float: "none!important" as any,
      padding: "10px 15px 5px",
      textAlign: "center",
      fontSize: "small"
    }
  },
  center: {
    padding: "15px",
    textAlign: "center"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0",
    width: "auto"
  },
  whiteColor: {
    "&,&:hover,&:focus": {
      color: '#fff'
    }
  },
  copyrightLink: {
    color: "inherit",
    "&:hover, &:focus": {
      color: "inherit",
      textDecoration: "underline"
    }
  }
});
