import { pluralize } from '../common/utils';
import CompanyChannel  from './channels/company.channel';
import api from './api-client.service';


export interface EntityServiceOptions {
  trackServerEntities?: boolean;
}

export abstract class EntityService<EntityType extends Entity> {
  public abstract readonly entityName: string;
  protected api = api;

  public read() {
    return this.api.get<EntityType[]>(this.buildEndpoint())
  }

  public get(id: EntityType['id']) {
    return this.api.get<EntityType>(this.buildEndpoint(id));
  }

  public create(record: NotPersistedEntity<EntityType>) {
    return this.api.post<EntityType>(this.buildEndpoint(), record);
  }

  public update(id: EntityType['id'], record: Partial<EntityType>) {
    return this.api.put<EntityType>(this.buildEndpoint(id || record.id), record);
  }

  public delete(record: Partial<EntityType>) {
    return this.api.delete<void>(this.buildEndpoint(record.id));
  }

  public trackEntitiesLive() {
    return CompanyChannel.trackEntityLive<EntityType>(this.entityName);
  }

  protected buildEndpoint = (id: EntityId = null, entityName = this.entityName) => {
    let endpoint = pluralize(entityName);

    if (id)
      endpoint += `/${id}`;

    return endpoint;
  };
}
