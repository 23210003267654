import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";


export function useUpdateAction(actionCreators, selectors) {
  const { entity, loading, error } = useSelector(selectors.getUpdatingState);
  const dispatch = useDispatch();

  const reset = useCallback(() => {
    if (entity)
      dispatch(actionCreators.updateLocal(null));
  }, [entity]);

  const updateLocal = useCallback(entityToUpdate => {
    dispatch(actionCreators.updateLocal(entityToUpdate));
  }, []);

  const patchLocal = useCallback(patch => {
    dispatch(actionCreators.updateLocal({
      ...entity,
      ...patch
    }));
  }, [entity]);

  const dispatchUpdate = useCallback((payload = entity) => {
    dispatch(actionCreators.update(payload));
  }, [entity]);

  return [{
    entity,
    loading,
    error
  }, {
    update: updateLocal,
    patch: patchLocal,
    commit: dispatchUpdate,
    reset
  }];
}
