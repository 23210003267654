import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { authHeader, baseURL, defaultHeaders, timeout } from "config/api.config";


interface IRequestConfig<FullResponse = boolean> extends AxiosRequestConfig {
  fullResponse?: FullResponse;
}

type ResponseInterceptor = (response: AxiosResponse) => AxiosResponse|void;
type ResponseErrorInterceptor = (error: AxiosError) => AxiosResponse|void;


const axiosInst = axios.create({
  baseURL,
  timeout,
  headers: {
    common: defaultHeaders
  }
});

// all these overloads are used to identify whether we return AxiosResponse or response data itself,
// based on settings.fullResponse flag
class ApiClientService {
  async get<T = any>(url: string, settings?: IRequestConfig<false>): Promise<T>
  async get<T = any>(url: string, settings: IRequestConfig<true>): Promise<AxiosResponse<T>>
  async get<T = any>(url: string, settings: IRequestConfig = {}) {
    const response = await axiosInst.get<T>(url, settings);
    return settings.fullResponse ? response : response.data;
  }

  async post<T = any>(url: string, data, settings?: IRequestConfig<false>): Promise<T>
  async post<T = any>(url: string, data, settings: IRequestConfig<true>): Promise<AxiosResponse<T>>
  async post<T = any>(url: string, data, settings: IRequestConfig = {}) {
    const response = await axiosInst.post<T>(url, data, settings);
    return settings.fullResponse ? response : response.data;
  }

  async put<T = any>(url: string, data, settings?: IRequestConfig<false>): Promise<T>
  async put<T = any>(url: string, data, settings: IRequestConfig<true>): Promise<AxiosResponse<T>>
  async put<T = any>(url: string, data, settings: IRequestConfig = {}) {
    const response = await axiosInst.put<T>(url, data, settings);
    return settings.fullResponse ? response : response.data;
  }

  async delete<T = any>(url: string, settings?: IRequestConfig<false>): Promise<T>
  async delete<T = any>(url: string, settings: IRequestConfig<true>): Promise<AxiosResponse<T>>
  async delete<T = any>(url: string, settings: IRequestConfig = {}) {
    const response = await axiosInst.delete<T>(url, settings);
    return settings.fullResponse ? response : response.data;
  }

  setAuthHeader(header: string|null) {
    if (header)
      axiosInst.defaults.headers.common[authHeader] = header;
    else
      delete axiosInst.defaults.headers.common[authHeader];
  }

  interceptResponse(callback: ResponseInterceptor, errorCallback: ResponseErrorInterceptor) {
    axiosInst.interceptors.response.use(response => {
      return callback && callback(response) || response;
    }, error => {
      return errorCallback && errorCallback(error) || Promise.reject(error);
    });
  }
}

export default new ApiClientService();
