import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";

import { EstimateStatus } from "config/constants.config";
import ItemFormulasConstructor from "modules/admin-items/ItemsPage/ItemFormulasConstructor/ItemFormulasConstructor";
import { buildUpdatingEstimateItemsSelector } from "store/entities/estimates";
import { selectors as categoriesSelectors } from "store/entities/categories";
import { useAssociatedItems } from "common/hooks/useAssociatedItems";

import ItemsWrapper from "../ItemsWrapper/ItemsWrapper";


export default function ItemsStep({ estimate, patch }) {
  const { loaded } = useAssociatedItems();
  const [advancedMode, setAdvancedMode] = useState(null);
  const itemsSelector = useMemo(
    () => buildUpdatingEstimateItemsSelector(estimate.type),
    [estimate.id, estimate.type]
  );
  const estimateItems = useSelector(itemsSelector);
  const categoriesByKey = useSelector(categoriesSelectors.entitiesByKey);

  const usedItems = useMemo(
    () => estimateItems.filter(item => !!item.overrides),
    [estimateItems]
  );

  useEffect(() => {

    if (loaded && advancedMode === null)
        setAdvancedMode(estimate.status === EstimateStatus.DRAFT); // auto-expand all items for draft estimate
  }, [loaded, usedItems, advancedMode]);

  const toggleItem = useCallback((item, defaultQuantity = 1) => {
    const updatedItems = estimateItems.map(estItem => {
      if (estItem.id !== item.id)
        return estItem;

      return {
        ...estItem,
        overrides: estItem.overrides ? null : {
          id: item.id,
          quantity: defaultQuantity
        }
      };
    });

    patch({
      items: updatedItems.filter(i => !!i.overrides).map(i => i.overrides)
    });
  }, [estimateItems, patch]);

  const updateQuantity = useCallback((item, quantity) => {
    let existingItem = estimateItems.find(i => i.id === item.id);

    if (!existingItem.overrides && quantity > 0)
      toggleItem(existingItem, quantity); // automatically select item
    else if (advancedMode && existingItem.overrides && quantity <= 0)
      toggleItem(existingItem); // automatically deselect item
    else {
      // update quantity without changing selection
      patch({
        items: estimate.items.map(i => {
          if (i.id === item.id)
            return {
              ...i,
              quantity
            };
          else
            return i;
        })
      });
    }
  }, [toggleItem, estimateItems, estimate.items, patch, advancedMode]);

  const renderRowSubComponent = useCallback(
    ({ row, close }) => (
      <ItemFormulasConstructor readOnly quantity={row.original.quantity} item={row.original} onClose={close}/>
    ),
    []
  );

  const fullModeColumns = useMemo(() =>
    [
      {
        Header: "Category",
        accessor: "categoryId",
        Cell: ({ cell: { value }}) => categoriesByKey[value] && categoriesByKey[value].name || "Uncategorized"
      },
      {
        id: "selection",
        Header: "",
        isSortable: false,
        style: {
          padding: 0
        },
        onClick: (event, cell, tableInst) => {
          tableInst.callbacks.toggleItem(cell.row.original);
        },
        Cell: ({ row }) => (
          <Checkbox
            checked={row.original.quantity > 0}
            color="secondary"
          />
        )
      }
    ],
    [categoriesByKey]
  );

  return (
    <ItemsWrapper
      estimate={estimate}
      patch={patch}
      data={advancedMode ? estimateItems : usedItems}
      extraColumns={advancedMode ? fullModeColumns : null}
      advancedMode={advancedMode}
      onToggleAdvancedMode={() => setAdvancedMode(!advancedMode)}
      tableProps={{
        key: `${advancedMode ? "advanced" : "simple"}-items-table`,
        defaultSortBy: advancedMode ? [{ id: "categoryId" }] : null,
        groupBy: advancedMode ? "categoryId" : null,
        renderRowSubComponent,
        callbacks: {
          toggleItem,
          updateQuantity
        }
      }}
    />
  );
}
