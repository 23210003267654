import { IAuditLog } from 'types/entities';
import AuditLogsService from 'services/entities/audit-logs.service';
import { createEntitySlice, EntitiesState } from 'store/helpers/entities';


export const {
  actions,
  reducer,
  selectors
} = createEntitySlice<IAuditLog>({
  name: 'auditLog',
  service: AuditLogsService,
  liveEntityTracking: true,
  selectors: {
    getFullState: state => state.audit
  }
});

export type AuditState = EntitiesState<IAuditLog>;
