import { Selector } from '@reduxjs/toolkit';

import { IUser } from 'types/entities';
import { UserRole } from 'config/constants.config';
import UsersService from 'services/entities/users.service';
import { createEntitySlice, EntitiesState, GenericSelectors } from 'store/helpers/entities';


interface IUsersSelectors extends GenericSelectors {
  isAdmin: Selector<IUser, boolean>;
}

export const { actions, reducer, selectors } = createEntitySlice<IUser, IUsersSelectors>({
  name: 'user',
  service: UsersService,
  liveEntityTracking: true,
  selectors: {
    isAdmin: user => user && user.role === UserRole.ADMIN
  }
});

export type UsersState = EntitiesState<IUser>;
