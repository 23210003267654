import { makeStyles } from '@material-ui/core/styles';
import { dangerColor, primaryColor, warningColor } from "assets/jss/common";


export default makeStyles(theme => ({
  status: {
    display: "inline-block",
    padding: theme.spacing(.25, 1),
    borderRadius: 4,
    color: "white",
    wordBreak: "keep-all",
    opacity: .8,
    "&:hover": {
      opacity: 1
    }
  },
  levelCritical: {
    background: dangerColor[0]
  },
  levelImportant: {
    background: warningColor[0]
  },
  levelDefault: {
    background: primaryColor[0]
  }
}));
