import { createSelector, Selector } from '@reduxjs/toolkit';
import mapValues from 'lodash/mapValues';

import { ICustomer, IEstimate } from 'types/entities';
import CustomersService from 'services/entities/customers.service';
import { AppState } from 'store/store.d';
import {
  createEntitySlice,
  CustomReducers,
  EntitiesByKey,
  EntitiesState,
  GenericSelectors
} from 'store/helpers/entities';
import { selectors as estimatesSelectors } from 'store/entities/estimates';


interface ICustomerExtended extends ICustomer {
  estimatesCount: number;
  estimatesTotal: number;
}

interface ICustomersSelectors extends GenericSelectors {
  entitiesByKey: Selector<AppState, EntitiesByKey<ICustomerExtended>>;
}

export const {
  actions,
  reducer,
  selectors
} = createEntitySlice<ICustomer, ICustomersSelectors, CustomReducers<ICustomer>, ICustomerExtended>({
  name: 'customer',
  service: CustomersService,
  liveEntityTracking: true,
  selectors: {
    // transform customers to ICustomerExtended interface
    entitiesByKey: createSelector(
      (state: any) => state.customers.byKey,
      estimatesSelectors.estimatesByCustomer,
      (customersByKey, estimatesByCustomerId) => mapValues(customersByKey, customer => {
        const estimates = estimatesByCustomerId[customer.id] || [];

        return {
          ...customer,
          estimatesCount: estimates.length,
          // calculate total income value of all customer's estimates
          estimatesTotal: estimates.reduce(
            (sum: number, estimate: IEstimate) => sum + (estimate.balance?.totalIncome || 0),
            0
          )
        } as ICustomerExtended;
      })
    )
  }
});

export type CustomersState = EntitiesState<ICustomer>;
