import { connect } from 'react-redux';

import { AppState } from 'store/store.d';
import { actions as authActions, selectors as authSelectors } from "store/auth";
import { actions as uiActions, selectors as uiSelectors } from "store/ui";
import { IHomeModuleProps, HomeModule } from './HomeModule';


type StateProps = Pick<IHomeModuleProps, 'minimizedSidebar'|'adminAccessAllowed'>;
type DispatchProps = Pick<IHomeModuleProps, 'setMinimizedSidebar'|'logOut'>;

const mapStateToProps = (state: AppState): StateProps => ({
  minimizedSidebar: uiSelectors.isSidebarMinimized(state),
  adminAccessAllowed: authSelectors.isManagerOrAdmin(state)
});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  setMinimizedSidebar: (flag: boolean) => dispatch(uiActions.setMinimizedSidebar(flag)),
  logOut: () => dispatch(authActions.logOut())
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeModule);
