import { Subject } from "rxjs/internal/Subject";

import { IUser } from 'types/entities';
import { authHeader, baseAuthURL, endpoints, statuses } from "config/api.config";
import api from "./api-client.service";


export interface ILoginResponse {
  authToken: string;
  user: IUser;
}

class AuthService {
  public authorizationFailed$ = new Subject<never>();
  public authToken$ = new Subject<string>();
  private authToken: string = null;

  constructor() {
    // trigger authorizationFailed$ subject whenever we get UNAUTHENTICATED status,
    // which will redirect to the login page
    api.interceptResponse(null, ({ response }) => {
      if (response.status === statuses.UNAUTHENTICATED)
        this.authorizationFailed$.next();
    });
  }

  getAuthToken() {
    return this.authToken;
  }

  async login(email: string, password: string): Promise<ILoginResponse> {
    const response = await api.post<IUser>(endpoints.login, {
      user: {
        email,
        password
      }
    }, {
      fullResponse: true,
      baseURL: baseAuthURL
    });

    return {
      authToken: (response.headers[authHeader] || response.headers[authHeader.toLowerCase()]) as string,
      user: response.data
    };
  }

  logout() {
    return api.delete<void>(endpoints.logout, {
      baseURL: baseAuthURL
    });
  }

  setAuthToken(token: string|null) {
    api.setAuthHeader(token);
    this.authToken = token;
    this.authToken$.next(token);
  }
}

export default new AuthService();
