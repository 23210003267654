export const appRoutes = {
  DEFAULT:  '/',
  HOME:     '/',
  AUTH:     '/auth'
};

export const authRoutes = {
  DEFAULT: appRoutes.AUTH + '/login',
  LOGIN:   appRoutes.AUTH + '/login'
};

export const homeRoutes = {
  DEFAULT:          appRoutes.HOME + 'customers',
  CUSTOMERS:        appRoutes.HOME + 'customers',
  ESTIMATES_WIZARD: appRoutes.HOME + 'estimates/:id/wizard',
  ESTIMATES:        appRoutes.HOME + 'estimates',
  DASHBOARD:        appRoutes.HOME + 'dashboard',
  AUDIT_LOGS:       appRoutes.HOME + 'activity',
  ADMIN:            appRoutes.HOME + 'admin'
};

export const adminRoutes = {
  DEFAULT:    homeRoutes.ADMIN + '/users',
  USERS:      homeRoutes.ADMIN + '/users',
  ITEMS:      homeRoutes.ADMIN + '/items',
  TEMPLATES:  homeRoutes.ADMIN + '/templates'
};

export const adminItemsRoutes = {
  DEFAULT:    adminRoutes.ITEMS + '/',
  ITEMS:      adminRoutes.ITEMS + '/',
  MATERIALS:  adminRoutes.ITEMS + '/materials',
  LABOR:      adminRoutes.ITEMS + '/labor',
  GUTTER:     adminRoutes.ITEMS + '/gutter',
  CATEGORIES: adminRoutes.ITEMS + '/categories',
};

export const adminTemplatesRoutes = {
  DEFAULT:  adminRoutes.TEMPLATES + '/contract',
  CONTRACT: adminRoutes.TEMPLATES + '/contract',
  INVOICE:  adminRoutes.TEMPLATES + '/invoice',
  EMAIL:    adminRoutes.TEMPLATES + '/email'
};

// Note: the order is important in this object: the last matching route is used to display title,
// i.e. the closes match is used, provided that the routes are ordered from the most generic to the most concrete ones.
// E.g. "/admin" route (title "Admin") should go before "/admin/items" (title "Items").
export const pageTitles = {
  [homeRoutes.CUSTOMERS]:          'Customers',
  [homeRoutes.ESTIMATES]:          'Estimates',
  [homeRoutes.DASHBOARD]:          'Dashboard',
  [homeRoutes.AUDIT_LOGS]:         'Activity',
  [homeRoutes.ESTIMATES_WIZARD]:   'Estimate Wizard',
  [homeRoutes.ADMIN]:              'Admin',
  [adminRoutes.USERS]:             'Users',
  [adminRoutes.ITEMS]:             'Items',
  [adminRoutes.TEMPLATES]:         'Templates',
  [adminItemsRoutes.ITEMS]:        'Items',
  [adminItemsRoutes.MATERIALS]:    'Materials',
  [adminItemsRoutes.LABOR]:        'Labor',
  [adminItemsRoutes.GUTTER]:       'Gutter',
  [adminItemsRoutes.CATEGORIES]:   'Categories & Units',
  [adminTemplatesRoutes.CONTRACT]: 'Contract',
  [adminTemplatesRoutes.INVOICE]:  'Invoice',
  [adminTemplatesRoutes.EMAIL]:    'Email Notification',
};

// Note: the order is important here as well (from more concrete to more generic):
// the first matching route is used as a match
export const allRoutes = [
  ...Object.values(adminItemsRoutes),
  ...Object.values(adminTemplatesRoutes),
  ...Object.values(adminRoutes),
  ...Object.values(homeRoutes),
  ...Object.values(appRoutes)
];
