import React, { useCallback, useMemo } from "react";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import GridContainer from "common/components/Grid/GridContainer";
import GridItem from "common/components/Grid/GridItem";
import ClipboardLink from "common/components/ClipboardLink/ClipboardLink";

import { EstimateStatus, EstimateType } from "config/constants.config";
import useStyles from "./summaryStepStyle";
import BalanceSheet from "./BalanceSheet/BalanceSheet";


export default function SummaryStep({ estimate, patch }) {
  const classes = useStyles();
  const contractUrls = useMemo(() => [
    {
      url: estimate.contractUrl,
      text: "PDF link"
    },
    {
      url: estimate.contractSigningUrl,
      text: "Signing link"
    },
    {
      url: estimate.contractSigningUrlTrackable,
      text: "Trackable signing link",
      isDisabled: true
    }
  ], [estimate]);

  const updateBalance = useCallback(balance => patch({ balance }), [patch]);

  const updateComment = useCallback(event => patch({ comment: event.target.value }), [patch]);

  return (
    <GridContainer justify="space-evenly">
      <GridItem xs={12} md={6} lg={5}>
        <Typography variant="h5" className={classes.header}>Balance</Typography>
        {estimate.status === EstimateStatus.DRAFT ? (
          <div className={classes.draftNote}>
            <Typography variant="h5" color="inherit" className={classes.title}>
              The estimate is not ready yet
            </Typography>
            <Typography variant="h5" color="inherit" className={classes.subtitle}>
              Add some items, and you&apos;ll see
              the financial balance and documents links on this page
            </Typography>
          </div>
          ) : (estimate.balance && <BalanceSheet balance={estimate.balance} onUpdate={updateBalance} />)
        }
      </GridItem>

      <GridItem xs={12} md={6} lg={4}>
        <Typography variant="h5" className={classes.header}>Documents</Typography>
        <ClipboardLink options={contractUrls} className={classes.clipboardLink} />
        { estimate.materialsInvoiceUrl && (
          <ClipboardLink
            url={estimate.materialsInvoiceUrl}
            text="Material Order Form"
            className={classes.clipboardLink} />
        )}
        { estimate.laborInvoiceUrl && (
          <ClipboardLink
            url={estimate.laborInvoiceUrl}
            text={`${estimate.type === EstimateType.SIDING ? "Siding" : "Roofing"} Labor Invoice`}
            className={classes.clipboardLink}
          />
        )}
        { estimate.gutterInvoiceUrl && (
          <ClipboardLink
            url={estimate.gutterInvoiceUrl}
            text="Gutter Labor Invoice"
            className={classes.clipboardLink}
          />
        )}

        <Typography variant="h5" className={classes.header}>Notes</Typography>
        <TextField multiline
                   variant="outlined"
                   rows={4}
                   fullWidth
                   placeholder="Enter some notes about this estimate (optional)"
                   value={estimate.comment || ""}
                   onChange={updateComment}
        />
      </GridItem>
    </GridContainer>
  );
}
