import { Action, Reducer } from '@reduxjs/toolkit';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


export default function persist<S, A extends Action = Action>(
  key: string,
  reducer: Reducer<S, A>,
  whitelist?: string[]
) {
  return persistReducer<S, A>({
    key,
    storage,
    whitelist,
  }, reducer);
}
