import { makeStyles } from '@material-ui/core/styles';


export default makeStyles(theme => ({
  draftNote: {
    padding: "6%",
    textAlign: "center"
  },
  title: {
    marginBottom: theme.spacing(4),
    color: theme.palette.text.hint
  },
  subtitle: {
    margin: "auto",
    maxWidth: 400,
    fontSize: "large",
    fontWeight: "normal",
    color: theme.palette.text.hint,
    opacity: .7
  },
  header: {
    borderBottom: "1px solid " + theme.palette.text.secondary,
    margin: theme.spacing(0, -1, 3),
    padding: theme.spacing(0, 0, 1, 3),
    color: theme.palette.text.secondary
  },
  clipboardLink: {
    maxWidth: 350,
    margin: theme.spacing(5, 0)
  }
}));
