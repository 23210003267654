import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";

import { appRoutes, authRoutes } from 'config/routing.config';
import { selectors as authSelectors } from "store/auth";


interface IPrivateRouteProps extends RouteProps {
  authorizeAdmin?: boolean;
}

const PrivateRoute: React.FunctionComponent<IPrivateRouteProps> = (props) => {
  const { component: Component, authorizeAdmin, ...rest } = props;
  const authenticated = useSelector(authSelectors.isAuthenticated);
  const isManagerOrAdmin = useSelector(authSelectors.isManagerOrAdmin);
  const authorized = !authorizeAdmin || isManagerOrAdmin;

  return (
    <Route {...rest} render={props => (
      authenticated
        ? (
            authorized
              ? <Component history={props.history} match={props.match} location={props.location} />
              : <Redirect to={appRoutes.DEFAULT} />
          )
        : <Redirect to={{ pathname: authRoutes.LOGIN, state: { from: props.location } }} />
    )} />
  );
};

export default PrivateRoute;
