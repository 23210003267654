import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { EstimateType } from "config/constants.config";
import { useCRUDActions } from "common/hooks/crud/useCRUDActions";
import { formatCurrency } from "common/utils/format.util";
import { actions as itemsActions, selectors as itemsSelectors } from "store/entities/items";
import { selectors as unitsSelectors } from "store/entities/units";

import TableCRUD from "common/views/TableCRUD/TableCRUD";


function SubItemsTable(props) {
  const { Icon, entity, getEntities, categories, title } = props;

  const units = useSelector(unitsSelectors.getEntities);
  const defaultCategory = useMemo(() => {
    return categories && categories.find(cat => cat.type === EstimateType.COMMON);
  }, [categories]);

  const [state, actions] = useCRUDActions(itemsActions, {
    ...itemsSelectors,
    getEntities
  }, {
    confirmDeletion: true,
    immediate: false,
    defaults: {
      name: "",
      price: 0,
      unitId: null,
      categoryId: defaultCategory ? defaultCategory.id : null
    }
  });

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Type",
        accessor: "category.type",
        show: categories.length > 1
      },
      {
        Header: "Price",
        accessor: "price",
        sortType: "basic",
        Cell: ({ cell: { value }}) => formatCurrency(value)
      },
      {
        Header: "Unit",
        accessor: "unit.name"
      }
    ],
    [categories.length]
  );

  const fields = useMemo(
    () => [
      {
        label: "Name",
        required: true,
        accessor: "name",
        TextFieldProps: {
          multiline: true
        }
      },
      {
        label: "Type",
        required: true,
        inline: true,
        accessor: "categoryId",
        disabled: categories.length <= 1,
        // assuming there is a limited number of categories for sub-items, and each category has a unique type
        // (i.e. there should be only 1 category of Roofing type, 1 - of Siding, and 1 - of Common
        options: categories && categories.map(cat => ({
          value: cat.id,
          label: cat.type
        })) || []
      },
      {
        label: "Unit",
        required: true,
        inline: true,
        accessor: "unitId",
        style: {
          maxWidth: 70,
          marginLeft: 16
        },
        options: units && units.map(u => ({
          value: u.id,
          label: u.name
        })) || []
      },
      {
        label: "Price",
        required: true,
        inline: true,
        accessor: "price",
        TextFieldProps: {
          type: "number"
        },
        style: {
          maxWidth: 90,
          marginLeft: 16
        }
      }
    ],
    [units, categories]);

  return (
    <TableCRUD
      Icon={Icon}
      title={title}
      crudState={state}
      actions={actions}
      columns={columns}
      fields={fields}
      entity={entity}
      TableProps={{ defaultSortBy: [{id: "category.type"}, { id: "name" }] }} // TODO: fix multisort
      getRemovalConfirmation={item => `Are you sure you want to delete ${item.name}?`}
    />
  );
}

export default SubItemsTable;
