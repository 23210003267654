import createMuiTheme, { Theme } from '@material-ui/core/styles/createMuiTheme';
import { tooltip } from 'assets/jss/common';


// update global declaration of Theme interface
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    appDrawer: {
      width: React.CSSProperties['width'];
      minimizedWidth: React.CSSProperties['width'];
      transition: string;
    }
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    appDrawer?: Partial<Theme['appDrawer']>
  }
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3f51b5'
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip
    }
  },
  appDrawer: {
    width: 260,
    minimizedWidth: 80,
    transition: 'width 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
  }
});

export default theme;
