import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

import { buildBasicItemsSelector } from "store/associatedItems";
import style from "./itemFormulasGroupStyle";
import ItemFormula from "../ItemFormula/ItemFormula";


const useStyles = makeStyles(style);

function ItemFormulasGroup(props) {
  const {
    title,
    addBtnTitle,
    basicGroup,
    estimateType,
    formulas,
    actions,
    className,
    readOnly,
    multiplier
  } = props;

  const classes = useStyles();
  const subItemsSelector = useMemo(() =>
    buildBasicItemsSelector(basicGroup/*, estimateType*/), // TODO: commented for further clarifications
    [basicGroup, estimateType]
  );
  const allSubItems = useSelector(subItemsSelector);

  return (
    <List className={cn(classes.list, className)}>
      <ListSubheader>{title}</ListSubheader>
      { formulas.map(formula => (
        <ItemFormula
          key={formula.id || formula.tmpId}
          formula={formula}
          onChange={patch => actions.patch(formula, patch)}
          onRemove={() => actions.remove(formula)}
          availableSubItems={allSubItems}
          readOnly={readOnly}
          multiplier={multiplier}
        />
      ))}
      { !readOnly && (
        <ListItem button onClick={actions.create} className={classes.addNewBtn}>
          <ListItemText primary={addBtnTitle} />
        </ListItem>
      )}
    </List>
  );
}

export default ItemFormulasGroup;
