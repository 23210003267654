import React, { useMemo } from "react";

import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import { BasicGroup, EstimateType } from "config/constants.config";
import ClipboardLink from "common/components/ClipboardLink/ClipboardLink";
import WasteCalculator from "./WasteCalculator/WasteCalculator";
import useStyles from "./itemsToolbarStyles";


export default function ItemsToolbar({ estimate, group, advancedMode, onToggleAdvancedMode, onCreateItem, entityName }) {
  const classes = useStyles();
  const urls = useMemo(() => ({
    items: estimate.contractUrl && [
      {
        url: estimate.contractUrl,
        text: "PDF link"
      },
      {
        url: estimate.contractSigningUrl,
        text: "Signing link"
      },
      {
        url: estimate.contractSigningUrlTrackable,
        text: "Trackable signing link",
        isDisabled: true
      }
    ],
    [BasicGroup.MATERIALS]: estimate.materialsInvoiceUrl && [{
      url: estimate.materialsInvoiceUrl,
      text: "Material Order Form"
    }],
    [BasicGroup.LABOR]: estimate.laborInvoiceUrl && [{
      url: estimate.laborInvoiceUrl,
      text: `${estimate.type === EstimateType.SIDING ? "Siding" : "Roofing"} Labor Invoice`
    }],
    [BasicGroup.GUTTER]: estimate.gutterInvoiceUrl && [{
      url: estimate.gutterInvoiceUrl,
      text: "Gutter Labor Invoice"
    }]
  }), estimate);

  group = group || 'items';

  return (
    <>
      { onToggleAdvancedMode && (
        <Tooltip title="Show all available items" placement="top">
          <Button
            variant={advancedMode ? "contained" : "outlined"}
            color="primary"
            className={classes.advancedBtn}
            onClick={onToggleAdvancedMode}
          >
            { advancedMode ? <CheckBox /> : <CheckBoxOutlineBlank />}
            &nbsp; &nbsp; Show all
          </Button>
        </Tooltip>
      )}

      { urls[group] && <ClipboardLink options={urls[group]} className={classes.clipboardLink} />}

      <WasteCalculator/>

      <Tooltip title="Create new item manually" placement="top">
        <Button
          variant="text"
          color="inherit"
          className={classes.addItemBtn}
          onClick={onCreateItem}
        >
          + Add custom {entityName || "item"}
        </Button>
      </Tooltip>

    </>
  );
}
