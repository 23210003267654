import React from 'react';
import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router';

import { appRoutes, authRoutes } from 'config/routing.config';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';

import LoginPage from './LoginPage';


export interface IAuthModuleProps extends RouteChildrenProps {
  authenticated: boolean;
}

export const AuthModule: React.FunctionComponent<IAuthModuleProps> = (props) => {
  const { authenticated } = props;

  if (authenticated)
    return <Redirect to={appRoutes.DEFAULT} />;

  return (
    <AuthLayout>
      <Switch>
        <Route path={authRoutes.LOGIN} component={LoginPage} />
        <Redirect to={authRoutes.LOGIN} />
      </Switch>
    </AuthLayout>
  );
};
