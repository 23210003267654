import { createSelector, PayloadAction } from '@reduxjs/toolkit';

import { IFormula, IItem } from 'types/entities';
import FormulasService, { IBulkUpdatePayload, IBulkUpdateResponse } from 'services/entities/formulas.service';

import { AppThunk } from 'store/store.d';
import { showNotification } from 'store/notifications';
import { createEntitySlice, CustomReducers, CustomSelectors, EntitiesState } from 'store/helpers/entities';
import { addNormalizedItem, deleteNormalizedItem, updateNormalizedItem } from 'store/helpers/entities/normalizer';


interface IFormulasReducers extends CustomReducers<IFormula> {
  finishBulkUpdate: (state: EntitiesState<IFormula>, action: PayloadAction<IBulkUpdateResponse>) => EntitiesState<IFormula>;
  startBulkUpdate: (state: EntitiesState<IFormula>) => EntitiesState<IFormula>;
  cancelBulkUpdate: (state: EntitiesState<IFormula>) => EntitiesState<IFormula>;
}

const {
  actions: defaultActions,
  reducer,
  selectors
} = createEntitySlice<IFormula, CustomSelectors<IFormula>, IFormulasReducers>({
  name: 'formula',
  service: FormulasService,
  reducers: {
    startBulkUpdate: state => state, // do nothing
    cancelBulkUpdate: state => state, // do nothing
    finishBulkUpdate(state, action) {
      const changes = action.payload;

      // doing it not in the most efficient way because the vast majority of bulk update calls will include only 1-2 changes anyway
      changes.deleted.forEach(id => {
        state = deleteNormalizedItem(state, id);
      });

      changes.updated.forEach(formula => {
        state = updateNormalizedItem(state, formula);
      });

      changes.created.forEach(formula => {
        state = addNormalizedItem(state, formula, true);
      });

      return state;
    }
  },
  selectors: {
    getFullState: state => state.associatedItems.formulas
  }
});

export type FormulasState = EntitiesState<IFormula>;

const actions = {
  ...defaultActions,
  bulkUpdate: (payload: IBulkUpdatePayload): AppThunk => async dispatch => {
    dispatch(defaultActions.startBulkUpdate());

    try {
      const response = await FormulasService.bulkUpdate(payload);
      dispatch(defaultActions.finishBulkUpdate(response));

      dispatch(showNotification({
        message: `The formula was successfully updated`,
        variant: "success",
        autoHideDuration: 3000,
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      }));
    } catch(error) {
      const errorMsg = error.response?.data?.error || error.message;

      console.error(`Action Error: ${defaultActions.startBulkUpdate.name}`, error);

      dispatch(defaultActions.cancelBulkUpdate());

      dispatch(showNotification({
        message: `Could not update the formula. ${errorMsg}`,
        variant: "error",
        autoHideDuration: 5000,
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      }));

      throw error;
    }
  }
};

const buildItemFormulasSelector = (item: IItem) =>
  createSelector(
    selectors.getEntities,
    (formulas) =>
      formulas.filter(f => f.itemId === item.id)
  );

export {
  reducer,
  selectors,
  actions,
  buildItemFormulasSelector
};
