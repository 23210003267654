import React from "react";
import ReactDOM from "react-dom";
import { Provider as StoreProvider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/core/styles";
import { PersistGate } from "redux-persist/integration/react";

import "assets/scss/main.scss";
import { store, persistor } from "store";
import { theme } from './styles';

import App from "./App";


const render = (Component: React.ComponentType) => {
  return ReactDOM.render(
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <StoreProvider store={store}>
          <PersistGate persistor={persistor}>
            <Component/>
          </PersistGate>
        </StoreProvider>
      </SnackbarProvider>
    </ThemeProvider>,
    document.getElementById('root')
  );
};

render(App);

// enable HMR magic according to this article: https://medium.com/@brianhan/hot-reloading-cra-without-eject-b54af352c642
if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
