import { IDocumentTemplate } from 'types/entities';
import DocumentTemplatesService from 'services/entities/templates.service';
import { createEntitySlice, EntitiesState } from 'store/helpers/entities';


export const { actions, reducer, selectors } = createEntitySlice<IDocumentTemplate>({
  name: 'template',
  service: DocumentTemplatesService
});

export type TemplatesState = EntitiesState<IDocumentTemplate>;
