import React from 'react';
import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router';

import { homeRoutes } from 'config/routing.config';
import PrivateRoute from 'common/components/PrivateRoute/PrivateRoute';
import AdminModule from 'modules/admin';
import HomeLayout from 'layouts/HomeLayout/HomeLayout';
import CustomersPage from './CustomersPage/CustomersPage';
import EstimatesPage from './EstimatesPage/EstimatesPage';
import EstimatesWizardPage from './EstimateWizardPage/EstimateWizardPage';
import DashboardPage from './DashboardPage/DashboardPage';
import AuditPage from './AuditPage/AuditPage';


export interface IHomeModuleProps extends RouteChildrenProps {
  adminAccessAllowed: boolean;
  minimizedSidebar: boolean;
  setMinimizedSidebar: (flag: boolean) => void;
  logOut: () => void;
}

export const HomeModule: React.FunctionComponent<IHomeModuleProps> = (props) => {
  const { minimizedSidebar, adminAccessAllowed, setMinimizedSidebar, logOut, history } = props;

  return (
    <HomeLayout
      history={history}
      minimizedSidebar={minimizedSidebar}
      adminAccessAllowed={adminAccessAllowed}
      setMinimizedSidebar={setMinimizedSidebar}
      logOut={logOut}
    >
      <Switch>
        <Route path={homeRoutes.CUSTOMERS} component={CustomersPage} />
        <Route path={homeRoutes.ESTIMATES_WIZARD} component={EstimatesWizardPage} />
        <Route path={homeRoutes.ESTIMATES} component={EstimatesPage} />
        <Route path={homeRoutes.DASHBOARD} component={DashboardPage} />
        <Route path={homeRoutes.AUDIT_LOGS} component={AuditPage} />
        <PrivateRoute path={homeRoutes.ADMIN} component={AdminModule} authorizeAdmin />
        <Redirect to={homeRoutes.DEFAULT} />
      </Switch>
    </HomeLayout>
  );
};
