import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from 'assets/jss/common';


export const useStyles = makeStyles(theme => ({
  rippleContainer: {
    borderRadius: 4
  },
  card: {
    width: 400,
    maxWidth: '95vw',
    textAlign: 'left',
    boxShadow: theme.shadows[5],
    '&:hover': {
      background: theme.palette.grey['100']
    },
    '&:active': {
      boxShadow: theme.shadows[2]
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    padding: theme.spacing(2, 2, 1, 2)
  },
  iconBox: {
    width: 50,
    padding: theme.spacing(0, 2, 2, 0),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: primaryColor[0]
  },
  contentBox: {
    flexGrow: 1
  },
  title: {
    marginBottom: theme.spacing(0.5),
  },
  body: {},
  date: {
    display: 'block',
    textAlign: 'right',
    color: theme.palette.grey['500']
  },
  closeBtn: {
    float: 'right',
    margin: theme.spacing(0, 0, 0, 0.5),
    color: theme.palette.grey['500'],
    '&:hover': {
      color: theme.palette.grey['800'],
    }
  }
}));
