import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";


interface IDeleteActionOptions {
  confirmDeletion?: boolean;
}

export function useDeleteAction(actionCreators, options: IDeleteActionOptions = {}) {
  const [deletingEntity, setDeletingEntity] = useState(null);
  const dispatch = useDispatch();

  const reset = useCallback(() => {
    if (deletingEntity)
      setDeletingEntity(null);
  }, [deletingEntity, setDeletingEntity]);

  const startDeletion = useCallback(payload => {
    if (options.confirmDeletion)
      setDeletingEntity(payload);
    else
      dispatchDelete(payload);
  }, [setDeletingEntity]);

  const dispatchDelete = useCallback((payload = deletingEntity)=> {
    dispatch(actionCreators.delete(payload));
    setDeletingEntity(null);
  }, [deletingEntity, setDeletingEntity]);

  return [deletingEntity, {
    delete: startDeletion,
    commit: dispatchDelete,
    reset
  }];
}
