import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import map from "lodash/map";
import pick from "lodash/pick";
import pickBy from "lodash/pickBy";


const formulaKeys = ["id", "itemId", "nestedItemId", "factor"];

export function useFormulasAPI(groups, bulkUpdate) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const save = useCallback(() => {
    const payload = preparePayload();
    commitPayload(payload);
  }, [groups, loading, setLoading, dispatch]);

  const hasChanges = groups.some(group => group[0].hasChanges);

  return [{
    hasChanges,
    loading
  }, {
    cancel: cancelAll,
    save
  }];

  function cancelAll() {
    groups.forEach(group => group[1].cancel());
  }

  function preparePayload() {
    const payload = {
      update: [],
      delete: [],
      create: []
    };

    groups.forEach(group => {
      const [groupState] = group;

      const toCreate = groupState.localCreated
        .map(f => pick(f, formulaKeys))
        .filter(f => !!f.itemId && !!f.nestedItemId);

      const toDelete = Object.keys(pickBy(groupState.localUpdated, val => !val)).map(id => +id);

      const toUpdate = map(
        pickBy(groupState.localUpdated, val => !!val),
        (patch, id) => ({
          ...pick(patch, formulaKeys),
          id: +id
        })
      );

      payload.create = payload.create.concat(toCreate);
      payload.delete = payload.delete.concat(toDelete);
      payload.update = payload.update.concat(toUpdate);
    });

    return payload;
  }

  async function commitPayload(payload) {
    try {
      setLoading(true);
      await dispatch(bulkUpdate(payload));
      cancelAll();
    } catch(e) {
      // skip error; error message is displayed by action creator
    } finally {
      setLoading(false);
    }
  }

}
