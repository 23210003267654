import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistPartial } from 'redux-persist/es/persistReducer';

import { IUser } from 'types/entities';
import { UserRole } from 'config/constants.config';
import AuthService, { ILoginResponse } from 'services/auth.service';
import { AppThunk, AppThunkSync } from 'store/store.d';
import persist from "store/helpers/persistReducer";
import { showNotification } from 'store/notifications';
import * as selectors from './selectors';


export interface AuthState {
  token: string;
  loading: boolean;
  error: any; // TODO: use validation error format
  user: IUser;
}

export type PersistedAuthState = PersistPartial & AuthState;

const initialState: AuthState = {
  token: null,
  loading: false,
  error: null,
  user: {
    id: null,
    createdAt: null,
    updatedAt: null,
    name: "",
    email: "",
    role: UserRole.USER
  }
};

const { reducer: originalReducer, actions: originalActions } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startLogin(state) {
      state.loading = true;
    },
    finishLogin(state, action: PayloadAction<ILoginResponse>) {
      state.loading = false;
      state.token = action.payload.authToken;
      state.user = action.payload.user;
    },
    cancelLogin(state, action: PayloadAction<any>) {
      state.loading = false;
      state.token = null;
      state.error = action.payload;
    },
    reset: () => initialState
  }
});

// save "token" and "user" in the localStorage
const reducer = persist("auth", originalReducer, ["token", "user"]);

const actions = {
  ...originalActions,
  reset: (): AppThunkSync => (dispatch) => {
    AuthService.setAuthToken(null);
    dispatch(originalActions.reset());
  },
  // initialize authorization:
  // 1) set auth token after it's loaded from localStorage (with redux-persist)
  // 2) listen to authorizationFailed$ event to log out user automatically
  initialize: (): AppThunkSync => (dispatch, getState) => {
    const token = selectors.getAuthToken(getState());
    AuthService.setAuthToken(token);

    AuthService.authorizationFailed$.subscribe(() => {
      dispatch(actions.reset());
    });
  },
  logIn: (email: string, password: string): AppThunk => async dispatch => {
    dispatch(originalActions.startLogin());

    try {
      const payload = await AuthService.login(email, password);
      AuthService.setAuthToken(payload.authToken);
      dispatch(originalActions.finishLogin(payload));
    } catch(e) {
      AuthService.setAuthToken(null);
      dispatch(originalActions.cancelLogin(e.response?.data));
      dispatch(showNotification({
        message: `Error! ${e.response?.data?.error || e.message}`,
        variant: "error",
        autoHideDuration: 5000,
        anchorOrigin: {
          horizontal: "center",
          vertical: "top"
        }
      }));
    }
  },
  logOut: (): AppThunk => async dispatch => {
    await AuthService.logout();
    dispatch(actions.reset());
  }
};

export {
  reducer,
  actions,
  selectors
};
