import { createSelector } from "@reduxjs/toolkit";
import { AppState } from 'store/store.d';


const fullState = (state: AppState) => state.estimateWizard;

const hasChanges = createSelector(
  fullState,
  // any `true` value indicates that the wizard has changes
  wizard => Object.values(wizard).some(value => value === true)
);

export {
  fullState,
  hasChanges
};
