import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import compact from "lodash/compact";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";
import { DeleteTwoTone, EditTwoTone, RestoreTwoTone, WarningRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { customQuantityWarning } from "config/strings.config";
import { formatCurrency } from "common/utils/format.util";
import { useEstimateItems } from "common/hooks/useEstimateItems";
import EditDialog from "common/views/EditDialog/EditDialog";
import GridItem from "common/components/Grid/GridItem";
import GridContainer from "common/components/Grid/GridContainer";
import CustomTable from "common/components/Table/Table";
import { buildBasicItemsSelector } from "store/associatedItems";

import ItemsToolbar from "../ItemsToolbar/ItemsToolbar";
import styles from "./itemsWrapperStyle";

import { IExtendedItem } from 'store/associatedItems/selectors';


const useStyles = makeStyles(styles);

export default function ItemsWrapper(props) {
  const {
    estimate,
    data,
    group,
    entityName,
    extraColumns,
    advancedMode,
    onToggleAdvancedMode,
    tableProps,
    patch
  } = props;

  const classes = useStyles();
  const [{
    loading,
    editingItem,
    units
  }, actions] = useEstimateItems({
    estimate,
    group,
    patch
  });

  const subItemsSelector = group ? buildBasicItemsSelector(group, estimate.type) : (() => null as IExtendedItem[]);
  const allSubItems = useSelector(subItemsSelector);
  const creatingItem = !!editingItem && !editingItem.id;

  const rowActions = useCallback(item => (!advancedMode || item.quantity > 0) && [
    !item.custom && Object.keys(item.overrides || {}).length > 2 && { // show "Restore defaults" action only if some other fields except `id` and `quantity` were overridden
      title: "Restore defaults",
      type: "warning",
      Icon: RestoreTwoTone,
      callback: () => actions.restoreDefaults(item)
    },
    {
      title: "Overwrite",
      type: "primary",
      Icon: EditTwoTone,
      callback: () => actions.editItem(item)
    },
    {
      title: "Delete",
      type: "danger",
      Icon: DeleteTwoTone,
      callback: () => actions.deleteItem(item)
    }
  ], [estimate.items, patch]);

  const columns = useMemo(
    () => [
      ...(extraColumns || []),
      {
        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Unit",
        accessor: "unit.name"
      },
      {
        Header: "Price",
        accessor: "price",
        sortType: "basic",
        Cell: ({ cell: { value }}) => formatCurrency(value) || null
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        sortType: "basic",
        Footer: "Total:",
        Cell: ({ row, callbacks }) => (
          <>
            <Input
              value={row.original.quantity.toString()}
              onFocus={e => e.target.select()}
              onChange={event => callbacks.updateQuantity(row.original, +event.target.value)}
              type="number"
              inputProps={{ min: 0 }}
              className={classes.quantity}
            />
            {row.original.originalQuantity >= 0 && (
              <Tooltip title={customQuantityWarning} placement="right">
                <WarningRounded className={classes.warningIcon} color="inherit" />
              </Tooltip>
            )}
          </>
        )
      },
      {
        Header: "Subtotal",
        id: "subtotal",
        accessor: item => item.quantity > 0 ? item.quantity * item.price : "",
        sortType: "basic",
        Cell: ({ cell: { value }}) => formatCurrency(value) || null,
        Footer: ({ data }) => formatCurrency(
          data.reduce((sum, item) => sum + item.quantity * item.price, 0)
        )
      }
    ],
    [extraColumns]
  );

  const fields = useMemo(
    () => compact([
      {
        label: "Name",
        required: true,
        accessor: "name",
        TextFieldProps: {
          multiline: true
        },
        autocompleteOptions: creatingItem ? allSubItems?.map(i => i.name) : null,
        free: true
      },
      {
        label: "Price",
        required: true,
        inline: true,
        accessor: "price",
        TextFieldProps: {
          type: "number",
          inputProps: {
            step: .01
          }
        }
      },
      // show quantity only when creating new item
      creatingItem && {
        label: "Quantity",
        inline: true,
        accessor: "quantity",
        style: {
          maxWidth: 90,
          marginLeft: 16
        },
        TextFieldProps: {
          type: "number",
          inputProps: {
            step: .01
          }
        }
      },
      {
        label: "Unit",
        required: true,
        inline: true,
        accessor: "unitId",
        style: {
          maxWidth: 90,
          marginLeft: 16
        },
        options: units && units.map(u => ({
          value: u.id,
          label: u.name
        })) || []
      }
    ]),
    [units, allSubItems, creatingItem]);

  return (
    <GridContainer justify="center">
      <GridItem xs={12}>
        <CustomTable
          showFooter
          noPagination
          hideFiltersByDefault
          loading={loading}
          columns={columns}
          data={data}
          actions={rowActions}
          callbacks={{
            updateQuantity: actions.updateQuantity
          }}
          toolbarLeft={
            <ItemsToolbar
              estimate={estimate}
              entityName={entityName}
              advancedMode={advancedMode}
              onToggleAdvancedMode={onToggleAdvancedMode}
              group={group}
              onCreateItem={actions.createItem}
            />
          }
          { ...tableProps }
        />

        <EditDialog
          entity={entityName || "item"}
          data={editingItem}
          createNew={creatingItem}
          onClose={actions.stopEditing}
          onEdit={actions.patchItem}
          onSave={actions.saveItem}
          fields={fields}
        />
      </GridItem>
    </GridContainer>
  );
}
