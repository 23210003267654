export default theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {},
  dataRow: {
    background: "var(--row-background, transparent) !important",
    "&:hover": {
      boxShadow: theme.shadows[3],
      "--row-background": "#f9fafb",

      "& > td:last-child > .table-row-actions": {
        opacity: 1,
        pointerEvents: "all"
      }
    }
  },
  expandableRow: {
    cursor: "pointer",
    "&:hover": {
      boxShadow: "none"
    },
    "&$expandedRow:hover": {
      backgroundColor: "transparent !important"
    }
  },
  clickableRow: {
    cursor: "pointer"
  },
  expandedRow: {
    "& $expander": {
      transform: "rotate(90deg)"
    },
    "& > td": {
      borderBottom: "none"
    }
  },
  nestedRow: {
    cursor: "cell"
  },
  sortableCell: {
    display: "flex" // instead of default "inline-flex", to stretch them horizontally
  },
  sortIcon: {
    width: 16
  },
  headerCell: {
    padding: theme.spacing(1.5, .5),
    borderBottomWidth: 2
  },
  headerCellWithFilters: {
    borderBottomWidth: 0
  },
  blankHeaderCell: {
    padding: 0,
    "&$filterCell": {
      padding: 0
    },
    "& + $headerCell": {
      paddingLeft: theme.spacing(3)
    }
  },
  filterCell: {
    padding: theme.spacing(0, .5, .5),
    borderBottomWidth: 2
  },
  footerCell: {
    borderBottom: "none",
    paddingTop: theme.spacing(4),
    fontSize: "medium",
    fontWeight: 600,
    color: theme.palette.text.primary
  },
  paginator: {
    marginTop: theme.spacing(2),
  },
  paginatorCaption: {
    color: theme.palette.grey["500"]
  },
  groupedCell: {
    padding: theme.spacing(1.4, 1),
    color: theme.palette.grey["800"],
    fontWeight: theme.typography.fontWeightBold,
    "&$dataCellSmall": {
      padding: theme.spacing(1)
    }
  },
  dataCell: {
    padding: theme.spacing(1.5, .5),
    wordBreak: "break-word",

    "&:last-child": {
      position: "relative",
      minWidth: 130,

      "& .table-row-actions": {
        opacity: 0,
        pointerEvents: "none"
      }
    }
  },
  dataCellSmall: {
    padding: theme.spacing(.5)
  },
  expanderCell: {
    padding: 0,
    width: 30,
    textAlign: "right",
    "& + $dataCell": {
      paddingLeft: theme.spacing(3)
    }
  },
  expander: {
    marginTop: 6,
    transition: "transform 250ms ease-in-out"
  },

  emptyTableMessage: {
    padding: "8% 0",
    textAlign: "center",
    fontWeight: 400,
    fontSize: "xx-large",
    color: theme.palette.text.hint,
    opacity: .75
  },

  launcher: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 2,
    borderRadius: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(255, 255, 255, .9)"
  }
} as any);
