import React from "react";
// nodejs library that concatenates classes
import cn from "classnames";
// nodejs library to set properties for components

// core components
import { useStyles} from "./cardIconStyle";


interface ICardIconProps {
  className?: string;
  color: string;
  title: string;
  [key: string]: any;
}

function CardIcon({ className, children, color, title, ...rest }: React.PropsWithChildren<ICardIconProps>) {
  const classes = useStyles();
  const cardIconClasses = cn({
    [classes.cardIcon]: true,
    [classes[color + "CardHeader"]]: color,
    [className]: className !== undefined
  });

  return (
    <div className={cardIconClasses} {...rest}>
      {children}
      {!!title && <h4 className={classes.title}>{title}</h4>}
    </div>
  );
}

export default CardIcon;
