// This file contains some constants defined on server-side in `settings.yml`; make sure they are the same

export enum UserRole {
  ADMIN = "admin",
  MANAGER = "manager",
  USER = "user"
}

export enum BasicGroup {
  MATERIALS = "materials",
  LABOR = "labor",
  GUTTER = "gutter"
}

export enum EstimateType {
  ROOFING = "roofing",
  SIDING = "siding",
  COMMON = "common"
}

export enum DocumentType {
  CONTRACT = "contract",
  INVOICE = "invoice",
  EMAIL = "email_notification"
}

export enum EstimateStatus {
  DRAFT = "draft",
  PENDING = "pending",
  SIGNED = "signed",
  CLOSED = "closed"
}

export enum AuditLogLevel {
  DEFAULT = "default",
  IMPORTANT = "important",
  CRITICAL = "critical"
}
