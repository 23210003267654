import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { addNormalizedItem, deleteNormalizedItem, normalizeArray, updateNormalizedItem } from './normalizer';
import { EntitiesState } from './state';


export type GenericReducers<T extends Entity> = SliceCaseReducers<EntitiesState<T>>;

export function createDefaultState<T extends Entity>(): EntitiesState<T> {
  return {
    keys: [],
    byKey: {},
    loading: false,
    fetched: false,
    error: false,
    creating: {
      entity: null,
      loading: false,
      error: null
    },
    updating: {
      entity: null,
      loading: false,
      error: null
    }
  };
}

export type ReducerFunction<T extends Entity, PayloadType = never> =
  (state: EntitiesState<T>, action: PayloadAction<PayloadType>) => EntitiesState<T>;

// here, each ReducerFunction specifies the entity type (T) and the type of the action payload
export interface IDefaultReducers<T extends Entity> {
  // read
  startReading: (state: EntitiesState<T>) => EntitiesState<T>;
  finishReading: ReducerFunction<T, T[]>;
  cancelReading: ReducerFunction<T, boolean>;
  // special case for reading single record
  finishReadingOne: ReducerFunction<T, T>;
  // create
  startCreating: ReducerFunction<T, Partial<T>>;
  finishCreating: ReducerFunction<T, T>;
  cancelCreating: ReducerFunction<T, string>;
  // update
  startUpdating: ReducerFunction<T, Partial<T>>;
  finishUpdating: ReducerFunction<T, T>;
  cancelUpdating: ReducerFunction<T, string>;
  // delete
  startDeleting: ReducerFunction<T>;
  finishDeleting: ReducerFunction<T, EntityId>;
  cancelDeleting: ReducerFunction<T, boolean>;
  // local actions (synchronous)
  createLocal: ReducerFunction<T, Partial<T>|null>;
  updateLocal: ReducerFunction<T, Partial<T>|null>;
  syncLiveEntity: ReducerFunction<T, { action: 'create'|'update'|'delete', entity: T }>;

  [action: string]: ReducerFunction<T>;
}

export function createDefaultReducers<T extends Entity>(): IDefaultReducers<T> {
  return {
    startReading: (state) => ({
      ...state,
      loading: true,
      error: false
    }),
    finishReading: (state, action) => ({
      ...state,
      ...normalizeArray(action.payload),
      loading: false,
      fetched: true,
      error: false
    }),
    cancelReading: (state, action) => ({
      ...state,
      loading: false,
      fetched: !action.payload, // tryAgainLater,
      error: true
    }),
    finishReadingOne: (state, action) => ({
      ...updateNormalizedItem(state, action.payload),
      // in case we have loaded the details of an entity which is being updated,
      // make sure to actualize it in the `updating` state
      updating: state.updating.entity?.id === action.payload.id ? {
        ...state.updating,
        entity: {
          ...action.payload,
          ...state.updating.entity
        }
      } : state.updating
    }),
    startCreating: (state, action) => ({
      ...state,
      creating: {
        ...state.creating,
        entity: action.payload,
        error: null,
        loading: true
      }
    }),
    finishCreating: (state, action) => ({
      ...addNormalizedItem(state, action.payload),
      creating: createDefaultState<T>().creating
    }),
    cancelCreating: (state, action) => ({
      ...state,
      creating: {
        ...state.creating,
        loading: false,
        error: action.payload
      }
    }),
    startUpdating: (state, action) => ({
      ...state,
      updating: {
        ...state.updating,
        entity: action.payload,
        error: null,
        loading: true
      }
    }),
    finishUpdating: (state, action) => ({
      ...updateNormalizedItem(state, action.payload),
      updating: createDefaultState<T>().updating
    }),
    cancelUpdating: (state, action) => ({
      ...state,
      updating: {
        ...state.updating,
        loading: false,
        error: action.payload
      }
    }),
    startDeleting: state => ({
      ...state,
      loading: true,
      error: false
    }),
    finishDeleting: (state, action) => ({
      ...deleteNormalizedItem(state, action.payload),
      loading: false,
      error: false
    }),
    cancelDeleting: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload
    }),
    createLocal: (state, action) => ({
      ...state,
      creating: {
        ...state.creating,
        entity: action.payload,
        error: action.payload ? state.creating.error : null // reset error if entity is reset
      }
    }),
    updateLocal: (state, action) => ({
      ...state,
      updating: {
        ...state.updating,
        entity: action.payload,
        error: action.payload ? state.updating.error : null // reset error if entity is reset
      }
    }),
    // synchronize an entity with backend by either adding it, updating, or deleting,
    // according to an entity_action WebSocket message
    // TODO: there is only one problem: the user who initiated the change already has an actual state
    // in the store, but still he receives this "live-tracking" action which updates the state again without any need
    syncLiveEntity: (state, action) => {
      const entity = action.payload.entity;
      switch (action.payload.action) {
        case 'create':
          return addNormalizedItem(state, entity);
        case 'update':
          return updateNormalizedItem(state, action.payload.entity);
        case 'delete':
          return deleteNormalizedItem(state, action.payload.entity.id);
      }
    }
  };
}
