import React from "react";
import cn from "classnames";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";

import { formatCurrency, formatPercentage } from "common/utils/format.util";
import {
  BALANCE_DEFAULTS,
  recalculateTotals,
  getSubtotalIncome,
  getSubtotalCosts,
  getMaterialTaxAmount,
  getExtraProfitAmount,
  getOverheadAmount,
  getNetProfit,
  getCapOut
} from "common/utils/finance.util";

import useStyles from "./balanceSheetStyle";


export default function BalanceSheet({ balance, onUpdate }) {
  const classes = useStyles();

  const updateBalance = (key, value) => onUpdate(recalculateTotals({
    ...balance,
    [key]: value
  }));

  const toggleField = (key) => updateBalance(key, balance[key] > 0 ? 0 : BALANCE_DEFAULTS[key]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.firstCol} />
            <TableCell>Item</TableCell>
            <TableCell align="right">Income</TableCell>
            <TableCell align="right">Costs</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell className={classes.firstCol} />
            <TableCell>
              Estimated items
            </TableCell>
            <TableCell align="right" className={classes.income}>
              {formatCurrency(balance.items)}
            </TableCell>
            <TableCell align="right"/>
          </TableRow>

          <TableRow>
            <TableCell className={classes.firstCol} />
            <TableCell>
              Labor
            </TableCell>
            <TableCell align="right"/>
            <TableCell align="right" className={classes.costs}>
              {formatCurrency(-balance.labor)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.firstCol} />
            <TableCell>
              Gutter
            </TableCell>
            <TableCell align="right"/>
            <TableCell align="right" className={classes.costs}>
              {formatCurrency(-balance.gutter)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.firstCol} />
            <TableCell>
              Materials
            </TableCell>
            <TableCell align="right"/>
            <TableCell align="right" className={classes.costs}>
              {formatCurrency(-balance.materials)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.firstCol} />
            <TableCell>
              <strong>Subtotal</strong>
            </TableCell>
            <TableCell align="right" className={classes.income}>
              <strong>{formatCurrency(getSubtotalIncome(balance))}</strong>
            </TableCell>
            <TableCell align="right" className={classes.costs}>
              <strong>
                {formatCurrency(-getSubtotalCosts(balance))}
              </strong>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.firstCol}>
              <Checkbox
                checked={balance.materialTax > 0}
                color="secondary"
                onChange={() => toggleField("materialTax")}
              />
            </TableCell>
            <TableCell>
              Material tax
              <Input
                value={balance.materialTax * 100}
                endAdornment="%"
                className={classes.input}
                type="number"
                onChange={e => updateBalance("materialTax", +e.target.value / 100)}
                inputProps={{
                  min: 0,
                  max: 100
                }}
              />
            </TableCell>
            <TableCell align="right" className={classes.income}>
              {formatCurrency(getMaterialTaxAmount(balance))}
            </TableCell>
            <TableCell align="right" className={classes.costs}>
              {formatCurrency(-getMaterialTaxAmount(balance))}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.firstCol}>
              <Checkbox
                checked={balance.overhead > 0}
                color="secondary"
                onChange={() => toggleField("overhead")}
              />
            </TableCell>
            <TableCell>
              Overhead
              <Input
                value={balance.overhead * 100}
                endAdornment="%"
                className={classes.input}
                type="number"
                onChange={e => updateBalance("overhead", +e.target.value / 100)}
                inputProps={{
                  min: 0,
                  max: 100
                }}
              />
            </TableCell>
            <TableCell align="right" className={classes.income}>
              {formatCurrency(getOverheadAmount(balance))}
            </TableCell>
            <TableCell align="right"/>
          </TableRow>

          <TableRow>
            <TableCell className={classes.firstCol}>
              <Checkbox
                checked={balance.profitRate > 0}
                color="secondary"
                onChange={() => toggleField("profitRate")}
              />
            </TableCell>
            <TableCell>
              Profit
              <Input
                value={balance.profitRate * 100}
                endAdornment="%"
                className={classes.input}
                type="number"
                onChange={e => updateBalance("profitRate", +e.target.value / 100)}
                inputProps={{
                  min: 0
                }}
              />
            </TableCell>
            <TableCell align="right" className={classes.income}>
              {formatCurrency(getExtraProfitAmount(balance))}
            </TableCell>
            <TableCell align="right"/>
          </TableRow>

          <TableRow>
            <TableCell className={classes.firstCol}>
              <Checkbox
                checked={balance.discountAmount > 0}
                color="secondary"
                onChange={() => toggleField("discountAmount")}
              />
            </TableCell>
            <TableCell>
              Discount
            </TableCell>
            <TableCell align="right" className={classes.income}>
              <Input
                value={balance.discountAmount || 0}
                startAdornment="-$"
                className={cn(classes.input, classes.income)}
                type="number"
                onChange={e => updateBalance("discountAmount", +e.target.value)}
                inputProps={{
                  min: 0
                }}
              />
            </TableCell>
            <TableCell align="right"/>
          </TableRow>

          <TableRow>
            <TableCell className={classes.firstCol} />
            <TableCell>
              <strong>Total</strong>
            </TableCell>
            <TableCell align="right" className={classes.income}>
              <strong>{formatCurrency(balance.totalIncome)}</strong>
            </TableCell>
            <TableCell align="right" className={classes.costs}>
              <strong>{formatCurrency(balance.totalCosts)}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <div className={classes.totals}>
        <div className={classes.totalRow}>
          Profit: <strong>{formatCurrency(getNetProfit(balance))}</strong>
        </div>
        <div className={classes.totalRow}>
          Cap Out: <strong>{formatPercentage(getCapOut(balance))}</strong>
        </div>
      </div>
    </>
  );
}
