import { Channel } from 'actioncable';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import CableService from 'services/cable.service';


export interface EntityActionMessage<T extends Entity = Entity> {
  type: 'entity_action';
  entityName: string;
  payload: T;
  action: 'create'|'update'|'delete';
}

class CompanyChannel {
  public entityActions$ = new Subject<EntityActionMessage>();
  private channel: Channel;

  public trackEntityLive<T extends Entity>(entityName: EntityActionMessage['entityName']) {
    return this.entityActions$.pipe(
      filter(message => message.entityName === entityName)
    ) as Observable<EntityActionMessage<T>>;
  }

  public listen() {
    if (!this.channel)
      this.channel = CableService.subscribeToChannel<EntityActionMessage>('CompanyChannel', {
        received: (data) => {
          this.entityActions$.next(data);
        }
      });
  }

  public stopListening() {
    this.channel?.unsubscribe();
    this.channel = null;
  }
}

export default new CompanyChannel();
