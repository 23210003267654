export const baseURL = "/api";

export const baseAuthURL = "/";

export const timeout = 40000;

export const authHeader = "Authorization";

export const defaultHeaders = {
  "Content-Type": "application/json",
  "X-Key-Inflection": "camel" // ask for camel-cased JSON keys in response
};

export const endpoints = {
  login: "/login",
  logout: "/logout"
};

export const statuses = {
  UNAUTHENTICATED: 401,
  UNAUTHORIZED: 403,
  INVALID: 422
};
