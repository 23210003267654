import React from "react";

import { ExitToApp } from "@material-ui/icons";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from "@material-ui/core/Tooltip";


interface INavLinksProps {
  logOut: () => void;
}

const NavLinks: React.FunctionComponent<INavLinksProps> = (props) => {
  const { logOut } = props;

  return (
    <div>
      <Tooltip title="Log out" placement="bottom">
        <IconButton onClick={logOut}>
          <ExitToApp />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default NavLinks;
