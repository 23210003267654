import { makeStyles } from "@material-ui/core/styles";

import { warningColor } from "assets/jss/common";


export const useStyles = makeStyles(theme => ({
  actionsRight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    paddingLeft: 25,
    paddingRight: 10,
    background: "linear-gradient(to right, transparent, var(--row-background, white) 20px)"
  },
  actionBtn: {
    color: theme.palette.grey["500"],
    transition: "color 200ms ease-in-out"
  },
  primaryButton: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  secondaryButton: {
    "&:hover": {
      color: theme.palette.secondary.main
    }
  },
  dangerButton: {
    "&:hover": {
      color: theme.palette.error.main
    }
  },
  warningButton: {
    "&:hover": {
      color: warningColor[0]
    }
  },
  menuItemIcon: {
    marginRight: theme.spacing(1)
  }
}));
