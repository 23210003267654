export const CKEditorCommon = {
  // Important! This option disables any content filtering, thus all tags and Thymeleaf attributes are preserved
  allowedContent: true,
  height: "60vh",
  bodyClass: "wysiwyg", // classes referenced in pdf_in_html.css
  font_names: "Times New Roman;Georgia;Tahoma;Helvetica;Arial;monospace"
};

export const CKEditorPDF = {
  ...CKEditorCommon,
  bodyClass: "a4-format page wysiwyg",
  contentsCss: ["/stylesheets/pdf_in_html.css"]
};

export const CKEditorEmail = {
  ...CKEditorCommon,
  contentsCss: ["/stylesheets/email_in_html.css"],
};
