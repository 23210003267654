export enum AppIcon {
  CUSTOMER = 'perm_contact_calendar',
  ESTIMATE = 'assessment',
  DASHBOARD = 'dashboard',
  AUDIT = 'history',
  ADMIN = 'font_download',
  USERS_OUTLINED = 'person_outlined',
  ITEMS_OUTLINED = 'list_outlined',
  TEMPLATES_OUTLINED = 'library_books'
}
