import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { BasicGroup } from "config/constants.config";
import { buildUpdatingEstimateBasicItemsSelector } from "store/entities/estimates";

import ItemsWrapper from "../ItemsWrapper/ItemsWrapper";


export default function LaborStep({ estimate, patch }) {
  const laborSelector = useMemo(
    () => buildUpdatingEstimateBasicItemsSelector(BasicGroup.LABOR),
    [estimate.id, estimate.type]
  );
  const labors = useSelector(laborSelector);

  return (
    <ItemsWrapper
      estimate={estimate}
      patch={patch}
      data={labors}
      group={BasicGroup.LABOR}
      entityName="labor"
    />
  );
}
