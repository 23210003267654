import { makeStyles } from '@material-ui/core/styles';
import { dangerColor, primaryColor, successColor, warningColor } from "assets/jss/common";


export default makeStyles(theme => ({
  typeIcon: {
    color: theme.palette.grey["700"]
  },
  income: {
    color: successColor[0],
    wordBreak: "keep-all"
  },
  costs: {
    color: dangerColor[0],
    wordBreak: "keep-all"
  },
  creationButton: {
    position: "fixed",
    bottom: 25,
    right: 30,
    padding: theme.spacing(.5, 3),
    zIndex: 100,
    backgroundColor: successColor[0],
    color: "white",
    "& svg": {
      width: 30,
      height: 30
    },
    "&:hover": {
      backgroundColor: successColor[1]
    },
    "&::before": {
      content: "'+'",
      position: "absolute",
      left: 10,
      bottom: 18,
      lineHeight: 0,
      fontSize: "30px",
      textShadow: `2px -2px 0 ${successColor[0]}`
    }
  },
  roofingButton: {
    bottom: 100
  },
  status: {
    display: "inline-block",
    padding: theme.spacing(.25, 1),
    borderRadius: 4,
    color: "white",
    wordBreak: "keep-all",
    opacity: .8,
    "&:hover": {
      opacity: 1
    }
  },
  statusDraft: {
    background: dangerColor[0]
  },
  statusPending: {
    background: warningColor[0]
  },
  statusSigned: {
    background: successColor[0]
  },
  statusClosed: {
    background: primaryColor[0]
  }
}));
