export default () => ({
  root: {
    justifyContent: "space-between"
  },
  toolbarLeft: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "stretch"
  }
});
