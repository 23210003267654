import { makeStyles } from "@material-ui/core/styles";
import { grayColor } from 'assets/jss/common';


export const useStyles = makeStyles(theme => ({
  header: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 10px 8px 16px',
    zIndex: 10,
    backgroundColor: 'white'
  },
  title: {
    margin: 0,
    fontSize: '1.2rem',
    fontWeight: theme.typography.fontWeightMedium
  },
  link: {
    fontSize: 'small',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  listPlaceholder: {
    padding: '20px 30px',
    fontWeight: theme.typography.fontWeightMedium,
    color: grayColor[1]
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 100px'
  },
  item: {
    width: 400,
    maxWidth: '95vw'
  },
  clickableNotification: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 15, 0.1)'
    }
  }
}));
