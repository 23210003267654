import { createSelector } from '@reduxjs/toolkit';
import mapValues from "lodash/mapValues";

import { IItem, IItemsCategory, IUnit } from 'types/entities';
import { BasicGroup, EstimateType } from "config/constants.config";

import { AppState } from 'store/store.d';
import { EntitiesByKey } from 'store/helpers/entities';
import { selectors as itemsSelectors } from "store/entities/items";
import { selectors as categoriesSelectors } from "store/entities/categories";
import { selectors as unitsSelectors } from "store/entities/units";
import { buildItemFormulasSelector } from "store/entities/formulas";


export interface IExtendedItem extends IItem {
  category: IItemsCategory | {};
  unit: IUnit | {};
}

const getFullState = (state: AppState) => state.associatedItems;
const isLoading = (state: AppState) => state.associatedItems.loading;
const isLoaded = (state: AppState) => state.associatedItems.loaded;

const itemsByKeys = createSelector(
  itemsSelectors.entitiesByKey,
  categoriesSelectors.entitiesByKey,
  unitsSelectors.entitiesByKey,
  (byKey, categoriesByKey, unitsByKey) =>
    mapValues(byKey, (item: IItem) => ({
      ...item,
      category: categoriesByKey[item.categoryId] as IItemsCategory || {},
      unit: unitsByKey[item.unitId] as IUnit || {}
    })) as EntitiesByKey<IExtendedItem>
);

const getAllItems = createSelector(
  itemsSelectors.getEntities,
  itemsByKeys,
  (items, byKeys) =>
    items.map(item => byKeys[item.id]) as IExtendedItem[]
);

const getCompositeItems = createSelector(
  getAllItems,
  items =>
    items.filter(isCompositeItem)
);

const getBasicItems = createSelector(
  getAllItems,
  items =>
    items.filter(isBasicItem)
);

const buildBasicItemsSelector = (basicGroup: BasicGroup, estimateType: EstimateType = null) =>
  createSelector(
    getBasicItems,
    items =>
      items.filter(item =>
        matchesBasicGroup(item, basicGroup) && matchesEstimateType(item, estimateType)
      )
  );

const buildCompositeItemsSelector = (estimateType: EstimateType) =>
  createSelector(
    getAllItems,
    items =>
      items.filter(item =>
        matchesBasicGroup(item, null) && matchesEstimateType(item, estimateType)
      )
  );

const buildFormulasSelector = (item: IItem, basicGroup: BasicGroup) => {
  const getItemFormulas = buildItemFormulasSelector(item);

  return createSelector(
    getItemFormulas,
    itemsByKeys,
    (formulas, byKey) =>
      formulas.filter(formula =>
        matchesBasicGroup(
          byKey[formula.nestedItemId],
          basicGroup
        )
      )
  );
};

const isBasicItem = (item: IExtendedItem) =>
  !!item["category"]["basicGroup"];

const isCompositeItem = (item: IExtendedItem) =>
  !item["category"]["basicGroup"];

const matchesBasicGroup = (item: IExtendedItem, basicGroup: BasicGroup) =>
  item["category"]["basicGroup"] === basicGroup;

const matchesEstimateType = (item: IExtendedItem, estimateType: EstimateType) =>
  !estimateType
  || item["category"]["type"] === estimateType
  || item["category"]["type"] === EstimateType.COMMON;

export {
  getFullState,
  isLoading,
  isLoaded,
  itemsByKeys,
  getAllItems,
  getCompositeItems,
  getBasicItems,
  buildBasicItemsSelector,
  buildCompositeItemsSelector,
  buildFormulasSelector
};
