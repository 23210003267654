import React, { useCallback, useMemo } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import compact from "lodash/compact";
import {
  House,
  LineWeight,
  PermContactCalendar,
  PersonAdd,
  PostAdd
} from "@material-ui/icons";

import { actions as customersActions, selectors as customersSelectors } from "store/entities/customers";
import { actions as estimatesActions, selectors as estimatesSelectors } from "store/entities/estimates";

import { EstimateType } from "config/constants.config";
import { xsBreakpoint } from "config/common.config";
import { formatCurrency } from "common/utils/format.util";
import { useReadAction } from "common/hooks/crud/useReadAction";
import { useCRUDActions } from "common/hooks/crud/useCRUDActions";
import TableCRUD from "common/views/TableCRUD/TableCRUD";
import GridContainer from "common/components/Grid/GridContainer";
import GridItem from "common/components/Grid/GridItem";


export function CustomersPage({ history }) {
  useReadAction(estimatesActions, estimatesSelectors); // load estimates to display estimates number in the table

  const [state, actions] = useCRUDActions(customersActions, customersSelectors, {
    confirmDeletion: true,
    defaults: {
      name: "",
      email: "",
      phone: "",
      address: "",
      company: ""
    }
  });

  const showCustomerEstimates = useCallback(
    customer => history.push(`/estimates?customerId=${customer.id}`),
    []
  );

  const tableActions = useCallback(
    customer => [{
      title: "Create estimate",
      type: "secondary",
      Icon: PostAdd,
      menu: {
        id: "estimate-type-menu",
        items: [{
          title: "Roofing",
          Icon: House,
          callback: () => history.push(`/estimates/new/wizard?type=${EstimateType.ROOFING}&customerId=${customer.id}`)
        }, {
          title: "Siding",
          Icon: LineWeight,
          callback: () => history.push(`/estimates/new/wizard?type=${EstimateType.SIDING}&customerId=${customer.id}`)
        }]
      }
    }],
    []
  );

  // TODO: reduce space for input filters and email column; hide unimportant columns on small screens; allow card to be more than 100% wide
  const tableProps = useMemo(() => ({
    defaultSortBy: [{ id: "updatedAt", desc: true }],
    recordAction: showCustomerEstimates
  }), [showCustomerEstimates]);

  const columns = useMemo(
    () => compact([
      {
        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Email",
        accessor: "email",
        style: { maxWidth: "10vw", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }
      }, {
        Header: "Phone",
        accessor: "phone",
        Cell: ({ cell: { value }}) => value || ""
      },
      {
        Header: "Address",
        accessor: "address"
      },
      window.innerWidth > xsBreakpoint && {
        Header: "Company",
        accessor: "company",
        Cell: ({ cell: { value }}) => value || ""
      },
      window.innerWidth > xsBreakpoint && {
        Header: "Updated",
        accessor: "updatedAt",
        sortDescFirst: true,
        disableFilters: true,
        title: ({ value }) => moment(value).format("DD MMM YYYY, HH:mm"),
        Cell: ({ cell: { value }}) => moment(value).fromNow()
      },
      {
        Header: "Estimates",
        accessor: "estimatesTotal",
        sortType: "basic",
        disableFilters: true,
        Cell: ({ cell: { value }, row: { original }}) => (
          <>
            {formatCurrency(value || 0)}&nbsp;
            (<strong>{original.estimatesCount || 0}</strong>)
          </>
        )
      }
    ]),
    [window.innerWidth]
  );

  const fields = useMemo(
    () => [
      {
        label: "Name",
        required: true,
        accessor: "name"
      }, {
        label: "Email Address",
        accessor: "email",
        TextFieldProps: {
          type: "email"
        }
      }, {
        label: "Phone",
        accessor: "phone"
      }, {
        label: "Address",
        accessor: "address",
        TextFieldProps: {
          multiline: true
        }
      }, {
        label: "Company",
        accessor: "company"
      }
    ],
    []);

  return (
     <GridContainer>
       <GridItem xs={12}>
         <TableCRUD
           Icon={PermContactCalendar}
           IconCreate={PersonAdd}
           extraActions={tableActions}
           crudState={state}
           actions={actions}
           columns={columns}
           fields={fields}
           entity="customer"
           TableProps={tableProps}
           getRemovalConfirmation={customer => `Are you sure you want to delete ${customer.name}?`}
         />
       </GridItem>
     </GridContainer>
  );
}

export default withRouter(CustomersPage);
