import { makeStyles } from '@material-ui/core/styles';
import { primaryColor, successColor, whiteColor } from "assets/jss/common";
import { dangerColor } from "assets/jss/common";


export default makeStyles(theme => ({
  fab: {
    position: "fixed",
    bottom: 25,
    right: 30,
    padding: theme.spacing(.5, 3),
    zIndex: 99999, // to be visible in wysiwyg's full-screen mode
    "& svg": {
      width: 30,
      height: 30
    }
  },

  previewBtn: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    "&:hover": {
      backgroundColor: primaryColor[1],
      color: whiteColor
    }
  },

  cancelBtn: {
    bottom: 100,
    backgroundColor: dangerColor[4],
    color: whiteColor,
    "&:hover": {
      backgroundColor: dangerColor[0]
    },
    "& svg": {
      width: 36,
      height: 36
    }
  },

  saveBtn: {
    backgroundColor: successColor[0],
    color: whiteColor,
    "&:hover": {
      backgroundColor: successColor[1]
    }
  }
}));
