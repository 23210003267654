import { connect } from 'react-redux';

import { AppState } from 'store/store.d';
import { selectors } from 'store/auth';
import { AuthModule, IAuthModuleProps } from './AuthModule';


type StateProps = Pick<IAuthModuleProps, 'authenticated'>;

const mapStateToProps = (state: AppState): StateProps => ({
  authenticated: selectors.isAuthenticated(state)
});

export default connect(mapStateToProps)(AuthModule);
