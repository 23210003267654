import { createStyles, Theme } from '@material-ui/core/styles';


export const styles = (theme: Theme) => createStyles({
  pageHeader: {
    position: "relative",
    zIndex: 1400, // to make buttons clickable even though they overlap with layout header
    textAlign: "center",
    marginTop: -30,
    marginBottom: theme.spacing(2)
  },
  tabsRoot: {
    display: "inline-flex"
  },
  tabsContainer: {
    justifyContent: "center"
  },
  tabIndicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      width: "75%",
      backgroundColor: theme.palette.secondary.main,
    }
  },
  tabPane: {
    minWidth: "9vw",
    textTransform: "none",
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&$tabPaneSelected": {
      opacity: 1,
      fontWeight: theme.typography.fontWeightMedium
    },
    "&:hover": {
      opacity: 1,
      color: theme.palette.text.primary
    },
    "&:focus": {
      color: theme.palette.text.primary
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "110px"
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 4,
      paddingRight: 4
    }
  },
  tabPaneSelected: {}
});
