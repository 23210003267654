import React, { useCallback } from "react";
import cn from "classnames";

import { Close } from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";

import { BasicGroup } from "config/constants.config";
import  { actions as formulasActions } from "store/entities/formulas";
import { useFormulasGroup } from "common/hooks/formulas/useFormulasGroup";
import { useFormulasAPI } from "common/hooks/formulas/useFormulasAPI";
import Button from "common/components/CustomButtons/Button";

import useStyles from "./itemFormulasConstructorStyle";
import ItemFormulasGroup from "../ItemFormulasGroup/ItemFormulasGroup";
import CircularProgress from "@material-ui/core/CircularProgress";


function ItemFormulasConstructor(props) {
  const { item, onClose, readOnly, quantity } = props;
  const classes = useStyles();

  const material = useFormulasGroup(item, BasicGroup.MATERIALS);
  const labor = useFormulasGroup(item, BasicGroup.LABOR);
  const gutter = useFormulasGroup(item, BasicGroup.GUTTER);
  const [commonState, commonActions] = useFormulasAPI([
    material,
    labor,
    gutter
  ], formulasActions.bulkUpdate);

  const close = useCallback(() => {
    let confirmed = true;

    if (commonState.hasChanges)
      confirmed = window.confirm("Are you sure you want to close without saving?");

    if (confirmed)
      onClose();
  }, [commonState.hasChanges, onClose]);

  const showMaterials = !readOnly || material[0].all && material[0].all.length > 0;
  const showLabor = !readOnly || labor[0].all && labor[0].all.length > 0;
  const showGutter = !readOnly || gutter[0].all && gutter[0].all.length > 0;

  return (
    <Paper elevation={2}>
      <h3 className={classes.title}>
        <IconButton size="small" onClick={close} className={classes.closeBtn}>
          <Close/>
        </IconButton>
        <strong>{ quantity || "One"} {item.unit.name}</strong> of this item
        { quantity > 1 ? " include" : " includes" }...
      </h3>
      <div className={cn(classes.container, readOnly && classes.readonly)}>
        { showMaterials && (
          <ItemFormulasGroup
            title="Materials"
            addBtnTitle="+ Material"
            className={classes.itemsGroup}
            estimateType={item.category.type}
            basicGroup={BasicGroup.MATERIALS}
            formulas={material[0].all}
            actions={material[1]}
            multiplier={quantity}
            readOnly={readOnly}
          />
        )}
        { showLabor && (
          <ItemFormulasGroup
            title="Labor"
            addBtnTitle="+ Labor"
            className={classes.itemsGroup}
            estimateType={item.category.type}
            basicGroup={BasicGroup.LABOR}
            formulas={labor[0].all}
            actions={labor[1]}
            multiplier={quantity}
            readOnly={readOnly}
          />
        )}
        { showGutter && (
          <ItemFormulasGroup
            title="Gutter"
            addBtnTitle="+ Gutter Item"
            className={classes.itemsGroup}
            estimateType={item.category.type}
            basicGroup={BasicGroup.GUTTER}
            formulas={gutter[0].all}
            actions={gutter[1]}
            multiplier={quantity}
            readOnly={readOnly}
          />
        )}

        { !showMaterials && !showLabor && !showGutter && (
          <div className={classes.placeholder}>
            No sub-items
          </div>
        )}

        { !readOnly && (
          <div className={classes.actions}>
            { commonState.loading && (
              <CircularProgress size={24} className={classes.launcher} />
            )}
            { commonState.hasChanges && (
              <>
                <Button onClick={commonActions.cancel} color="transparent" disabled={commonState.loading}>Cancel</Button>
                <Button onClick={commonActions.save} color="primary" disabled={commonState.loading}>SAVE</Button>
              </>
            )}
          </div>
        )}
      </div>
    </Paper>
  );
}

export default ItemFormulasConstructor;
