import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { List } from "@material-ui/icons";

import { formatCurrency } from "common/utils/format.util";
import { useCRUDActions } from "common/hooks/crud/useCRUDActions";
import { selectors as associatedItemsSelectors } from "store/associatedItems";
import { selectors as unitsSelectors } from "store/entities/units";
import { selectors as categoriesSelectors } from "store/entities/categories";
import { actions as itemsActions, selectors as itemsSelectors } from "store/entities/items";
import TableCRUD from "common/views/TableCRUD/TableCRUD";

import ItemFormulasConstructor from "./ItemFormulasConstructor/ItemFormulasConstructor";


export default function ItemsPage() {
  const units = useSelector(unitsSelectors.getEntities);
  const categories = useSelector(categoriesSelectors.aggregatedCategories);
  const categoriesByKey = useSelector(categoriesSelectors.entitiesByKey);

  const [state, actions] = useCRUDActions(itemsActions, {
    ...itemsSelectors,
    getEntities: associatedItemsSelectors.getCompositeItems
  }, {
    confirmDeletion: true,
    immediate: false,
    defaults: {
      name: "",
      price: 0,
      unitId: null,
      categoryId: null
    }
  });

  const columns = useMemo(
    () => [
      {
        Header: "Category",
        accessor: "categoryId",
        Cell: ({ cell: { value }}) => categoriesByKey[value] && categoriesByKey[value].name || ""
      },
      {
        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Type",
        accessor: "category.type"
      },
      {
        Header: "Price",
        accessor: "price",
        sortType: "basic",
        Cell: ({ cell: { value }}) => formatCurrency(value)
      },
      {
        Header: "Unit",
        accessor: "unit.name"
      }
    ],
    [categoriesByKey]
  );

  const fields = useMemo(
    () => [
      {
        label: "Name",
        required: true,
        accessor: "name",
        TextFieldProps: {
          multiline: true
        }
      },
      {
        label: "Category",
        required: true,
        accessor: "categoryId",
        options: categories && categories.map(cat => ({
          value: cat.id,
          label: `${cat.name} [${cat.type}]`
        })) || []
      },
      {
        label: "Price",
        required: true,
        inline: true,
        accessor: "price",
        TextFieldProps: {
          type: "number"
        }
      },
      {
        label: "Unit",
        required: true,
        inline: true,
        accessor: "unitId",
        style: {
          maxWidth: 120,
          marginLeft: 16
        },
        options: units && units.map(u => ({
          value: u.id,
          label: u.name
        })) || []
      }
    ],
    [units, categories]);

  const renderRowSubComponent = useCallback(
    ({ row, close }) => (
      <ItemFormulasConstructor item={row.original} onClose={close}/>
    ),
    []
  );

  return (
    <TableCRUD
      Icon={List}
      title="Items"
      crudState={state}
      actions={actions}
      columns={columns}
      fields={fields}
      entity="item"
      TableProps={{
        defaultSortBy: [{ id: "categoryId" }],
        groupBy: "categoryId",
        renderRowSubComponent
      }}
      getRemovalConfirmation={item => `Are you sure you want to delete item ${item.name}?`}
    />
  );
}
