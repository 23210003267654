import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import MenuItem from "@material-ui/core/MenuItem";
import { Close } from "@material-ui/icons";

import Button from "common/components/CustomButtons/Button";
import { useStyles } from "./editDialogStyle";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";


function Transition(props) {
  return <Slide direction="down" {...props} />;
}

export default function EditDialog(props) {
  const { onClose, onSave, onEdit, data, createNew, title, entity, fields, loading, error } = props;
  const classes = useStyles();
  const [localErrors, setLocalErrors] = useState(null);

  useEffect(() => {
    if (!localErrors && error)
      setLocalErrors(error);
    else if (localErrors && !error)
      setLocalErrors(null);
  }, [error, localErrors, setLocalErrors]);

  const onInput = useCallback(field => (event, value) => {
    value = typeof value === 'string' ? value : event.target.value;

    onEdit({
      [field.accessor]: field.TextFieldProps && field.TextFieldProps.type === "number" ? +value : value
    });

    setLocalErrors({
      ...localErrors,
      [field.accessor]: null
    });
  }, [onEdit, setLocalErrors, localErrors]);

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal
      }}
      open={!!data}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <form onSubmit={e => { e.preventDefault(); onSave() }}>
        <DialogTitle disableTypography className={classes.modalHeader}>
          <Button
            justIcon
            round
            key="close"
            className={classes.modalCloseButton}
            color="transparent"
            onClick={onClose}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{ title || (createNew ? "Create " : "Edit ") + entity }</h4>
        </DialogTitle>
        <DialogContent className={classes.modalBody}>
          {data && fields.map(field => (
            <div className={cn(classes.formControl, field.inline && classes.formControlInline)} key={field.accessor} style={field.style}>
              { field.autocompleteOptions ? (
                <Autocomplete
                  freeSolo={field.free}
                  options={field.autocompleteOptions}
                  value={data[field.accessor] || ""}
                  onChange={onInput(field)}
                  onInputChange={onInput(field)}
                  disabled={field.disabled}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      required={field.required}
                      label={field.label}
                      error={localErrors && !!localErrors[field.accessor]}
                      InputLabelProps={{
                        className: classes.fieldLabel
                      }}
                      {...field.TextFieldProps}
                    />
                  )}
                />
              ) : (
                <TextField
                  fullWidth
                  required={field.required}
                  disabled={field.disabled}
                  select={!!field.options}
                  error={localErrors && !!localErrors[field.accessor]}
                  label={field.label}
                  value={data[field.accessor] || (data[field.accessor] === 0 ? 0 : "")} // allow to display zero in numeric input
                  onChange={onInput(field)}
                  InputLabelProps={{
                    className: classes.fieldLabel
                  }}
                  {...field.TextFieldProps}
                >
                  { field.options && field.options.map(option => (
                    <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {localErrors && localErrors[field.accessor] && (
                <div className={classes.error}>
                  The {field.label.toLowerCase()} {localErrors[field.accessor][0]}
                </div>
              )}
            </div>
          ))}
        </DialogContent>

        <DialogActions className={classes.modalFooter}>
          <Button type="button" onClick={onClose} color="transparent">Cancel</Button>
          <Button type="submit" color={ createNew ? "success" : "primary" } disabled={loading}>
            { createNew ? "Create" : "Save" }
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
