import React, { useCallback } from "react";
import cn from "classnames";

import { Close, RemoveRedEye, Save } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";

import CKEditor from "ckeditor4-react";

import useStyles from "./templateEditorStyle";


export default function TemplateEditor(props) {
  const { template, content, hasChanges, loading, actions, config } = props;
  const classes = useStyles();

  const patch = useCallback(data => {
      actions.patch({
        id: template.id,
        content: data
      });
  }, [actions.patch, content]);

  if (!template)
    return null;

  return (
    <>
      <CKEditor
        data={content}
        onChange={evt => patch(evt.editor.getData())}
        config={config}
      />

      {!hasChanges ? (
        <Tooltip title="Preview" placement="left">
          <Fab href={template.previewLink}
               target="_blank"
               className={cn(classes.previewBtn, classes.fab)}
               disabled={loading}>
            <RemoveRedEye />
          </Fab>
        </Tooltip>
      ) : (
        <>
          <Tooltip title="Cancel" placement="left">
            <Fab className={cn(classes.cancelBtn, classes.fab)} disabled={loading} onClick={() => actions.reset()}>
              <Close />
            </Fab>
          </Tooltip>
          <Tooltip title="Save" placement="left">
            <Fab className={cn(classes.saveBtn, classes.fab)} disabled={loading} onClick={() => actions.commit()}>
              <Save />
            </Fab>
          </Tooltip>
        </>
      )}
    </>
  );
}
