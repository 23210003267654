import { Dictionary, createSelector } from '@reduxjs/toolkit';
import mapValues from "lodash/mapValues";
import groupBy from "lodash/groupBy";

import { IEstimate } from 'types/entities';
import { AppSelector, AppState } from 'store/store.d';
import { createDefaultSelectors, CustomSelectors } from "store/helpers/entities";
import { selectors as usersSelectors } from "store/entities/users";
import { selectors as unitsSelectors } from "store/entities/units";
import { selectors as associatedItemsSelectors, buildCompositeItemsSelector } from "store/associatedItems";
import { IEstimateExtended } from './index';


export interface IEstimatesSelectors extends CustomSelectors<IEstimate, IEstimateExtended> {
  estimatesByCustomer: AppSelector<Dictionary<IEstimateExtended[]>>;
}

const getFullState = (state: AppState) => state.estimates;

const rawEntitiesByKey = createSelector(
  getFullState,
  fullState => fullState.byKey
);

const entitiesByKey = createSelector(
  rawEntitiesByKey,
  state => state.customers.byKey, // not importing customer.selectors.js to avoid circular dependency
  usersSelectors.entitiesByKey,
  (estimatesByKey, customersByKey, usersByKey) =>
    mapValues(estimatesByKey, (estimate: IEstimate) => ({
      ...estimate,
      customer: estimate.customerData || customersByKey[estimate.customerId], // use existing customerData, or get default data by customer ID
      user: usersByKey[estimate.userId]
    })) as IEstimateExtended[]
);

const defaultSelectors = createDefaultSelectors<IEstimate, IEstimateExtended>('estimate', {
  getFullState,
  entitiesByKey
});

export const estimatesByCustomer = createSelector(
  defaultSelectors.getEntities,
  estimates => groupBy<IEstimateExtended>(estimates, 'customerId')
);

export default {
  ...defaultSelectors,
  estimatesByCustomer
} as IEstimatesSelectors;

export const buildUpdatingEstimateItemsSelector = estimateType => {
  const getItems = buildCompositeItemsSelector(estimateType);

  return createSelector(
    getItems,
    unitsSelectors.entitiesByKey,
    defaultSelectors.getUpdatingEntity,
    (allItems, unitsByKey, estimate: any) => {
      const itemsById = groupBy(estimate && estimate.items || [], "id");

      const customItems = estimate ? estimate.items.filter(i => i.custom).map(i => ({
        ...i,
        unit: unitsByKey[i.unitId],
        overrides: i
      })) : [];

      return allItems.map(item => {
        const estimateItem = itemsById[item.id] && itemsById[item.id][0];
        const unit = unitsByKey[estimateItem && estimateItem.unitId || item.unitId];

        return { // TODO: filter by estimate.type
          ...item,
          unit,
          quantity: 0,
          ...estimateItem,
          overrides: estimateItem
        };
      }).concat(customItems);
    }
  );
};

export const buildUpdatingEstimateBasicItemsSelector = basicGroup => {
  return createSelector(
    associatedItemsSelectors.itemsByKeys,
    defaultSelectors.getUpdatingEntity,
    (originalItemsByKey, estimate) => {
      const estimateItems = estimate && estimate[basicGroup];

      return estimateItems && estimateItems.map(item => {
        let originalItem = originalItemsByKey[item.id];

        return { // TODO: filter by estimate.type
          ...originalItem,
          quantity: 0,
          ...item,
          overrides: item
        };
      }) || [];
    }
  );
};
