import React, { useState } from "react";
import cn from "classnames";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import { useStyles } from "./tableActionsStyle";


export default function TableActions(props) {
  const { actions } = props;
  const classes = useStyles();
  const [menuAnchors, setMenuAnchors] = useState({});

  if (!actions || actions.length === 0)
    return null;

  const onClickAction = action => event => {
    event.stopPropagation();

    if (action.callback)
      action.callback();
    else if (action.menu && action.menu.id)
      setMenuAnchors({
        ...menuAnchors,
        [action.menu.id]: event.currentTarget
      });
  };

  const onCloseAction = menuId => () => {
    setMenuAnchors({
      ...menuAnchors,
      [menuId]: null
    });
  };

  return (
    <div className={cn('table-row-actions', classes.actionsRight)}>
      {actions && actions.map(action => (
        <React.Fragment key={action.title}>
          <Tooltip key={action.title} title={action.title} placement="top">
            <IconButton onClick={onClickAction(action)} size="small" className={cn(classes.actionBtn, {
              [classes.primaryButton]: action.type === "primary",
              [classes.secondaryButton]: action.type === "secondary",
              [classes.warningButton]: action.type === "warning",
              [classes.dangerButton]: action.type === "danger"
            })}>
              <action.Icon/>
            </IconButton>
          </Tooltip>

          {!!action.menu && (
            <Menu
              id={action.menu.id}
              anchorEl={menuAnchors[action.menu.id]}
              open={!!menuAnchors[action.menu.id]}
              onClick={e => e.stopPropagation()}
              onClose={onCloseAction(action.menu.id)}
              disableScrollLock
              anchorOrigin={{ vertical: "center", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              {action.menu.items.map(item => (
                <MenuItem key={item.title} onClick={onClickAction(item)}>
                  {item.Icon && <item.Icon className={classes.menuItemIcon}/>}
                  {item.title}
                </MenuItem>
              ))}
            </Menu>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
