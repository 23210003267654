import React, { useCallback, useMemo, useState } from "react";
import cn from "classnames";
import compact from "lodash/compact";
import {
  useExpanded,
  useFilters,
  useGroupBy,
  usePagination,
  useSortBy,
  useTable
} from "react-table";

import { makeStyles } from "@material-ui/core/styles";
import { KeyboardArrowRight, UnfoldMoreOutlined, FilterList } from "@material-ui/icons";
import FormatLineSpacing from "@material-ui/icons/FormatLineSpacing";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";

import { compareCaseIndependent } from "common/utils/sorting.util";
import { getByPath } from "common/utils";

import TableToolbar from "./TableToolbar/TableToolbar";
import TableActions from "./TableActions/TableActions";
import TextFilter from "./Filters/TextFilter";
import styles from "./tableStyle";


const useStyles = makeStyles(styles);
const defaultColumn = {
  sortType: "textual",
  Filter: TextFilter
};
const sortTypes = {
  textual: (rowA, rowB, accessor) =>
    compareCaseIndependent(
      getByPath(rowA.original, accessor),
      getByPath(rowB.original, accessor)
    )
};

const defaultToolbarActions = [{
  title: "Filters",
  key: "filter",
  Icon: FilterList
}, {
  title: "Compact View",
  key: "dense",
  Icon: FormatLineSpacing
}];

function CustomTable(props) {
  const {
    columns,
    data,
    loading,
    actions,
    defaultSortBy,
    groupBy,
    renderRowSubComponent,
    recordAction,
    callbacks,
    noToolbar,
    noSorting,
    noPagination,
    toolbarLeft,
    showFooter,
    hideFiltersByDefault,
    defaultFilters
  } = props;
  const classes = useStyles() as any;
  const [actionsState, setActionsState] = useState({
    dense: false,
    filter: !hideFiltersByDefault && !noToolbar
  });

  const stateReducer = useCallback((state, action) => {
    if (action.type === "set-expanded-all")
      return {
        ...state,
        expanded: action.expanded
      };

    return state;
  }, []);

  const initialState = useMemo(() => ({
    sortBy: defaultSortBy ? defaultSortBy : [],
    groupBy: groupBy ? [groupBy] : [],
    pageSize: noPagination ? 1000 : 10,
    filters: defaultFilters || {}
  }), []);

  const tableInst = useTable({
    initialState,
    columns,
    data,
    defaultColumn,
    sortTypes,
    autoResetExpanded: false,
    autoResetFilters: false,
    autoResetGroupBy: false,
    autoResetSortBy: false,
    autoResetPage: false,
    paginateExpandedRows: false,
    disableSortBy: noSorting || false,
    disableMultiSort: true,
    // custom option that is not used by react-table, but is passed to the Cell rendering functions
    callbacks,
    stateReducer
  }, useFilters, useGroupBy, useExpanded, useSortBy, usePagination);

  const {
    getTableProps,
    headers,
    prepareRow,
    rows, // array of filtered rows, used to get rows count; use `page` to get actual rows
    // pagination props
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
    dispatch
  } = tableInst;

  const displayedRows = page || rows;

  const headerCellClass = cn(actionsState["filter"] && classes.headerCellWithFilters, classes.headerCell);
  const blankHeaderCell = cn(headerCellClass, classes.blankHeaderCell);
  const blankFilterCell = cn(headerCellClass, classes.blankHeaderCell, classes.filterCell);
  const toolbarActions = [...defaultToolbarActions];

  const onToolbarAction = useCallback(key => {
    if (key === "toggle-all")
      dispatch({
        type: "set-expanded-all",
        expanded: actionsState[key] ? [] : getAllExpandedState(rows)
      });

    setActionsState({
      ...actionsState,
      [key]: !actionsState[key]
    });
  }, [rows, actionsState, setActionsState]);

  const _onClickRow = (row, event) => {
    if (event.target.matches("input, button"))
      return;

    // row.canExpand indicates that this is an aggregated row after grouping, we handle clicks on it automatically
    if (!row.canExpand && recordAction && recordAction(row.original) === false)
      return;

    if (row.canExpand || renderRowSubComponent) {
      row.toggleExpanded();
    }
  };

  if (!!groupBy && !noToolbar)
    toolbarActions.unshift({
      title: "Toggle All",
      key: "toggle-all",
      Icon: UnfoldMoreOutlined
    });

  return (
    <>
      { !noToolbar && (
        <TableToolbar actions={toolbarActions} activeActions={actionsState} onAction={onToolbarAction}>
          {toolbarLeft}
        </TableToolbar>
      )}

      <Table className={classes.table} {...getTableProps()} size={actionsState["dense"] ? "small" : "default"}>
        <TableHead>
          <TableRow>
            { headers.map(column => column.show !== false && (
              column.isGrouped ? <TableCell className={blankHeaderCell} key={"header-" + column.id}/> : (
                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())} title={null} className={headerCellClass} style={column.style}>
                  <TableSortLabel
                    disabled={noSorting}
                    active={!!column.isSorted}
                    direction={column.isSortedDesc ? "desc" : "asc"}
                    classes={{ root: classes.sortableCell, icon: classes.sortIcon }}
                  >
                    {column.render("Header")}
                  </TableSortLabel>
                </TableCell>
              )
            ))}
          </TableRow>

          { actionsState["filter"] && (
            <TableRow>
              { headers.map(column => column.show !== false && (
                column.isGrouped ?  <TableCell className={blankFilterCell} key={"filter-" + column.id}/> : (
                  <TableCell {...column.getHeaderProps()} className={classes.filterCell}>
                    {column.canFilter ? column.render("Filter") : null}
                  </TableCell>
                )
              ))}
            </TableRow>
          )}
        </TableHead>

        <TableBody>
          { displayedRows.map(
            (row, i) => {
              let tableActions = compact(actions && row.original && actions(row.original));

              return prepareRow(row) || (
                <>
                <TableRow
                  {...row.getRowProps()}
                  hover
                  onClick={event => _onClickRow(row, event)}
                  className={cn(classes.dataRow, {
                    [classes.expandableRow]: row.canExpand,
                    [classes.expandedRow]: row.isExpanded,
                    [classes.clickableRow]: !!recordAction,
                    [classes.nestedRow]: !!renderRowSubComponent && !row.canExpand // styles for nested row that has sub-component
                  })}
                >
                  { !!groupBy && (
                    <TableCell className={classes.expanderCell}>
                      {row.canExpand && <KeyboardArrowRight className={classes.expander} />}
                    </TableCell>
                  )}

                  { row.cells.map((cell, i) => row.canExpand && !cell.isGrouped && row.depth === 0 || cell.isRepeatedValue ? null : (
                    <TableCell
                      {...cell.getCellProps()}
                      classes={{
                        root: cn({
                          [classes.dataCell]: !cell.isGrouped,
                          [classes.groupedCell]: cell.isGrouped
                        }),
                        sizeSmall: classes.dataCellSmall
                      }}
                      title={cell.column.title ? cell.column.title(cell) : undefined}
                      style={cell.column.style}
                      colSpan={cell.isGrouped ? row.cells.length - 1 : null}
                      onClick={cell.column.onClick ? (event) => {cell.column.onClick(event, cell, tableInst)} : null}
                    >
                      {cell.render("Cell")}
                      {cell.isGrouped ? ` (${row.subRows.length})` : null}
                      { // render table actions in the last table cell
                        tableActions && tableActions.length > 0 && !row.canExpand && i >= row.cells.length - 1 && (
                        <TableActions actions={tableActions}/>
                      )}
                    </TableCell>
                  ))}
                </TableRow>

                { !row.canExpand && row.isExpanded && renderRowSubComponent && (
                  <TableRow key={"row-expanded-" + i}>
                    <TableCell colSpan={row.cells.length + (actions ? 1 : 0)}>
                      {renderRowSubComponent({
                        row,
                        close: () => row.toggleExpanded()
                      })}
                    </TableCell>
                  </TableRow>
                )}
                </>
              )
            }
          )}
        </TableBody>

        { showFooter && (
          <TableFooter>
            <TableRow>
              { headers.map(column => column.show !== false && (
                column.isGrouped ?  <TableCell key={"footer-" + column.id} className={classes.footerCell} /> : (
                  <TableCell key={column.id} className={classes.footerCell}>
                    { column.Footer ? column.render("Footer") : null }
                  </TableCell>
                )
              ))}
            </TableRow>
          </TableFooter>
        )}
      </Table>

      { !noPagination && displayedRows.length > 0 && (
        <TablePagination
          classes={{
            root: classes.paginator,
            caption: classes.paginatorCaption
          }}
          component="div"
          labelRowsPerPage="Records per page:"
          rowsPerPageOptions={[10, 15, 25, 50, 100, 200]}
          count={rows.length}
          rowsPerPage={pageSize}
          page={pageIndex}
          onChangePage={(event, index) => gotoPage(index)}
          onChangeRowsPerPage={(event) => setPageSize(event.target.value)}
        />
      )}

      { displayedRows.length === 0 && (
        <div className={classes.emptyTableMessage}>
          No Records
        </div>
      )}

      { loading && (
        <div className={classes.launcher}>
          <CircularProgress size={60} color="secondary" />
        </div>
      )}
    </>
  );

  function getAllExpandedState(rows) {
    let expanded = [];
    // (typeof row.path[0] === "string") is used to match all grouping rows,
    // even those that are not visible now due to pagination;
    // such rows have path like ["categoryId:123"], while normal rows use numeric paths
    rows.forEach(row => (typeof row.path[0] === "string") && expanded.push(row.path[0]));

    return expanded;
  }
}

export default CustomTable;
