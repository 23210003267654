import { Action, ActionCreatorWithPayload, CaseReducerActions } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';
import { AxiosError } from 'axios';

import { statuses } from 'config/api.config';
import { pluralize, diff } from 'common/utils';
import { AppState, AppThunk } from 'store/store.d';
import { EntityService } from 'services/entity.service';
import { showNotification } from 'store/notifications';
import { IDefaultReducers } from './reducers';
import { IDefaultSelectors } from './selectors';
import { Subscription } from 'rxjs';


interface ICreateThunksOptions<T extends Entity = null> {
  showValidationErrors?: boolean;
  allowPartialUpdate?: boolean;
  // whether to track entities updates on the server to immediately apply them in the store
  liveEntityTracking?: boolean;
  selectors?: IDefaultSelectors<T>;
}

const defaultOptions: ICreateThunksOptions = {
  showValidationErrors: false,
  allowPartialUpdate: false,
  liveEntityTracking: false,
  selectors: {}
};

interface IHandleSuccessOptions {
  humanizedAction: string;
  payload?: any;
  successActionCreator: ActionCreatorWithPayload<any>;
  dispatch: ThunkDispatch<AppState, unknown, Action<string>>;
}

interface IHandleErrorOptions {
  humanizedAction: string;
  actionName?: string;
  entityName?: string;
  dispatch: ThunkDispatch<AppState, unknown, Action<string>>;
  failureActionCreator?: ActionCreatorWithPayload<any>; // todo: replace any with actual validation error type
}

export interface IEntitiesThunks<T extends Entity> {
  read: () => AppThunk<T[]|false>;
  readOne: (id: T['id']) => AppThunk<T|false>;
  create: (record: NotPersistedEntity<T>) => AppThunk<T|false>;
  update: (record: AtLeast<T, 'id'>) => AppThunk<T|false>;
  delete: (record: AtLeast<T, 'id'>) => AppThunk<boolean>;
}

export const createDefaultThunks = <T extends Entity>(
  entity: string,
  actions: CaseReducerActions<IDefaultReducers<T>>,
  service: EntityService<T>,
  options: ICreateThunksOptions<T> = defaultOptions
): IEntitiesThunks<T> => {
  const { showValidationErrors, allowPartialUpdate, liveEntityTracking, selectors } = options;
  let entityActionsSubscription: Subscription;

  return {
    read: () => async (dispatch) => {
      dispatch(actions.startReading());

      try {
        const payload = await service.read();
        dispatch(actions.finishReading(payload));

        if (liveEntityTracking)
          trackEntitiesLive(dispatch);

        return payload;
      } catch (error) {
        const authError = error.response && error.response.status === statuses.UNAUTHENTICATED;
        // in case of 401 error, send this flag to not set 'fetched' flag in reducer, so that items can be fetched again after login
        // TODO: this shouldn't happen; we should completely reset the store between the sessions
        dispatch(actions.cancelReading(authError));

        handleError(error, {
          actionName: actions.startReading.toString(),
          humanizedAction: 'read',
          entityName: pluralize(entity),
          dispatch
        });

        return false;
      }
    },

    readOne: (id: T['id']) => async (dispatch) => {
      try {
        const payload = await service.get(id);
        dispatch(actions.finishReadingOne(payload));
        return payload;
      } catch (error) {
        handleError(error, {
          actionName: 'readOne',
          humanizedAction: 'read',
          entityName: entity,
          dispatch
        });

        return false;
      }
    },

    create: record => async dispatch => {
      dispatch(actions.startCreating(record));

      try {
        const newItem = await service.create(record);

        handleSuccess({
          successActionCreator: actions.finishCreating,
          humanizedAction: 'created',
          dispatch,
          payload: newItem
        });

        return newItem;
      } catch (error) {
        handleError(error, {
          failureActionCreator: actions.cancelCreating,
          humanizedAction: 'create',
          dispatch
        });

        return false;
      }
    },

    update: record => async (dispatch, getState) => {
      dispatch(actions.startUpdating(record));

      try {
        const state = getState();
        const originalRecord: T|false = allowPartialUpdate && record?.id && selectors.getEntityById?.(state, record.id);
        const payload = !!originalRecord ? diff(record, originalRecord) : record;
        const item = await service.update(record.id, payload);

        handleSuccess({
          successActionCreator: actions.finishUpdating,
          humanizedAction: 'updated',
          dispatch,
          payload: item
        });

        return item;
      } catch (error) {
        handleError(error, {
          failureActionCreator: actions.cancelUpdating,
          humanizedAction: 'update',
          dispatch
        });

        return false;
      }
    },

    delete: record => async dispatch => {
      dispatch(actions.startDeleting());

      try {
        await service.delete(record);

        handleSuccess({
          successActionCreator: actions.finishDeleting,
          humanizedAction: 'deleted',
          dispatch,
          payload: record.id
        });

        return true;
      } catch (error) {
        handleError(error, {
          failureActionCreator: actions.cancelDeleting,
          humanizedAction: 'delete',
          dispatch
        });

        return false;
      }
    }
  };

  function handleSuccess(options: IHandleSuccessOptions) {
    const { successActionCreator, humanizedAction, dispatch, payload } = options;
    dispatch(successActionCreator(payload));

    dispatch(showNotification({
      message: `The ${entity} was successfully ${humanizedAction}`,
      variant: 'success',
      autoHideDuration: 3000,
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
      }
    }));
  }

  function handleError(error: AxiosError, options: IHandleErrorOptions) {
    const { actionName, failureActionCreator, humanizedAction, dispatch, entityName = entity } = options;
    const validationError = error.response?.status === statuses.INVALID;
    const authError = error.response?.status === statuses.UNAUTHENTICATED;
    let errorMsg = error.response?.data?.error
      || `Could not ${humanizedAction} the ${entityName}. ${error.message}`;

    if (failureActionCreator)
      dispatch(failureActionCreator(
        validationError && error.response.data
      ));

    if (showValidationErrors && validationError && error.response.data)
      errorMsg = Object.keys(error.response.data).reduce((msg, errorField) => {
        return `${msg} ${errorField} ${error.response.data[errorField][0]}`;
      }, 'Validation error: ');

    if ((!validationError || showValidationErrors) && !authError) {
      console.error(`Action Error: ${actionName || failureActionCreator?.toString()}`, error);
      dispatch(showNotification({
        message: errorMsg,
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        }
      }));
    }
  }

  // listen to the updates
  function trackEntitiesLive(dispatch) {
    if (entityActionsSubscription)
      entityActionsSubscription.unsubscribe();

    entityActionsSubscription = service.trackEntitiesLive().subscribe(entityAction => {
      console.log("Live entity sync", entityAction);
      dispatch(actions.syncLiveEntity({
        action: entityAction.action,
        entity: entityAction.payload
      }))
    });
  }
};
