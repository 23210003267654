import { createSelector, Selector } from "@reduxjs/toolkit";
import { pluralize } from "common/utils";
import { AppSelector, AppState } from 'store/store';
import { EntitiesByKey, EntitiesState } from './state';


export interface GenericSelectors {
  [key: string]: Function;
}

export interface IDefaultSelectors<T extends Entity, ExtendedT extends T = T> {
  getFullState?: AppSelector<EntitiesState<T>>;
  isLoading?: AppSelector<boolean>;
  entitiesByKey?: AppSelector<EntitiesByKey<ExtendedT>>;
  getAllKeys?: AppSelector<EntityId[]>;
  getEntities?: AppSelector<ExtendedT[]>;
  getEntityById?: (state: AppState, id: EntityId) => ExtendedT;
  getCreatingState?: AppSelector<EntitiesState<T>['creating']>;
  getUpdatingState?: AppSelector<EntitiesState<T>['updating']>;
  getCreatingEntity?: AppSelector<EntitiesState<T>['creating']['entity']>;
  getUpdatingEntity?: AppSelector<EntitiesState<T>['updating']['entity']>;
}

export function createDefaultSelectors<T extends Entity, ExtendedT extends T = T>(
  entityName: string,
  customSelectors: IDefaultSelectors<T, ExtendedT> = {}
) {
  const entities = pluralize(entityName);
  const getFullState = customSelectors['getFullState'] || ((state => state[entities]) as AppSelector<EntitiesState<T>>);

  const isLoading = customSelectors['isLoading'] || createSelector(
    getFullState,
    fullState => fullState['loading']
  );

  const entitiesByKey = customSelectors['entitiesByKey'] || createSelector(
    getFullState,
      fullState => fullState['byKey']
  );

  const getAllKeys = customSelectors['getAllKeys'] || createSelector(
    getFullState,
    fullState => fullState['keys']
  );

  const getEntities = customSelectors['getEntities'] || createSelector(
    getAllKeys,
    entitiesByKey,
    (keys, byKey) =>
      keys.map(key => byKey[key]) as ExtendedT[]
  );

  const getEntityById = customSelectors['getEntityById'] ||
    ((state: AppState, id: EntityId) => entitiesByKey(state)[id] as ExtendedT);

  const getCreatingState = customSelectors['getCreatingState'] || createSelector(
    getFullState,
    fullState => fullState['creating']
  );

  const getCreatingEntity = customSelectors['getCreatingEntity'] || createSelector(
    getCreatingState,
    creating => creating['entity']
  );

  const getUpdatingState = customSelectors['getUpdatingState'] || createSelector(
    getFullState,
    fullState => fullState['updating']
  );

  const getUpdatingEntity = customSelectors['getUpdatingEntity'] || createSelector(
    getUpdatingState,
    updating => updating['entity']
  );

  return {
    getFullState,
    isLoading,
    entitiesByKey,
    getAllKeys,
    getEntities,
    getCreatingState,
    getUpdatingState,
    getCreatingEntity,
    getUpdatingEntity,
    getEntityById
  };
}
