export function formatCurrency(x, currency = "$") {
  if (typeof x === "number") {
    // e.g. 12345 => $12,345.67, 4123.456 => $4,123.46, -3456 => -$3,456
    const sign = x < 0 ? "-" : "";
    return sign + currency + Math.abs(x).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  } else
    return x;
}

export function formatPercentage(x, fractionDigits = 1, suffix = "%") {
  if (typeof x === "number") {
    // e.g. 0.05 => 5%, -0.1234321 => -12.3%
    return (x * 100).toFixed(fractionDigits) + suffix;
  } else
    return x;
}

export function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1);
}
