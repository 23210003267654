import { successColor } from "assets/jss/common";
import { makeStyles } from '@material-ui/core/styles';


export default makeStyles(theme => ({
  advancedBtn: {
    paddingLeft: theme.spacing(1),
    marginRight: theme.spacing(3)
  },
  clipboardLink: {
    display: "inline-block",
    verticalAlign: "bottom"
  },
  addItemBtn: {
    margin: theme.spacing(0, 3),
    color: theme.palette.grey[500],
    justifySelf: "flex-end",
    textTransform: "none",
    "&:hover": {
      color: successColor[0],
      background: "transparent"
    }
  }
}));
