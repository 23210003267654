import { Subject } from 'rxjs';
import { Channel } from 'actioncable';
import CableService from 'services/cable.service';
import { IInAppNotification } from 'types/entities';


interface INotificationMessage {
  type: 'new_notification'; // we support only one message type as for now
  notification: IInAppNotification;
}

class NotificationsChannel {
  public notification$ = new Subject<IInAppNotification>();
  private channel: Channel;

  public listen() {
    this.channel = CableService.subscribeToChannel<INotificationMessage>('NotificationsChannel', {
      received: (data) => {
        this.notification$.next(data.notification);
      }
    });
  }

  public stopListening() {
    this.channel?.unsubscribe();
  }
}

export default new NotificationsChannel();
