export default theme => ({
  inputFilter: {
    "& input": {
      padding: theme.spacing(1),
      fontWeight: "normal"
    },
    "& label": {
      fontSize: "small"
    }
  }
});
