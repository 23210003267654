import React, { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { Close, Info } from '@material-ui/icons';
import ButtonBase from '@material-ui/core/ButtonBase'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import LinearProgress from '@material-ui/core/LinearProgress';

import { IInAppNotification } from 'types/entities';
import { useStyles } from './NewNotificationStyle';


interface INewNotificationProps {
  notification: IInAppNotification;
  markAsRead: (notification?: IInAppNotification) => void;
  close: () => void;
  innerRef?: any;
}

const FULL = 100;
const DURATION = 5000;
const TICK = 100;

export function NewNotification(props: INewNotificationProps) {
  const [freeze, setFreeze] = useState(false);
  const [progress, setProgress] = useState(FULL);
  const [read, setRead] = useState(false);
  const classes = useStyles();
  const { notification, innerRef, markAsRead, close } = props;

  const onClick = useCallback(() => {
    if (!read) {
      setRead(true); // update local state because this component is separated from the store and won't receive any update
      markAsRead();
    }
  }, [read, setRead]);

  const onMouseEnter = useCallback(() => {
    setFreeze(true);
  }, [setFreeze]);

  const onMouseLeave = useCallback(() => {
    setFreeze(false);
  }, [setFreeze]);

  // creating new interval and stopping the old one each time `freeze` variable changes
  useEffect(() => {
    const interval = !freeze && setInterval(() => {
      setProgress(p => Math.max(0, p - (FULL * TICK / DURATION)))
    }, TICK);

    return () => clearInterval(interval);
  }, [freeze]);

  // freeze the animation before closing (to avoid firing `close()` multiple times)
  const closeCard = useCallback(() => {
    setFreeze(true);
    close();
  }, [close, setFreeze]);

  useEffect(() => {
    if (progress <= 0 && !freeze)
      closeCard();
  }, [progress, freeze, closeCard]);

  return (
    <ButtonBase
      ref={innerRef}
      classes={{ root: classes.rippleContainer}}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Card classes={{ root: classes.card }}>
        <CardContent classes={{ root: classes.content }}>
          <div className={classes.iconBox}>
            <Badge variant="dot" color="error" invisible={read || notification.read} overlap="circle">
              <Info fontSize="large" />
            </Badge>
          </div>
          <div className={classes.contentBox}>
            <Close onClick={closeCard} className={classes.closeBtn}/>
            <Typography className={classes.title} variant="subtitle2">
              { notification.content.title }
            </Typography>
            <Typography className={classes.body}>
              { notification.content.body }
            </Typography>
            <Typography className={classes.date} variant="caption">
              { moment(notification.createdAt).fromNow() }
            </Typography>
          </div>
        </CardContent>
        <LinearProgress variant="determinate" value={progress} />
      </Card>
    </ButtonBase>
  );
}

export default React.forwardRef<any, INewNotificationProps>((props, ref) =>
  <NewNotification innerRef={ref} {...props} />
);
