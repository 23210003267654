import { makeStyles } from '@material-ui/core/styles';
import { primaryCardHeader } from "assets/jss/common";


export default makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "stretch",
    alignItems: "flex-start",
    borderRadius: "0 0 4px 4px",
    padding: theme.spacing(0, 1),
    background: theme.palette.grey["100"],

    "&$readonly $itemsGroup": {
      flexGrow: 1,
      maxWidth: 700
    }
  },
  readonly: {},
  title: {
    position: "relative", // to enable shadow
    margin: 0,
    padding: theme.spacing(1),
    borderRadius: "4px 4px 0 0",
    textAlign: "center",
    fontSize: "1.4em",
    color: "white",
    ...primaryCardHeader,
    boxShadow: "0 2px 2px rgba(0, 0, 0, .4)",
    "& > strong": {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  closeBtn: {
    float: "right",
    color: "rgba(255, 255, 255, .9)"
  },
  itemsGroup: {
    width: "33%",
    flexGrow: 0,
    flexShrink: 0,
    [theme.breakpoints.down("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  actions: {
    width: "50%",
    flexGrow: 1,
    alignSelf: "flex-end",
    paddingBottom: theme.spacing(.5),
    textAlign: "right"
  },
  launcher: {
    verticalAlign: "bottom",
    marginBottom: theme.spacing(2)
  },
  placeholder: {
    width: "100%",
    padding: "4% 0",
    textAlign: "center",
    fontSize: "large",
    fontWeight: 400,
    color: theme.palette.text.hint
  }
}));
