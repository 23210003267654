import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotification } from 'types/ui';


export interface NotificationsState {
  nextNotification: number;
  notifications: INotification[];
  dismissedProgrammatically: string[];
}

export const { actions, reducer } = createSlice({
  name: 'notifications',
  initialState: {
    nextNotification: 0,
    notifications: [],
    dismissedProgrammatically: []
  } as NotificationsState,
  reducers: {
    showNotification(state, action: PayloadAction<INotification>) {
      const key = state.nextNotification + 1; // increment notification key
      state.nextNotification = key;
      state.notifications.unshift({
        ...action.payload,
        key
      });
    },
    closeNotification(state, action: PayloadAction<string>) {
      state.dismissedProgrammatically = [
        ...state.dismissedProgrammatically,
        action.payload
      ];
    },
    forgetAll(state) {
      state.notifications = [];
      state.dismissedProgrammatically = [];
    }
  }
});

export const { showNotification, forgetAll } = actions;
