import { makeStyles } from '@material-ui/core/styles';


export default makeStyles(theme => ({
  calculatorWrapper: {
    flexGrow: 1,
    display: "flex",
    alignItems: "stretch",
    marginLeft: theme.spacing(3)
  },
  textField: {
    width: 110,
    marginRight: theme.spacing(2)
  },
  input: {
    padding: theme.spacing(1)
  },
  answersWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "stretch"
  },
  answerBox: {
    flex: "0 1 40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: theme.spacing(0, 1)
  },
  percentage: {
    fontSize: "x-small",
    lineHeight: 1,
    color: theme.palette.grey[700]
  },
  answer: {
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1
  }
}));
