import { useCallback } from "react";

import { useReadAction } from "./useReadAction";
import { useCreateAction } from "./useCreateAction";
import { useUpdateAction } from "./useUpdateAction";
import { useDeleteAction } from "./useDeleteAction";


export function useCRUDActions(actionCreators, selectors, options = {}) {
  const [state, read] = useReadAction(actionCreators, selectors, options);
  const [creatingState, newEntityActions] = useCreateAction(actionCreators, selectors, options);
  const [updatingState, editedEntityActions] = useUpdateAction(actionCreators, selectors);
  const [deletingEntity, deletionActions] = useDeleteAction(actionCreators, options);

  const creatingNew = !!creatingState.entity;
  const currentEntityState = creatingNew ? creatingState : updatingState;
  const currentEntity = currentEntityState.entity;

  const reset = useCallback(() => {
    newEntityActions.reset();
    editedEntityActions.reset();
    deletionActions.reset();
  }, [newEntityActions, editedEntityActions]);

  return [{
    ...state,
    creatingNew,
    currentEntity,
    currentEntityLoading: currentEntityState.loading,
    currentEntityError: currentEntityState.error,
    deletingEntity
  }, {
    read,
    reset,
    delete: deletingEntity ? deletionActions.commit : deletionActions.delete,
    create: newEntityActions.create,
    update: editedEntityActions.update,
    // useCRUDActions hook simplifies creating/updating entities by providing single interface to the both actions;
    // it return, it sets its limitation: creating and updating two entities in parallel is not allowed
    patch: currentEntity ? (creatingNew ? newEntityActions.patch : editedEntityActions.patch) : null,
    commit: currentEntity ? (creatingNew ? newEntityActions.commit : editedEntityActions.commit) : null
  }];
}
