import { createStyles, Theme } from '@material-ui/core/styles';

import { containerFluid } from 'styles';


export const styles = (theme: Theme) => createStyles({
  wrapper: {
    position: "relative",
    display: 'flex',
    height: '100vh'
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    overflow: "auto",
    position: "relative",
    maxHeight: "100%",
    overflowScrolling: "touch"
  },
  mainWithFixedSidebar: {
    marginLeft: theme.appDrawer.minimizedWidth
  },
  content: {
    ...containerFluid,
    width: '100%',
    flexGrow: 1
  }
});
