import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { selectors as unitsSelectors } from "store/entities/units";
import { useAssociatedItems } from "./useAssociatedItems";


export function useEstimateItems({ estimate, group, patch }) {
  group = group || "items";

  const units = useSelector(unitsSelectors.getEntities);
  const { loading, loaded } = useAssociatedItems();
  const [editingItem, setEditingItem] = useState(null);

  const startCreatingItem = useCallback(() => {
    setEditingItem({
      name: "",
      price: "",
      unitId: units[0] && units[0].id || null,
      unit: units[0] || null,
      quantity: 1
    });
  }, [units, setEditingItem]);

  // save new/edited item to the estimate and stop editing (it does not persist estimate on the server though)
  const saveEditingItem = useCallback(() => {
    let items = estimate[group];

    if (editingItem.id) // update existing item in array
      items = items.map(i => {
        if (i.id === editingItem.id)
          return {
            ...i,
            ...editingItem
          };
        else
          return i;
      });
    else // add new custom item to the array
      items = [...items, {
        ...editingItem,
        id: `custom_${Math.round(Math.random() * 1e6)}`,
        custom: true
      }];

    patch({
      [group]: items
    });

    setEditingItem(null);
  }, [estimate[group], patch, editingItem, setEditingItem]);

  // update new/edited item in the local state
  const patchEditingItem = useCallback(patch => setEditingItem({
    ...editingItem,
    ...patch,
    // update selected unit object to display proper unit name in the table
    unit: patch.unitId ? units.find(u => u.id === patch.unitId) : editingItem.unit,
    overrides: { // everything that comes in the patch should also go to `overrides` object
      ...editingItem.overrides,
      ...patch
    }
  }), [editingItem, units]);

  const restoreDefaultsForItem = useCallback(item => {
    patch({
      [group]: estimate[group].map(i => {
        if (i.id === item.id)
          return {
            id: i.id,
            quantity: i.originalQuantity || i.quantity
          };
        else
          return i;
      })
    })
  }, [estimate[group], patch]);

  const deleteItem = useCallback(item => {
    patch({
      [group]: estimate[group].filter(i => i.id !== item.id)
    });
  }, [estimate[group], patch]);

  const updateQuantity = useCallback((item, quantity) => {
    patch({
      [group]: estimate[group].map(i => {
        if (i.id === item.id) {
          let newItem = {
            ...i,
            quantity,
          };

          // set originalQuantity value for sub-items (items belonging to a basic group) when updating their quantity;
          // it is used to display warnings near quantity input in ItemsWrapper component;
          // on backend this value is reset when quantity is re-calculated (see original_quantity flag on server side)
          if (!i.custom && group !== 'items' && !newItem.originalQuantity)
            newItem.originalQuantity = i.quantity;

          return newItem;
        } else
          return i;
      })
    });
  }, [estimate[group], patch]);

  const stopEditing = useCallback(() => setEditingItem(null), [setEditingItem]);

  return [{
    loading,
    loaded,
    editingItem,
    units
  }, {
    createItem: startCreatingItem,
    editItem: setEditingItem,
    patchItem: patchEditingItem,
    saveItem: saveEditingItem,
    restoreDefaults: restoreDefaultsForItem,
    deleteItem,
    stopEditing,
    updateQuantity
  }];
}
