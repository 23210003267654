import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions as estimatesActions, selectors as estimatesSelectors } from "store/entities/estimates";
import { actions as wizardActions, selectors as wizardSelectors } from "store/estimateWizard";


interface ILocalState {
  shouldExit?: boolean;
  redirectToId?: number|null;
}

export function useEstimateWizard(estimateId, defaults) {
  const [localState, setLocalState] = useState<ILocalState>({
    shouldExit: false,
    redirectToId: null
  });
  const dispatch = useDispatch();
  const wizard = useSelector(wizardSelectors.fullState);
  const hasChanges = useSelector(wizardSelectors.hasChanges);
  const { loading, fetched, creating, updating } = useSelector(estimatesSelectors.getFullState);
  const estimatesByKey = useSelector(estimatesSelectors.entitiesByKey);

  estimateId = estimateId || localState.redirectToId;

  const creatingNew = !estimateId;
  const entityState = creatingNew ? creating : updating;
  const estimate = entityState.entity;

  /** ACTIONS **/

  const reset = useCallback(() => {
    dispatch(wizardActions.reset());

    if (updating.entity)
      dispatch(estimatesActions.updateLocal(null));

    if (creating.entity)
      dispatch(estimatesActions.createLocal(null));
  }, [dispatch, updating.entity, creating.entity]);

  const goToStep = useCallback(
    stepId => dispatch(wizardActions.goToStep(stepId)),
    [dispatch]
  );

  const patch = useCallback(patch => {
    if (creatingNew)
      dispatch(estimatesActions.createLocal({
        ...estimate,
        ...patch
      }));
    else
      dispatch(estimatesActions.updateLocal({
        ...estimate,
        ...patch
      }));

    dispatch(wizardActions.updateStep(wizard.currentStep));
  }, [dispatch, estimate, wizard.currentStep, creatingNew]);

  const close = useCallback(() => {
    setLocalState({
      shouldExit: true
    });

    reset();
  }, [setLocalState, reset]);

  const cancel = useCallback((resetWizard = false) => {
    if (updating.entity)
      dispatch(estimatesActions.updateLocal(null));

    if (creating.entity)
      dispatch(estimatesActions.createLocal(null));

    if (creatingNew && resetWizard !== false) // ugly workaround, but it works to prevent  wizard from resetting its current step
      dispatch(wizardActions.reset());
  }, [updating.entity, creating.entity, creatingNew, dispatch]);

  const commit = useCallback(() => {
    if (creatingNew)
      createEstimate(estimate);
    else
      updateEstimate(estimate);
  }, [dispatch, estimate, creatingNew]);

  // TODO: move this whole logic to the store
  const updateEstimate = useCallback(async e => {
      const result: any = await dispatch(estimatesActions.update(e));

      if (result !== false)
        cancel(); // reset updating entity to null, so that the side effect is invoked and actual entity is set for updating
    },[cancel, dispatch]
  );

  const createEstimate = useCallback(async e => {
      const result: any = await dispatch(estimatesActions.create(e));

      if (result !== false) {
        setLocalState({
          shouldExit: false,
          redirectToId: result.id
        });

        cancel(false);
      }
    },[setLocalState, dispatch]
  );

  /** EFFECTS **/

  useEffect(() => {
    if (!loading && !fetched)
      dispatch(estimatesActions.read());

    reset();
  }, []);

  useEffect(() => {
    if (!creatingNew && fetched && estimateId) {
      const est = estimatesByKey[estimateId];

      // load detailed data (contract and invoices URLs) if not loaded yet
      if (!('contractUrl' in est))
        dispatch(estimatesActions.readOne(estimateId));
    }
  }, [creatingNew, fetched, estimatesByKey, estimateId])

  // creation logic
  useEffect(() => {
    if (creatingNew && !estimate && !localState.shouldExit && !localState.redirectToId) {
      dispatch(estimatesActions.createLocal(defaults));
      dispatch(wizardActions.reset(wizard.currentStep));

      if (defaults.customerId)
        dispatch(wizardActions.updateStep(wizard.currentStep));
    }
  }, [creatingNew, estimate]);

  // edition logic
  useEffect(() => {
    if (!creatingNew && fetched && !estimate && !localState.shouldExit) {
      const est = estimatesByKey[estimateId];

      if (!est) {
        console.error(`Could not find an estimate with id=${estimateId}. Redirecting to the main page`);
        setLocalState({
          shouldExit: true
        });
      } else {
        dispatch(estimatesActions.updateLocal(est));
        dispatch(wizardActions.reset(wizard.currentStep));
      }
    }
  }, [creatingNew, fetched, estimate, estimateId]);

  /** EXPOSED STATE & ACTIONS **/

  return [
    // exposed state
    {
      ...localState,
      loading: loading || !estimate || entityState.loading,
      creatingNew,
      wizard: {
        ...wizard,
        hasChanges: hasChanges || !!(creatingNew && estimate && estimate.customerId)
      },
      estimate: estimate || defaults
    },
    // exposed actions
    {
      goToStep,
      patch,
      close,
      cancel,
      commit
    }
  ] as [any, any];
}
