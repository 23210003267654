import { createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

// background roofs
import bg1 from 'assets/img/roofs/roof-4.jpg';
import bg2 from 'assets/img/roofs/roof-3.jpg';
import bg3 from 'assets/img/roofs/roof-2.jpeg';
import bg4 from 'assets/img/roofs/roof-1.jpg';
import bg5 from 'assets/img/roofs/roof-6.jpg';
import bg6 from 'assets/img/roofs/roof-7.jpg';
import bg7 from 'assets/img/roofs/roof-8-steadmans.jpg';
import bg8 from 'assets/img/roofs/roof-9-tesla-solar.jpeg';


const backgrounds = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8];

export const styles = () => createStyles({
  container: {
    position: 'relative',
    minHeight: '100vh',
    display: 'flex',
    margin: '0',
    border: '0',
    color: 'white',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    height: '100%',
    '& footer': {
      position: 'absolute',
      bottom: 0
    },
    '&:before': {
      backgroundColor: fade('#000', 0.5)
    },
    '&:before,&:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      zIndex: 2
    }
  }
});

export const randomBackground = () => {
  const i = Math.floor(Math.random() * backgrounds.length);
  const bgUrl = backgrounds[i];

  return {
    backgroundImage: `url(${bgUrl})`
  };
};
