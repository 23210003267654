import React, { FormEvent } from "react";
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Email, LockOutlined } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';

// core components
import Card from "common/components/Card/Card";
import CardBody from "common/components/Card/CardBody";
import CardHeader from "common/components/Card/CardHeader";
import CardFooter from "common/components/Card/CardFooter";

import { styles } from "./loginPageStyle";


export interface ILoginPageProps extends WithStyles<typeof styles> {
  isLoading: boolean;
  logIn: (email: string, password: string) => void;
}

interface ILoginPageState {
  email: string;
  password: string;
}

class LoginPage extends React.Component<ILoginPageProps, ILoginPageState> {
  state: ILoginPageState = {
    email: '',
    password: ''
  };

  render() {
    const { isLoading, classes } = this.props;
    const { email, password } = this.state;

    return (
      <div className={classes.container}>
        <Slide in direction='down' timeout={500}>
          <form onSubmit={this.onSubmit}>
            <Card className={classes.card}>
              <CardHeader className={classes.cardHeader} color="primary">
                <h4 className={classes.cardTitle}>
                  Welcome!
                </h4>
              </CardHeader>

              <CardBody>
                <TextField
                  value={email}
                  label="Email"
                  id="login-email"
                  name="login-email"
                  fullWidth
                  required
                  classes={{ root: classes.textField }}
                  onChange={event => this.setState({
                    email: event.target.value
                  })}
                  InputLabelProps={{
                    classes: { root: classes.textFieldLabel },
                    required: false
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon}/>
                      </InputAdornment>
                    ),
                    classes: { root: classes.textFieldInput }
                  }}
                />
                <TextField
                  value={password}
                  label="Password"
                  id="login-password"
                  name="login-password"
                  type="password"
                  fullWidth
                  required
                  classes={{ root: classes.textField }}
                  onChange={event => this.setState({
                    password: event.target.value
                  })}
                  InputLabelProps={{
                    classes: { root: classes.textFieldLabel },
                    required: false
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockOutlined className={classes.inputAdornmentIcon}/>
                      </InputAdornment>
                    ),
                    classes: { root: classes.textFieldInput }
                  }}
                />
              </CardBody>

              <CardFooter>
                <Button
                  fullWidth
                  color="primary"
                  type="submit"
                  size="large"
                  disabled={isLoading}
                  className={classes.loginButton}
                >
                  Log In
                </Button>
              </CardFooter>
            </Card>
          </form>
        </Slide>
      </div>
    );
  }

  private onSubmit = (event: FormEvent) => {
    const { email, password } = this.state;

    this.props.logIn(email, password);
    event.preventDefault();
  }
}

export default withStyles(styles)(LoginPage);
