import React, { PropsWithChildren, useState } from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";

import { WithStyles, withStyles } from '@material-ui/core/styles';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";

import { AppIcon } from 'config/icons.config';
import { pageTitles } from 'config/routing.config';
import { styles } from "./sidebarItemStyle";


interface ISidebarItemProps extends WithStyles<typeof styles> {
  mini: boolean;
  route: string;
  icon: AppIcon;
}

const SidebarItem: React.FunctionComponent<PropsWithChildren<ISidebarItemProps>> = (props) => {
    const { classes, children, mini, route, icon } = props;
    const [expanded, setExpanded] = useState(false);
    const collapsible = !!children; // when children are present, it is a collapsible item
    const itemLinkClasses = cn(classes.itemLink, {
      [classes.collapseItemLinkExpanded]: collapsible && expanded
    });
    const itemTextClasses = cn(classes.itemText, mini && classes.itemTextMini);

    return (
      <ListItem className={classes.item}>
        <NavLink
          to={route}
          className={itemLinkClasses}
          activeClassName={cn(!collapsible && classes.itemLinkActive)}
          onClick={(e) => {
            if (collapsible) {
              e.preventDefault();
              setExpanded(!expanded);
            }
          }}
        >
          <Icon className={classes.itemIcon}>{icon}</Icon>
          <ListItemText
            disableTypography
            primary={pageTitles[route]}
            className={itemTextClasses}
            secondary={collapsible && (
              <b className={cn(classes.caret, expanded && classes.caretActive)} />
            )}
          />
        </NavLink>

        {collapsible && (
          <Collapse in={expanded}>
            {children}
          </Collapse>
        )}
      </ListItem>
    );
};

export default withStyles(styles)(SidebarItem);
