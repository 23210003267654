import { createStyles, fade, Theme } from '@material-ui/core/styles';
import { niceBoxShadow } from 'styles';


export const styles = (theme: Theme) => createStyles({
  item: {
    display: "block",
    position: "relative",
    color: "inherit",
    margin: 0,
    padding: 0
  },
  itemLink: {
    display: "block",
    position: "relative",
    width: 'auto',
    padding: 10,
    margin: theme.spacing(1, 2),
    borderRadius: 3,
    backgroundColor: "transparent",
    transition: "all 200ms ease-in-out",
    transitionProperty: 'background-color, box-shadow',
    "&:hover": {
      outline: "none",
      backgroundColor: fade('#c8c8c8', 0.2),
      boxShadow: "none"
    },
    "&,&:hover,&:focus": {
      color: "inherit"
    }
  },
  itemLinkActive: {
    "&,&:hover,&:focus": {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      boxShadow: niceBoxShadow(theme.palette.primary.main)
    }
  },
  collapseItemLinkExpanded: {
    outline: "none",
    backgroundColor: fade('#c8c8c8', 0.2),
    boxShadow: "none"
  },
  itemIcon: {
    position: "relative",
    float: "left",
    top: 3,
    width: 30,
    height: 24,
    marginRight: theme.spacing(2),
    textAlign: "center",
    verticalAlign: "middle",
    color: "inherit",
    opacity: 0.8
  },
  itemText: {
    color: "inherit",
    margin: "0",
    lineHeight: "30px",
    fontSize: "14px",
    transform: "translate3d(0px, 0, 0)",
    opacity: "1",
    transition: "transform 300ms ease 0s, opacity 300ms ease 0s",
    position: "relative",
    display: "block",
    height: "auto",
    whiteSpace: "nowrap"
  },
  itemTextMini: {
    transform: "translate3d(-25px, 0, 0)",
    opacity: "0"
  },
  collapseItemText: {
    color: "inherit",
    margin: "0",
    position: "relative",
    transform: "translateX(0px)",
    opacity: "1",
    whiteSpace: "nowrap",
    display: "block",
    transition: "transform 300ms ease 0s, opacity 300ms ease 0s",
    fontSize: "14px"
  },
  caret: {
    marginTop: "13px",
    position: "absolute",
    right: "18px",
    transition: "all 150ms ease-in",
    display: "inline-block",
    width: "0",
    height: "0",
    marginLeft: "2px",
    verticalAlign: "middle",
    borderTop: "4px solid",
    borderRight: "4px solid transparent",
    borderLeft: "4px solid transparent"
  },
  caretActive: {
    transform: "rotate(180deg)"
  }
});
