import { ICustomer, IEstimate, IUser } from 'types/entities';
import EstimatesService from 'services/entities/estimates.service';
import { createEntitySlice, CustomReducers, EntitiesState } from 'store/helpers/entities';
import estimatesSelectors, { IEstimatesSelectors } from './selectors';


export { buildUpdatingEstimateItemsSelector, buildUpdatingEstimateBasicItemsSelector } from './selectors';

export interface IEstimateExtended extends IEstimate {
  customer: ICustomer;
  user: IUser;
}

export const {
  actions,
  reducer,
  selectors
} = createEntitySlice<IEstimate, IEstimatesSelectors, CustomReducers<IEstimate>, IEstimateExtended>({
  name: 'estimate',
  service: EstimatesService,
  showValidationErrors: true,
  allowPartialUpdate: true,
  skipDefaultSelectors: true,
  liveEntityTracking: true,
  selectors: estimatesSelectors
});

export type EstimatesState = EntitiesState<IEstimate>;
