import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Extension } from "@material-ui/icons";

import { BasicGroup } from "config/constants.config";
import { buildBasicItemsSelector } from "store/associatedItems";
import { selectors as categoriesSelectors } from "store/entities/categories";
import SubItemsTable from "common/views/SubItemsTable/SubItemsTable";


export default function MaterialsPage() {
  const materialsSelector = useMemo(
    () => buildBasicItemsSelector(BasicGroup.MATERIALS),
    []
  );
  const categories = useSelector(categoriesSelectors.materialCategories);

  return (
    <SubItemsTable
      entity="material"
      title="Materials"
      Icon={Extension}
      getEntities={materialsSelector}
      categories={categories}
    />
  );
}
