import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createLogger } from "redux-logger";
import { persistStore, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";

import reducer from "./reducer";


const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      // to make default serializable-state-invariant-middleware work with redux-persist
      // (https://github.com/rt2zz/redux-persist/issues/988#issuecomment-552242978)
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }),
  // TODO: use logger only for DEV environment
  createLogger({
    colors: true,
    level: {
      prevState: false,
      nextState: false
    }
  })
];

export const store = configureStore({
  reducer,
  middleware
});

export const persistor = persistStore(store);

// preserve redux state with hot module reloading (HMR)
if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept(() => {
    const newRootReducer = require('./reducer').default;
    store.replaceReducer(newRootReducer);
  });
}
