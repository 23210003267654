import { PayloadAction } from '@reduxjs/toolkit';
import { IInAppNotification } from 'types/entities';
import InAppNotificationsService from 'services/entities/in-app-notifications.service';
import { AppThunk } from 'store/store.d';
import { createEntitySlice, CustomReducers, CustomSelectors, EntitiesState } from 'store/helpers/entities';
import { showNotification } from 'store/notifications';
import { addNormalizedItem } from '../../helpers/entities/normalizer';


interface IMarkAsReadPayload {
  notifications: IInAppNotification[];
  read?: boolean;
}

interface IInAppNotificationsReducers extends CustomReducers<IInAppNotification> {
  markAsRead: (state: EntitiesState<IInAppNotification>, action: PayloadAction<IMarkAsReadPayload>) => EntitiesState<IInAppNotification>;
  addNotification: (state: EntitiesState<IInAppNotification>, action: PayloadAction<IInAppNotification>) => EntitiesState<IInAppNotification>;
}

const {
  actions: defaultActions,
  reducer,
  selectors
} = createEntitySlice<IInAppNotification, CustomSelectors<IInAppNotification>, IInAppNotificationsReducers>({
  name: 'notification',
  service: InAppNotificationsService,
  reducers: {
    markAsRead: (state, action: PayloadAction<IMarkAsReadPayload>) => {
      action.payload.notifications.forEach(n => {
        const notification = state.byKey[n.id];

        if (notification)
          state.byKey[n.id] = {
            ...notification,
            read: 'read' in action.payload ? action.payload.read : true
          };
      });

      return state;
    },
    addNotification: (state, action) => addNormalizedItem(state, action.payload)
  },
  selectors: {
    getFullState: state => state.inAppNotifications
  }
});

const actions = {
  ...defaultActions,
  markNotificationsAsRead: (notifications: IInAppNotification[]): AppThunk => async dispatch => {
    notifications = notifications.filter(n => !n.read);

    if (notifications.length === 0)
      return;

    // optimistically update notifications in store without waiting for server response
    dispatch(defaultActions.markAsRead({
      notifications,
      read: true
    }));

    try {
      await InAppNotificationsService.markAsRead(notifications);
    } catch(error) {
      const errorMsg = error.response?.data?.error || error.message;

      console.error(`Action Error: markNotificationsAsRead`, error);

      // revert the update made above in case of error
      dispatch(defaultActions.markAsRead({
        notifications: notifications,
        read: false
      }));

      dispatch(showNotification({
        message: `Could not mark notification(s) as read. ${errorMsg}`,
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      }));

      throw error;
    }
  }
};

export type InAppNotificationsState = EntitiesState<IInAppNotification>;

export {
  actions,
  reducer,
  selectors
};
