import { Subscription } from 'rxjs';
import NotificationsChannel from './channels/notifications.channel';
import { filter } from 'rxjs/operators';
import { IInAppNotification } from '../types/entities';


/**
 * This service is responsible for showing app notifications in the operating system
 * using browser's Notification API (if available).
 * The notifications are shown only when the application tab is not active in the browser
 */
class SystemNotificationsService {
  private permissionGranted = false;
  private subscription: Subscription;

  public async initialize() {
    if (this.isNotificationAPISupported()) {
      const granted = await this.requestPermission();

      if (granted)
        this.listenToNotifications();
    }
  }

  public teardown() {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  public isNotificationAPISupported() {
    return 'Notification' in window;
  }

  private async requestPermission() {
    const permission = await Notification.requestPermission();
    this.permissionGranted = permission === 'granted';

    return this.permissionGranted;
  }

  private listenToNotifications() {
    this.subscription = NotificationsChannel.notification$.pipe(
      filter(() => this.permissionGranted && this.isTabInactive())
    ).subscribe(notification => {
      this.showSystemNotification(notification);
    });
  }

  private showSystemNotification(notification: IInAppNotification) {
    new Notification(notification.content.title, {
      body: notification.content.body,
      icon: '/favicon.ico'
    });
  }

  private isTabInactive() {
    return document.hidden;
  }
}

export default new SystemNotificationsService();
