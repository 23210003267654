import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import styles from "./filtersStyle";


const useStyles = makeStyles(styles);

export default function TextFilter({ column: { filterValue, Header, setFilter }}) {
  const classes = useStyles();

  return (
    <TextField fullWidth
               autoComplete="table-filter-field"
               value={filterValue || ""}
               onChange={event => setFilter(event.target.value)}
               margin="none"
               variant="outlined"
               className={classes.inputFilter}/>
  );
}
