import { makeStyles } from '@material-ui/core/styles';
import { dangerColor, successColor } from "assets/jss/common";


export default makeStyles(theme => ({
  firstCol: {
    width: 1,
    padding: "0 0 0 8px"
  },
  totals: {
    margin: "30px 0"
  },
  totalRow: {
    padding: "10px 40px",
    fontSize: "large",
    textAlign: "right",
    "& > strong": {
      display: "inline-block",
      marginLeft: "1%",
      minWidth: 100
    }
  },
  income: {
    color: successColor[0]
  },
  costs: {
    color: dangerColor[0]
  },
  input: {
    width: 50,
    marginLeft: theme.spacing(0.5),

    "& > input": {
      textAlign: "center"
    }
  }
}));
