import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import qs from "query-string";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import { EstimateType } from "config/constants.config";
import { useEstimateWizard } from "common/hooks/useEstimateWizard";
import GridContainer from "common/components/Grid/GridContainer";
import GridItem from "common/components/Grid/GridItem";
import Button from "common/components/CustomButtons/Button";
import Wizard from "common/views/Wizard/Wizard";

import CustomerStep from "./CustomerStep/CustomerStep";
import ItemsStep from "./ItemsStep/ItemsStep";
import MaterialsStep from "./MaterialsStep/MaterialsStep";
import LaborsStep from "./LaborStep/LaborStep";
import GutterStep from "./GutterStep/GutterStep";
import SummaryStep from "./SummaryStep/SummaryStep";


export function EstimateWizardPage({ match, location, history }) {
  const entityId = +match.params.id;
  const query = qs.parse(location.search) || {};

  const [localState, setLocalState] = useState({
    showConfirmation: false
  });

  const [state, actions] = useEstimateWizard(entityId, {
    type: query.type || EstimateType.ROOFING,
    customerId: query.customerId,
    customer: {},
    items: [],
    materials: [],
    gutter: [],
    comment: ""
  });

  const nextButton = entityId ? "Next" : null; // hide next button when creating a new estimate
  const laborName = `${state.estimate.type === EstimateType.SIDING ? 'Siding' : 'Roofing'} Labor`;

  const steps = useMemo(() => [
    // show next button in edit mode
    { id: "customer",  name: query.customerId ? "Address" : "Customer",   title: "Fill in customer information", component: CustomerStep, nextButton },
    { id: "items",     name: "Items",     title: "Estimate items",               component: ItemsStep },
    { id: "materials", name: "Materials", title: "Review Material Order Form",   component: MaterialsStep },
    { id: "labor",     name: laborName,     title: "Review Labor Invoice",         component: LaborsStep },
    { id: "gutter",    name: "Gutter Labor",    title: "Review Gutter Labor Invoice",  component: GutterStep },
    { id: "summary",   name: "Summary",   title: "Estimate summary",             component: SummaryStep }
  ], [nextButton, query, laborName]);

  const onClose = useCallback(() => {
    if (state.wizard.hasChanges && !localState.showConfirmation)
      setLocalState({
        showConfirmation: true
      });
    else
      actions.close();
  }, [state.wizard.hasChanges, localState, setLocalState, actions.close]);

  const cancelClose = useCallback(() => {
    setLocalState({
      showConfirmation: false
    });
  }, [setLocalState]);

  useEffect(() => {
    if (query.step && query.step !== state.wizard.currentStep)
      actions.goToStep(query.step);
  }, [query.step]);

  useEffect(() => {
    if (state.shouldExit)
      history.goBack();
  }, [state.shouldExit]);

  // transition from "/estimates/new/wizard" to "/estimates/:id/wizard"
  if (state.redirectToId && state.redirectToId !== entityId)
    return <Redirect to={`/estimates/${state.redirectToId}/wizard`}/>;

  return (
    <GridContainer justify="center">
      <GridItem xs={12} xl={10}>
        <Wizard
          onClose={onClose}
          onSave={actions.commit}
          onCancel={actions.cancel}
          steps={steps}
          state={state.wizard}
          loading={state.loading}
          arbitraryNavigation={!state.creatingNew && !state.wizard.hasChanges}
          goToStep={actions.goToStep}
          stepsProps={{
            estimate: state.estimate,
            patch: actions.patch
          }}
        />

        <Dialog open={!!localState.showConfirmation} onClose={cancelClose}>
          <DialogTitle>Leave without saving?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Some changes are not saved yet.<br/>
              Are you sure you want to leave the wizard without saving?<br/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelClose} color="transparent">
              Cancel
            </Button>
            <Button onClick={onClose} color="warning">
              Yes, leave
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}

export default withRouter(EstimateWizardPage);
