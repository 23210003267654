import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./tableToolbarStyle";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";


const useStyles = makeStyles(styles);

export default function TableToolbar({ actions, onAction, activeActions, children }) {
  const classes = useStyles() as any;

  return (
    <Toolbar classes={{ root: classes.root }} disableGutters variant="dense">
      <div className={classes.toolbarLeft}>
        {children}
      </div>

      <div className={classes.toolbarRight}>
        { actions.map(action => (
          <Tooltip title={action.title} key={action.key} placement="top">
            <IconButton onClick={() => onAction(action.key)} color={activeActions[action.key] ? "secondary" : "default"}>
              <action.Icon/>
            </IconButton>
          </Tooltip>
        ))}
      </div>
    </Toolbar>
  );
}
