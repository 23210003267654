import forEach from "lodash/forEach";
import transform from "lodash/transform";
import isEqual from "lodash/isEqual";


export const noop = item => item;

export const mapBy = (array, prop, transform = noop) => {
  const res = {};

  forEach(array, item => {
    const transformedItem = transform(item);
    res[transformedItem[prop]] = transformedItem;
  });

  return res;
};

export const pluralize = (str: string) => {
  const lastChar = str[str.length - 1];
  let ending = "s";

  if (lastChar === "y") {
    str = str.substring(0, str.length - 1);
    ending = "ies";
  }

  return str + ending;
};

export async function sleep(t = 0) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, t);
  });
}

export function getByPath(obj: object, path: string, def?: any) {
  if (!path)
    return obj;

  let pathObj = path.split(".");
  let val;

  try {
    val = pathObj.reduce((cursor, pathPart) => cursor[pathPart], obj);
  } catch (e) {// continue regardless of error
  }

  return typeof val !== 'undefined' ? val : def;
}

export function diff<T = object>(object: Partial<T>, base: T) {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key]))
      result[key] = value;
  }) as Partial<T>;
}
