import React, { PropsWithChildren } from "react";
import { WithStyles, withStyles } from '@material-ui/core/styles';

import Footer from "layouts/common/Footer/Footer";
import { styles, randomBackground } from "./authStyle";


interface IAuthLayoutProps extends WithStyles<typeof styles>{}

const AuthLayout: React.FunctionComponent<PropsWithChildren<IAuthLayoutProps>> = (props) => {
  const { children, classes } = props;

  return (
    <div className={classes.container} style={randomBackground()}>
      {children}
      <Footer white minimal noBorder />
    </div>
  );
};

export default withStyles(styles)(AuthLayout);
