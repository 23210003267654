import React from "react";
import cn from "classnames";
import { withStyles } from '@material-ui/core/styles';
import { Close, ExitToApp, Forward, Save } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";

import Card from "common/components/Card/Card";

import wizardStyle from "./wizardStyle";


interface IWizardProps {
  state: any;
  steps: any;
  goToStep: any;
  onClose: any;
  color?: string;
  classes: any;
  arbitraryNavigation: boolean;
  stepsProps: any;
  loading: boolean;
  onSave: any;
  onCancel: any;
  nextButtonText?: string;
  finishButtonText?: string;
  previousButtonText?: string;
}

interface IWizardState {
  movingTabStyle: any;
}

class Wizard extends React.Component<IWizardProps, IWizardState> {
  constructor(props) {
    super(props);

    this.state = {
      movingTabStyle: {
        transition: "transform 0s"
      }
    };
  }

  componentDidMount() {
    this.refreshAnimation();
    window.addEventListener("resize", this.refreshAnimation);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.refreshAnimation);
  }

  componentDidUpdate(prevProps) {
    if (this.props.state.currentStep !== prevProps.state.currentStep)
      this.refreshAnimation();
  }

  goForward = () => {
    const index = this.getStepIndex();

    if (index < this.props.steps.length - 1) {
      const nextStep = this.props.steps[index + 1];
      this.props.goToStep(nextStep.id);
    } else {
      this.props.onClose();
    }
  }

  goBack = () => {
    const index = this.getStepIndex();
    if (index > 0) {
      const prevStep = this.props.steps[index - 1];
      this.props.goToStep(prevStep.id);
    }
  }

  refreshAnimation = () => {
    const index = this.getStepIndex();
    var total = this.props.steps.length;
    var total_steps = this.props.steps.length;
    var move_distance = (this.refs.wizard as any).children[0].offsetWidth / total_steps;
    var index_temp = index;
    var vertical_level = 0;

    var mobile_device = window.innerWidth < 600 && total > 3;

    if (mobile_device) {
      move_distance = (this.refs.wizard as any).children[0].offsetWidth / 2;
      index_temp = index % 2;
    }

    var step_width = move_distance;
    move_distance = move_distance * index_temp;

    var current = index + 1;

    if (current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (
      current === total_steps ||
      (mobile_device === true && index % 2 === 1)
    ) {
      move_distance += 8;
    }

    if (mobile_device) {
      vertical_level = Math.floor(index / 2);
      vertical_level = vertical_level * 38;
    }
    var movingTabStyle = {
      width: step_width,
      transform:
        "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
      transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)"
    };
    this.setState({ movingTabStyle: movingTabStyle });
  }

  getStepIndex() {
    const stepId = this.props.state.currentStep;
    return this.props.steps.findIndex(step => step.id === stepId);
  }

  render() {
    const { classes, color, steps, onClose, state } = this.props;
    const index = this.getStepIndex();
    const step = steps[index];

    return (
      <div className={classes.wizardContainer} ref="wizard">
        <Card className={classes.card}>
          <div className={classes.wizardHeader}>
            { !!onClose && (
              <Tooltip title="Exit without saving" placement="left">
                <IconButton onClick={onClose} className={classes.closeBtn}>
                  <Close/>
                </IconButton>
              </Tooltip>
            )}
            <h3 className={classes.title}>{step.title}</h3>
          </div>

          <div className={classes.wizardNavigation}>
            <ul className={classes.nav}>
              {this.renderStepsNav()}
            </ul>
            <div className={classes.movingTab + " " + classes[color]} style={this.state.movingTabStyle}>
              {step.name + (state[step.id] ? " *" : "")}
            </div>
          </div>

          <div className={classes.content}>
            {this.renderSteps()}
          </div>

          {this.renderFooter()}
        </Card>

        {this.renderFloatingButtons()}
      </div>
    );
  }

  renderStepsNav() {
    const { classes, arbitraryNavigation, state } = this.props;

    return this.props.steps.map(step => {
      return (
        <li className={classes.steps} key={step.id}>
          <a href="javascript:void(0)"
             className={cn(classes.stepsAnchor, !arbitraryNavigation && classes.disabled)}
             onClick={arbitraryNavigation ? (() => this.props.goToStep(step.id)) : undefined}>
            {step.name + (state[step.id] ? " *" : "")}
          </a>
        </li>
      );
    })
  }

  renderSteps() {
    const { classes, loading, stepsProps, state } = this.props;
    const stepId = this.props.state.currentStep;

    return (
      <>
        { loading && (
          <div className={classes.launcher}>
            <CircularProgress size={60} color="secondary" />
          </div>
        )}
        {
          this.props.steps.map(step => {
            const stepContentClasses = cn({
              [classes.stepContentActive]: step.id === stepId,
              [classes.stepContent]: step.id !== stepId
            });

            return (
              <div className={stepContentClasses} key={step.id}>
                {step.id === stepId && (
                  <step.component
                    goForward={this.goForward}
                    goBack={this.goBack}
                    hasChanges={state.hasChanges}
                    {...stepsProps}
                  />
                )}
              </div>
            );
          })
        }
      </>
    );
  }

  renderFooter() {
    const { classes, steps, nextButtonText, finishButtonText, state, loading } = this.props;
    const stepIndex = this.getStepIndex();
    const step = steps[stepIndex] || {};
    const isLastStep = stepIndex >= steps.length - 1;
    const hasChanges = state[step.id];
    const showPrev = step.hasOwnProperty("prevButton") ? !!step.prevButton : stepIndex > 0;
    const showNext = step.hasOwnProperty("nextButton") ? !!step.nextButton : true;
    const nextText = step.nextButton || (isLastStep ? finishButtonText : nextButtonText);
    const nextBtnClasses = cn({
      [classes.saveBtn]: isLastStep,
      [classes.nextBtn]: !isLastStep
    });

    return (
      <div className={classes.footer}>
        <div className={classes.left}>
          {showPrev && (
            <Button variant="text"
                    color="inherit"
                    className={classes.prevBtn}
                    disabled={hasChanges || loading}
                    onClick={this.goBack}>
              {step.prevButton || this.props.previousButtonText}
            </Button>
          )}
        </div>
        <div className={classes.right}>
          {showNext && (
            <Button variant={isLastStep ? "contained" : "text"}
                    color="inherit"
                    className={nextBtnClasses}
                    disabled={hasChanges || loading}
                    onClick={this.goForward}>
              {nextText}
            </Button>
          )}
        </div>
        <div className={classes.clearfix} />
      </div>
    );
  }

  renderFloatingButtons() {
    const { classes, steps, state, loading, onSave, onCancel } = this.props;
    const stepIndex = this.getStepIndex();
    const step = steps[stepIndex] || {};
    const isLastStep = stepIndex >= steps.length - 1;
    const showSave = state[step.id];
    const showNext = !showSave && (step.hasOwnProperty("nextButton") ? !!step.nextButton : true);

    return showSave ? (
      <>
        <Tooltip title="Cancel" placement="left">
          <Fab className={cn(classes.cancelFab, classes.fab)} disabled={loading} onClick={onCancel}>
            <Close />
          </Fab>
        </Tooltip>
        <Tooltip title="Save" placement="left">
          <Fab className={cn(classes.finishFab, classes.fab)} disabled={loading} onClick={onSave}>
            <Save />
          </Fab>
        </Tooltip>
      </>
      ) : showNext && (
        <Tooltip title={isLastStep ? "Finish" : "Next"} placement="left">
          <Fab className={cn(isLastStep ? classes.finishFab : classes.nextFab, classes.fab)}
               disabled={loading}
               onClick={() => this.goForward()}>
            { isLastStep ? <ExitToApp /> : <Forward />}
          </Fab>
        </Tooltip>
      );
  }
}

Wizard['defaultProps'] = {
  color: "success",
  previousButtonText: "Back",
  nextButtonText: "Next",
  finishButtonText: "Finish"
};

export default withStyles(wizardStyle as any)(Wizard);
