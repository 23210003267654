import { createStyles, Theme } from '@material-ui/core/styles';
import { container } from 'styles';


export const styles = (theme: Theme) => createStyles({
  container: {
    ...container,
    zIndex: 4
  },
  card: {
    maxWidth: 350,
    margin: "auto"
  },
  cardHeader: {
    textAlign: "center"
  },
  cardTitle: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
    fontSize: "x-large",
    fontWeight: 400,
    color: 'white'
  },
  inputAdornmentIcon: {
    color: '#555555'
  },
  textField: {
    margin: theme.spacing(2, 0)
  },
  textFieldInput: {
    '&::before': {
      borderBottomColor: '#D2D2D2'
    }
  },
  textFieldLabel: {
    color: '#AAAAAA'
  },
  loginButton: {
    padding: theme.spacing(2)
  }
});
