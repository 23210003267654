import React, { useCallback } from "react";
import Select from "react-select";
import pick from "lodash/pick";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import { actions as customersActions, selectors as customersSelectors } from "store/entities/customers";
import { capitalize } from "common/utils/format.util";
import { useReadAction } from "common/hooks/crud/useReadAction";
import GridContainer from "common/components/Grid/GridContainer";
import GridItem from "common/components/Grid/GridItem";
import style from "./customerStepStyle";


const useStyles = makeStyles(style);

const customerOverwritableFields = ["name", "email", "phone", "address", "company"];

export default function CustomerStep({ estimate, patch }) {
  const [customers] = useReadAction(customersActions, customersSelectors);
  const classes = useStyles();
  const customer = customers.byKey[estimate.customerId];
  const customerData = estimate.customerData || customer;

  const onSwitchOverwrite = useCallback(() => {
    let cd = null;

    if (!estimate.customerData && customer)
      cd = pick(customer, customerOverwritableFields); // copy existing customer's data

    patch({ customerData: cd });
  }, [patch, estimate, customer]);

  const patchCustomerData = (field, value) => patch({
    customerData: {
      ...estimate.customerData,
      [field]: value
    }
  });

  // TODO: replace Select with Autocomplete element from Material-UI lab
  return (
    <GridContainer justify="center">
      <GridItem xs={12} lg={7} className={classes.userSelectCell}>
        <Select options={customers.all}
                value={customer}
                isLoading={customers.loading}
                onChange={customer => patch({ customerId: customer.id })}
                getOptionLabel={customer => customer.name}
                getOptionValue={customer => customer.id}
                placeholder="Select customer"
                className={classes.select}
                classNamePrefix="customers-select"
                styles={{ menu: base => ({ ...base, zIndex: 10 }) }}
                isSearchable />

        { !!estimate.customerId && (
          <Tooltip title="Overwrite customer data for this particular estimate" placement="bottom">
            <FormControlLabel
              label="Overwrite"
              labelPlacement="start"
              control={
                <Switch color="primary" checked={!!estimate.customerData} onChange={onSwitchOverwrite}/>
              }
            />
          </Tooltip>
        )}
      </GridItem>

      {customerData && (
        <GridItem xs={12} lg={7}>
          {customerOverwritableFields.map(field => (
            <TextField
              key={field}
              label={capitalize(field)}
              variant="outlined"
              fullWidth
              placeholder="<Blank>"
              InputLabelProps={{ shrink: true }}
              classes={{ root: classes.input }}
              disabled={!estimate.customerData}
              value={customerData[field]}
              onChange={event => patchCustomerData(field, event.target.value)}
            />
          ))}
        </GridItem>
      )}
    </GridContainer>
  );
}
