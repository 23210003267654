import React from 'react';
import { Redirect, Route, RouteChildrenProps } from 'react-router';

import { adminItemsRoutes } from 'config/routing.config';
import { getActiveRoutePath } from 'common/utils/routing.util';
import TabsLayout from 'layouts/TabsLayout/TabsLayout';
import ItemsPage from './ItemsPage/ItemsPage';
import MaterialsPage from './MaterialsPage/MaterialsPage';
import LaborPage from './LaborPage/LaborPage';
import GutterPage from './GutterPage/GutterPage';
import CategoriesPage from './CategoriesPage/CategoriesPage';


export interface IAdminItemsModuleProps extends RouteChildrenProps {
  loadEverything: () => void;
}

const AdminItemsModule: React.FunctionComponent<IAdminItemsModuleProps> = (props) => {
  const { loadEverything } = props;

  React.useEffect(() => {
    loadEverything();
  }, []);

  return (
    <TabsLayout>
      <Route path={adminItemsRoutes.ITEMS} component={ItemsPage} exact />
      <Route path={adminItemsRoutes.MATERIALS} component={MaterialsPage} />
      <Route path={adminItemsRoutes.LABOR} component={LaborPage} />
      <Route path={adminItemsRoutes.GUTTER} component={GutterPage} />
      <Route path={adminItemsRoutes.CATEGORIES} component={CategoriesPage} />
      <Redirect to={adminItemsRoutes.DEFAULT} />
    </TabsLayout>
  );
};

export default AdminItemsModule;
