import { connect } from 'react-redux';

import { AppState } from 'store/store.d';
import { actions, selectors } from "store/auth";
import LoginPage, { ILoginPageProps } from './LoginPage';


type StateProps = Pick<ILoginPageProps, 'isLoading'>;
type DispatchProps = Pick<ILoginPageProps, 'logIn'>;

const mapStateToProps = (state: AppState): StateProps => ({
  isLoading: selectors.isLoading(state)
});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  logIn: (email: string, password: string) => dispatch(actions.logIn(email, password))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
