import React, { ReactElement } from "react";
import { RouteChildrenProps, withRouter } from 'react-router';
import { Link, Route, RouteProps, Switch } from "react-router-dom";
import { UnregisterCallback } from 'history';
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from '@material-ui/core/Grid';

import { pageTitles } from 'config/routing.config';
import { getActiveRoutePath } from 'common/utils/routing.util';
import { styles } from "./tabsLayoutStyle";


interface ITabsLayoutProps extends WithStyles<typeof styles> {
  children: ReactElement<RouteProps, typeof Route>[];
  disabled?: boolean;
}

type TabsLayoutProps = ITabsLayoutProps & RouteChildrenProps;

interface ITabsLayoutState {
  activeRoute: string;
}

class TabsLayout extends React.Component<TabsLayoutProps, ITabsLayoutState> {
  state: ITabsLayoutState = {
    activeRoute: null
  };

  private unsubscribe: UnregisterCallback;

  constructor(props: TabsLayoutProps) {
    super(props);

    this.unsubscribe = props.history.listen(location => {
      this.setState({
        activeRoute: getActiveRoutePath(location)
      });
    });
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        activeRoute: getActiveRoutePath(this.props.location)
      });
    }, 0);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { classes, children, disabled } = this.props;
    const { activeRoute } = this.state;

    return (
      <Grid>
        <Grid item xs={12} className={classes.pageHeader}>
          <Tabs
            value={activeRoute}
            classes={{root: classes.tabsRoot, indicator: classes.tabIndicator, flexContainer: classes.tabsContainer}}
            TabIndicatorProps={{children: <div/>}}
          >
            {React.Children.map(children, child => !!child?.props?.path && (
              <Tab
                component={Link}
                to={child.props.path as string}
                key={"tab-" + child.props.path}
                value={child.props.path as string}
                label={pageTitles[child.props.path as string] || 'Undefined'}
                disabled={disabled}
                disableRipple
                classes={{root: classes.tabPane, selected: classes.tabPaneSelected}}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Switch>
            {children}
          </Switch>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(
  withRouter(TabsLayout)
);
