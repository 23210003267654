import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { FlipToBack } from "@material-ui/icons";

import { BasicGroup } from "config/constants.config";
import { buildBasicItemsSelector } from "store/associatedItems";
import { selectors as categoriesSelectors } from "store/entities/categories";
import SubItemsTable from "common/views/SubItemsTable/SubItemsTable";


export default function GutterPage() {
  const categories = useSelector(categoriesSelectors.gutterCategories);
  const gutterSelector = useMemo(
    () => buildBasicItemsSelector(BasicGroup.GUTTER),
    []
  );

  return (
    <SubItemsTable
      entity="gutter item"
      title="Gutter"
      Icon={FlipToBack}
      getEntities={gutterSelector}
      categories={categories}
    />
  );
}
