import { successColor } from "assets/jss/common";


export default theme => ({
  creationButton: {
    position: "fixed",
    bottom: 25,
    right: 30,
    padding: theme.spacing(.5, 3),
    zIndex: 100,
    backgroundColor: successColor[0],
    color: "white",
    "& svg": {
      width: 30,
      height: 30
    },
    "&:hover": {
      backgroundColor: successColor[1],
    }
  },
  cardHeader: {
    height: 0
  },
  confirmationDialog: {
    minWidth: 450,
    [theme.breakpoints.down("xs")]: {
      minWidth: "95%"
    }
  }
});
