import * as ActionCable from 'actioncable';
import AuthService from './auth.service';


class CableService {
  private authToken: string = null;
  private consumer: ActionCable.Cable;

  constructor(options: { autoConnect?: boolean } = {}) {
    if (options.autoConnect)
      AuthService.authToken$.subscribe(this.updateToken);
  }

  public isConnected() {
    return !!this.consumer;
  }

  public connect() {
    if (this.isConnected())
      this.disconnect();

    this.consumer = ActionCable.createConsumer(this.getCableUrl());
    // this.consumer.connect();
    console.log('Action cable consumer is created', this.consumer);
  }

  public disconnect() {
    this.consumer.disconnect();
  }

  public subscribeToChannel<T = any>(channel: string, callbacks: { received?: (data: T) => void, connected?: () => void, disconnected?: () => void }) {
    return this.consumer?.subscriptions.create({
      channel
    }, callbacks);
  }

  private updateToken = (token: string) => {
    if (token === this.authToken)
      return;

    this.authToken = token;

    if (token)
      this.connect();
    else
      this.disconnect();
  }

  private getCableUrl() {
    return `/cable?token=${encodeURIComponent(this.authToken.replace('Bearer ', ''))}`;
  }
}

export default new CableService({
  autoConnect: true
});
