import React, { useCallback } from "react";

import { DeleteForever } from "@material-ui/icons";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import useStyles from "./itemFormulaStyle";


function ItemFormula(props) {
  const { formula, onChange, onRemove, availableSubItems, readOnly, multiplier } = props;
  const classes = useStyles();

  const onFactorChange = useCallback(event => {
    onChange({
      factor: event.target.value
    });
  }, [onChange]);

  const onSubItemChange = useCallback(event => {
    onChange({
      nestedItemId: event.target.value
    });
  }, [onChange]);

  return (
    <ListItem dense className={classes.container}>
      <TextField
        margin="dense"
        type="number"
        variant="outlined"
        inputProps={{ readOnly: readOnly }}
        value={multiplier ? multiplier * formula.factor : formula.factor}
        className={classes.input}
        onChange={onFactorChange}
      />
      <span className={classes.separator}>×</span>
      <TextField
        select
        margin="dense"
        variant="outlined"
        inputProps={{ readOnly: readOnly }}
        className={classes.select}
        value={formula.nestedItemId}
        onChange={onSubItemChange}
        InputProps={{
          startAdornment:
            <InputAdornment position="start" disableTypography disablePointerEvents className={classes.inputAdorement}>
              {formula.nestedItem && formula.nestedItem.unit.name}
            </InputAdornment>
        }}
      >
        { availableSubItems.map(item => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>

      { !readOnly && (
        <IconButton size="small" className={classes.removeButton} onClick={onRemove}>
          <DeleteForever/>
        </IconButton>
      )}
    </ListItem>
  );
}

export default ItemFormula;
