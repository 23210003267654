import React, { useCallback } from "react";
import cn from "classnames";
import compact from "lodash/compact";

import Tooltip from "@material-ui/core/Tooltip";
import { Add, EditTwoTone, DeleteForeverTwoTone } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Fab from "@material-ui/core/Fab";

import EditDialog from "common/views/EditDialog/EditDialog";
import Card from "common/components/Card/Card";
import CardHeader from "common/components/Card/CardHeader";
import CardBody from "common/components/Card/CardBody";
import CardIcon from "common/components/Card/CardIcon";
import CustomTable from "common/components/Table/Table";
import Button from "common/components/CustomButtons/Button";

import styles from "./tableCRUDStyle";


const useStyles = makeStyles(styles as any);

export default function TableCRUD(props) {
  const {
    Icon,
    IconCreate,
    FabClassName,
    title,
    entity,
    crudState,
    actions,
    columns,
    fields,
    getRemovalConfirmation,
    isAuthorizedToEdit,
    isAuthorizedToDelete,
    extraActions,
    TableProps = {}
  } = props;

  const classes = useStyles();

  const crudActions = useCallback(record => {
    let extra = extraActions?.(record) || [];
    let editDelete = [
      actions?.update && (isAuthorizedToEdit ? isAuthorizedToEdit(record) : true) && {
        title: "Edit",
        callback: () => actions.update(record),
        Icon: EditTwoTone,
        type: "primary"
      },
      actions?.delete && (isAuthorizedToDelete ? isAuthorizedToDelete(record) : true) && {
        title: "Delete",
        callback: () => actions.delete(record),
        Icon: DeleteForeverTwoTone,
        type: "danger"
      }
    ];

    return compact([...extra, ...editDelete]);
  }, [actions, extraActions, isAuthorizedToEdit]);

  return (
    <>
      <Card>
        <CardHeader color="primary" icon={!!Icon} className={classes.cardHeader}>
          {Icon && (
            <CardIcon color="primary" title={title}>
              <Icon/>
            </CardIcon>
          )}
        </CardHeader>

        <CardBody>
          <CustomTable
            columns={columns}
            data={crudState.all}
            loading={crudState.loading}
            actions={crudActions}
            {...TableProps}
          />
        </CardBody>
      </Card>

      {!!actions?.create && (
        <Tooltip title={"Create " + entity} placement="left">
          <Fab classes={{ root: cn(classes.creationButton, FabClassName) }} onClick={() => actions.create()}>
            <IconCreate />
          </Fab>
        </Tooltip>
      )}

      {!!actions?.reset && (
        <Dialog open={!!crudState.deletingEntity} onClose={actions.reset} classes={{ paper: classes.confirmationDialog }}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {crudState.deletingEntity && getRemovalConfirmation(crudState.deletingEntity) || `Are you sure you want to delete this ${entity}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={actions.reset} color="transparent">
              Cancel
            </Button>
            <Button onClick={() => actions.delete()} color="danger">
              DELETE
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {!!fields && actions?.commit && (
        <EditDialog
          entity={entity}
          data={crudState.currentEntity}
          createNew={crudState.creatingNew}
          loading={crudState.currentEntityLoading}
          error={crudState.currentEntityError}
          onClose={actions.reset}
          onEdit={actions.patch}
          onSave={actions.commit}
          fields={fields}
        />
      )}
    </>
  );
}

TableCRUD.defaultProps = {
  IconCreate: Add,
  entity: "record",
  isAuthorizedToEdit: () => true
};
