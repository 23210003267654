import React from "react";
import cn from "classnames";

import { withStyles, WithStyles } from '@material-ui/core/styles';
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";

import logo from "assets/img/logo-white.svg";

import { AppIcon } from 'config/icons.config';
import { adminRoutes, homeRoutes } from 'config/routing.config';

import SidebarItem from './SidebarItem/SidebarItem';
import { styles } from "./sidebarStyle";


interface ISidebarProps extends WithStyles<typeof styles> {
  adminAccessAllowed: boolean;
  isMinimized: boolean;
  brandText: string;
  open: boolean;
  onToggle: () => void;
}

interface ISidebarState {
  isTemporarilyExpanded: boolean;
}

class Sidebar extends React.Component<ISidebarProps, ISidebarState> {
  state: ISidebarState = {
    isTemporarilyExpanded: false
  };

  render() {
    const { classes, open, isMinimized, onToggle } = this.props;
    const { isTemporarilyExpanded } = this.state;
    const isMinimizedRightNow = isMinimized && !isTemporarilyExpanded;

    return (
      <>
        {/* Mobile Drawer*/}
        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            anchor="left"
            open={open}
            classes={{
              paper: cn(classes.drawerPaper, classes.drawerPaperMobile)
            }}
            ModalProps={{
              keepMounted: true // better open performance on mobile
            }}
            onClose={onToggle}
          >
            {this.renderContent()}
          </Drawer>
        </Hidden>

        {/* Desktop Drawer */}
        <Hidden smDown implementation="js">
          <Drawer
            anchor="left"
            variant="permanent"
            open
            onMouseOver={() => isMinimized && this.setState({ isTemporarilyExpanded: true })}
            onMouseOut={() => isMinimized && this.setState({ isTemporarilyExpanded: false })}
            PaperProps={{
              elevation: 16
            }}
            classes={{
              root: cn(classes.drawerDesktop, {
                [classes.drawerDesktopMinimized]: isMinimizedRightNow,
                [classes.drawerDesktopFixed]: isMinimized
              }),
              paper: classes.drawerPaper
            }}
          >
            {this.renderContent()}
          </Drawer>
        </Hidden>
      </>
    );
  }

  private renderContent() {
    const { classes, isMinimized, adminAccessAllowed, brandText } = this.props;
    const { isTemporarilyExpanded } = this.state;
    const mini = isMinimized && !isTemporarilyExpanded;
    const brandTextClass = cn(classes.brandText, {
      [classes.brandTextMini]: isMinimized && !this.state.isTemporarilyExpanded
    });

    return (
      <>
        <div className={classes.brand}>
          <a href="javascript:void(0)" className={classes.brandLogo}>
            <img src={logo} alt="logo" className={classes.brandLogoImg} />
          </a>
          <a href="javascript:void(0)" className={brandTextClass}>
            {brandText}
          </a>
        </div>

        <List className={classes.list}>
          <SidebarItem route={homeRoutes.CUSTOMERS} icon={AppIcon.CUSTOMER} mini={mini} />
          <SidebarItem route={homeRoutes.ESTIMATES} icon={AppIcon.ESTIMATE} mini={mini} />
          <SidebarItem route={homeRoutes.DASHBOARD} icon={AppIcon.DASHBOARD} mini={mini} />
          <SidebarItem route={homeRoutes.AUDIT_LOGS} icon={AppIcon.AUDIT} mini={mini} />

          {adminAccessAllowed && (
            <SidebarItem route={homeRoutes.ADMIN} icon={AppIcon.ADMIN} mini={mini}>
              <List className={cn(classes.list, classes.nestedList)}>
                <SidebarItem route={adminRoutes.ITEMS} icon={AppIcon.ITEMS_OUTLINED} mini={mini} />
                <SidebarItem route={adminRoutes.USERS} icon={AppIcon.USERS_OUTLINED} mini={mini} />
                <SidebarItem route={adminRoutes.TEMPLATES} icon={AppIcon.TEMPLATES_OUTLINED} mini={mini} />
              </List>
            </SidebarItem>
          )}
        </List>
        <div className={classes.backgroundOverlay} />
      </>
    );
  }
}

export default withStyles(styles)(Sidebar);
