import { Action, combineReducers } from "@reduxjs/toolkit";

import { AppState } from './store';
import { reducer as auth, actions as authActions } from "./auth";
import { reducer as customers } from "./entities/customers";
import { reducer as estimates } from "./entities/estimates";
import { reducer as users } from "./entities/users";
import { reducer as templates } from "./entities/templates";
import { reducer as audit } from "./entities/audit";
import { reducer as inAppNotifications } from "./entities/notifications";
import { reducer as associatedItems } from "./associatedItems";
import { reducer as estimateWizard } from "./estimateWizard";
import { reducer as notifications } from "./notifications";
import { reducer as ui } from './ui';


const appReducer = combineReducers({
  auth,
  customers,
  estimates,
  users,
  templates,
  associatedItems,
  estimateWizard,
  notifications,
  ui,
  audit,
  inAppNotifications
});

const rootReducer = (state: AppState, action: Action<string>) => {
  if (action.type === authActions.reset.toString()) {
    const { auth, estimateWizard } = state;
    state = {
      auth,
      estimateWizard,
      associatedItems: null,
      customers: null,
      estimates: null,
      notifications: null,
      templates: null,
      users: null,
      ui: null,
      audit: null,
      inAppNotifications: null
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
