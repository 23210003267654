import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CreateNewFolder, Filter1, FolderOpenOutlined, PlusOneOutlined } from "@material-ui/icons";

import { EstimateType } from "config/constants.config";
import { useCRUDActions } from "common/hooks/crud/useCRUDActions";
import { actions as categoriesActions, selectors as categoriesSelectors } from "store/entities/categories";
import { actions as unitsActions, selectors as unitsSelectors } from "store/entities/units";

import GridContainer from "common/components/Grid/GridContainer";
import GridItem from "common/components/Grid/GridItem";
import TableCRUD from "common/views/TableCRUD/TableCRUD";

import styles from "./categoriesStyle";


const useStyles = makeStyles(styles);


export default function CategoriesPage() {
  const classes = useStyles();
  const [categoriesState, cActions] = useCRUDActions(categoriesActions, {
    ...categoriesSelectors,
    getEntities: categoriesSelectors.aggregatedCategories
  }, {
    confirmDeletion: true,
    immediate: false,
    defaults: {
      name: "",
      type: EstimateType.COMMON
    }
  });

  const [unitsState, uActions] = useCRUDActions(unitsActions, unitsSelectors, {
    confirmDeletion: true,
    immediate: false,
    defaults: {
      name: ""
    }
  });

  const categoriesColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Type',
        accessor: 'type'
      }
    ],
    []
  );

  const categoriesFields = useMemo(
    () => [
      {
        label: "Name",
        required: true,
        accessor: "name"
      },
      {
        label: "Type",
        required: true,
        accessor: "type",
        options: Object.values(EstimateType).map(t => ({
          value: t,
          label: t
        })) || []
      }
    ],
    []);

  const unitsColumns = useMemo(
    () => [{
      Header: 'Name',
      accessor: 'name'
    }],
    []
  );

  const unitsFields = useMemo(
    () => [{
      label: "Name",
      required: true,
      accessor: "name"
    }],
    []);

  return (
    <GridContainer justify="space-evenly">
      <GridItem xs={12} md={6} lg={5} xl={4}>
        <TableCRUD
          Icon={FolderOpenOutlined}
          IconCreate={CreateNewFolder}
          FabClassName={classes.fabCategory}
          title="Categories"
          crudState={categoriesState}
          actions={cActions}
          columns={categoriesColumns}
          fields={categoriesFields}
          entity="category"
          TableProps={{ defaultSortBy: [{ id: "name" }] }}
          getRemovalConfirmation={cat => `Are you sure you want to delete category ${cat.name}?`}
        />
      </GridItem>

      <GridItem xs={12} md={6} lg={5} xl={4}>
        <TableCRUD
          Icon={Filter1}
          IconCreate={PlusOneOutlined}
          FabClassName={classes.fabUnit}
          title="Units"
          crudState={unitsState}
          actions={uActions}
          columns={unitsColumns}
          fields={unitsFields}
          entity="unit"
          TableProps={{ defaultSortBy: [{ id: "name" }] }}
          getRemovalConfirmation={cat => `Are you sure you want to delete unit ${cat.name}?`}
        />
      </GridItem>
    </GridContainer>
  );
}
