import React, { useCallback, useMemo } from "react";
import { Link, withRouter } from "react-router-dom";
import cn from "classnames";
import moment from "moment";
import orderBy from "lodash/orderBy";
import { Assessment, House, LineWeight, PermContactCalendar } from "@material-ui/icons";

import { EstimateStatus, EstimateType } from "config/constants.config";
import { formatCurrency } from "common/utils/format.util";
import { useReadAction } from "common/hooks/crud/useReadAction";
import GridContainer from "common/components/Grid/GridContainer";
import GridItem from "common/components/Grid/GridItem";
import Card from "common/components/Card/Card";
import CardIcon from "common/components/Card/CardIcon";
import CardHeader from "common/components/Card/CardHeader";
import CardBody from "common/components/Card/CardBody";
import CustomTable from "common/components/Table/Table";
import { actions as customersActions, selectors as customersSelectors } from "store/entities/customers";
import { actions as estimatesActions, selectors as estimatesSelectors } from "store/entities/estimates";

import { useStyles } from "./dashboardStyle";


function DashboardPage({ history }) {
  const classes = useStyles();

  const [customers] = useReadAction(customersActions, customersSelectors);
  const [estimates] = useReadAction(estimatesActions, estimatesSelectors);
  const showCustomerEstimates = useCallback(
    customer => history.push(`/estimates?customerId=${customer.id}`),
    []
  );

  const showEstimate = useCallback(
    estimate => history.push(`/estimates/${estimate.id}/wizard?step=summary`),
    []
  );

  const latestCustomers = useMemo(
    () => getTop(customers.all, "estimatesTotal"),
    [customers.all]
  );

  const latestEstimates = useMemo(
    () => getTop(estimates.all, "updatedAt"),
    [estimates.all]
  );

  const customersColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name"
      }, {
        Header: "Address",
        accessor: "address"
      }, {
        Header: "Estimates",
        accessor: "estimatesTotal",
        sortType: "basic",
        disableFilters: true,
        Cell: ({ cell: { value }, row: { original }}) => (
          <>
            {formatCurrency(value || 0)}&nbsp;
            (<strong>{original.estimatesCount || 0}</strong>)
          </>
        )
      }
    ],
    []
  );

  const estimatesColumns = useMemo(
    () => [
      {
        Header: "",
        accessor: "type",
        style: { width: 0, paddingRight: 12, paddingTop: 4, paddingBottom: 0 },
        title: ({ value }) => value,
        Cell: ({ cell: { value }}) =>
          value === EstimateType.ROOFING
            ? <House className={classes.typeIcon} />
            : <LineWeight className={classes.typeIcon} />
      },
      {
        Header: "Customer",
        accessor: "customer.name"
      }, {
        Header: "Status",
        accessor: "status",
        style: {paddingTop: 4, paddingBottom: 4 },
        Cell: ({ cell: { value }}) => (
          <span
            className={cn(classes.status, {
              [classes.statusDraft]: value === EstimateStatus.DRAFT,
              [classes.statusPending]: value === EstimateStatus.PENDING,
              [classes.statusSigned]: value === EstimateStatus.SIGNED,
              [classes.statusClosed]: value === EstimateStatus.CLOSED
            })}
          >
            {value}
          </span>
        )
      }, {
        Header: "Income",
        accessor: "balance.totalIncome",
        sortType: "basic",
        Cell: ({ cell: { value }}) => <strong className={classes.income}>{formatCurrency(value)}</strong>
      }, {
        Header: "Costs",
        accessor: "balance.totalCosts",
        sortType: "basic",
        Cell: ({ cell: { value }}) => <strong className={classes.costs}>{formatCurrency(value)}</strong>
      }, {
        Header: "Updated",
        accessor: "updatedAt",
        sortDescFirst: true,
        disableFilters: true,
        Cell: ({ cell: { value }}) => moment(value).fromNow()
      }
    ],
    []
  );

  return (
    <GridContainer>
      <GridItem xs={12} xl={6}>
        <Card>
          <CardHeader color="success" text>
            <Link to="/customers">
              <CardIcon color="success" title={`Top ${latestCustomers.length} Customers`}>
                <PermContactCalendar className={classes.cardTitleIcon}/>
              </CardIcon>
            </Link>
          </CardHeader>
          <CardBody>
            <CustomTable
              columns={customersColumns}
              data={latestCustomers}
              loading={customers.loading}
              recordAction={showCustomerEstimates}
              noToolbar
              noSorting
              noPagination
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} xl={6}>
        <Card>
          <CardHeader color="primary" text>
            <Link to="/estimates">
              <CardIcon color="primary" title={`${latestEstimates.length} Latest Estimates`}>
                <Assessment className={classes.cardTitleIcon}/>
              </CardIcon>
            </Link>
          </CardHeader>
          <CardBody>
            <CustomTable
              columns={estimatesColumns}
              data={latestEstimates}
              loading={estimates.loading}
              recordAction={showEstimate}
              noToolbar
              noSorting
              noPagination
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );

  function getTop(items, field, limit = 10) {
    return orderBy(items, [field], ["desc"]).slice(0, limit);
  }
}

export default withRouter(DashboardPage);
