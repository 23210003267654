import union from "lodash/union";
import omit from "lodash/omit";

import { mapBy } from "common/utils";
import { EntitiesState } from './state';
import { keys } from '@material-ui/core/styles/createBreakpoints';


export const mergeArray = (state, array) => {
  return mergeNormalizedStates(
    state,
    normalizeArray(array)
  );
};

export const normalizeArray = <T extends Entity>(array: T[], transformItem?) => {
  if (array.length > 0) {
    const obj = mapBy(array, 'id', transformItem);
    return {
      keys: array.map(item => item['id']),
      byKey: obj
    };
  }

  return {
    keys: [],
    byKey: {}
  };
};

export const mergeNormalizedStates = <T extends Entity>(stateA: EntitiesState<T>, stateB: Partial<EntitiesState<T>>) => ({
  ...stateA,
  byKey: {
    ...stateA.byKey,
    ...stateB.byKey
  },
  keys: union(stateA.keys, stateB.keys)
});

export const addNormalizedItem = <T extends Entity>(state: EntitiesState<T>, item: T, toTheTail = false) => ({
  ...state,
  byKey: {
    ...state.byKey,
    [item['id']]: item
  },
  // avoid adding the same entity twice to the store
  keys: state.keys.includes(item['id'])
    ? state.keys
    : (
      toTheTail
        ? [...state.keys, item['id']]
        : [item['id'], ...state.keys]
    )
});

export const updateNormalizedItem = <T extends Entity>(state: EntitiesState<T>, data: AtLeast<T, 'id'>) => ({
  ...state,
  byKey: {
    ...state.byKey,
    [data['id']]: {
      ...state.byKey[data['id'] as EntityId],
      ...data
    }
  }
});

export const deleteNormalizedItem = <T extends Entity>(state: EntitiesState<T>, itemKey: EntityId) => ({
  ...state,
  byKey: omit(state.byKey, itemKey),
  keys: state.keys.filter(key => key !== itemKey)
});
