import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { appRoutes } from 'config/routing.config';
import { actions as authActions } from "store/auth";
import AuthModule from "modules/auth";
import HomeModule from 'modules/home';
import Notifier from "common/views/Notifier/Notifier";
import PrivateRoute from "common/components/PrivateRoute/PrivateRoute";


interface IAppProps {
  initAuth: () => void;
}

export class App extends React.Component<IAppProps> {
  constructor(props: IAppProps) {
    super(props);
    props.initAuth();
  }

  render() {
    return (
      <>
        <Notifier/>
        <BrowserRouter>
          <Switch>
            <Route path={appRoutes.AUTH} component={AuthModule}/>
            <PrivateRoute component={HomeModule}/>
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

// Connect to the store

type DispatchProps = Pick<IAppProps, 'initAuth'>;

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  initAuth: () => dispatch(authActions.initialize())
});

export default connect(null, mapDispatchToProps)(App);
