import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Person, PersonAdd } from "@material-ui/icons";

import { actions as usersActions, selectors as usersSelectors } from "store/entities/users";

import { UserRole } from "config/constants.config";
import { selectors as authSelectors } from "store/auth";
import { useCRUDActions } from "common/hooks/crud/useCRUDActions";
import GridContainer from "common/components/Grid/GridContainer";
import GridItem from "common/components/Grid/GridItem";
import TableCRUD from "common/views/TableCRUD/TableCRUD";


export default function UsersPage() {
  const isAdmin = useSelector(authSelectors.isAdmin);
  const [state, actions] = useCRUDActions(usersActions, usersSelectors, {
    confirmDeletion: true,
    defaults: {
      name: "",
      email: "",
      role: UserRole.USER,
      password: ""
    }
  });

  const isAuthorized = useCallback(user => isAdmin || !usersSelectors.isAdmin(user), [isAdmin]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Role',
        accessor: 'role'
      }
    ],
    []
  );

  const fields = useMemo(
    () => [
      {
        label: "Name",
        required: true,
        inline: true,
        accessor: "name"
      }, {
        label: "Role",
        inline: true,
        accessor: "role",
        style: {
          maxWidth: 100,
          marginLeft: 16
        },
        options: [{
          value: UserRole.USER,
          label: "User"
        }, {
          value: UserRole.MANAGER,
          label: "Manager"
        }, {
          value: UserRole.ADMIN,
          label: "Admin",
          disabled: !isAdmin
        }]
      }, {
        label: "Email",
        required: true,
        accessor: "email",
        TextFieldProps: {
          type: "email"
        }
      }, {
        label: "Password",
        required: state.creatingNew,
        accessor: "password",
        TextFieldProps: {
          type: "password"
        }
      }
    ],
    [state.creatingNew, isAdmin]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <TableCRUD
          Icon={Person}
          IconCreate={PersonAdd}
          crudState={state}
          actions={actions}
          columns={columns}
          fields={fields}
          entity="user"
          TableProps={{ defaultSortBy: [{ id: "name" }] }}
          getRemovalConfirmation={user => `Are you sure you want to delete ${user.name}?`}
          isAuthorizedToEdit={isAuthorized}
          isAuthorizedToDelete={isAuthorized}
        />
      </GridItem>
    </GridContainer>
  );
}
