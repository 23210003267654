import React, { useMemo } from "react";
import cn from "classnames";
import moment from "moment";
import { History } from "@material-ui/icons";

import { actions as auditActions, selectors as auditSelectors } from "store/entities/audit";
import { actions as notificationsActions, selectors as notificationsSelectors } from "store/entities/notifications";

import { useReadAction } from "common/hooks/crud/useReadAction";
import TableCRUD from "common/views/TableCRUD/TableCRUD";
import GridContainer from "common/components/Grid/GridContainer";
import GridItem from "common/components/Grid/GridItem";
import { AuditLogLevel } from 'config/constants.config';

import useStyles from "./auditStyle";


export function AuditPage() {
  const classes = useStyles();
  const [state] = useReadAction(auditActions, auditSelectors);
  useReadAction(notificationsActions, notificationsSelectors);

  const tableProps = useMemo(() => ({
    defaultSortBy: [{ id: "createdAt", desc: true }],
  }), []);

  const columns = useMemo(
    () => [
      {
        Header: "Timestamp",
        accessor: "createdAt",
        Cell: ({ cell: { value }}) => moment(value).format("MMM DD, HH:mm:ss")
      },
      {
        Header: "Level",
        accessor: "level",
        Cell: ({ cell: { value }}) => (
          <span
            className={cn(classes.status, {
              [classes.levelCritical]: value === AuditLogLevel.CRITICAL,
              [classes.levelImportant]: value === AuditLogLevel.IMPORTANT,
              [classes.levelDefault]: value === AuditLogLevel.DEFAULT
            })}
          >
            {value}
          </span>
        )
      },
      {
        Header: "Description",
        accessor: "description"
      },
      {
        Header: "Details",
        accessor: "details",
        style: {maxWidth: "40vw", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" },
        Cell: ({ cell: { value }}) => value && JSON.stringify(value) || '',
        title: ({ value }) => value && JSON.stringify(value) || ''
      }
    ],
    []
  );

  return (
     <GridContainer>
       <GridItem xs={12}>
         <TableCRUD
           Icon={History}
           crudState={state}
           columns={columns}
           entity="audit-log"
           TableProps={tableProps}
         />
       </GridItem>
     </GridContainer>
  );
}

export default AuditPage;
