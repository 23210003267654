import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { BasicGroup } from "config/constants.config";
import { buildUpdatingEstimateBasicItemsSelector } from "store/entities/estimates";

import ItemsWrapper from "../ItemsWrapper/ItemsWrapper";


export default function GutterStep({ estimate, patch }) {
  const guttersSelector = useMemo(
    () => buildUpdatingEstimateBasicItemsSelector(BasicGroup.GUTTER),
    [estimate.id, estimate.type]
  );
  const estimateGutters = useSelector(guttersSelector);

  return (
    <ItemsWrapper
      estimate={estimate}
      patch={patch}
      data={estimateGutters}
      group={BasicGroup.GUTTER}
      entityName="gutter"
    />
  );
}
