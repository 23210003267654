import React, { SyntheticEvent } from 'react';
import { Notifications } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Popover from '@material-ui/core/Popover';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Subscription } from 'rxjs';

import { IInAppNotification } from 'types/entities';
import SystemNotificationsService from 'services/system-notifications.service';
import NotificationsChannel from 'services/channels/notifications.channel';
import NotificationsList from './NotificationsList/NotificationsList';
import NewNotification from './NewNotification/NewNotification';
import { styles } from './inAppNotificationsListStyle';


export interface IInAppNotificationsProps extends WithStyles<typeof styles> {
  notifications: IInAppNotification[];
  loading: boolean;
  loadNotifications: () => void;
  markNotificationsAsRead: (notifications: IInAppNotification[]) => void;
  addNotification: (notification: IInAppNotification, renderCb: (key: string) => React.ReactNode) => void;
  closeNotification: (key: string) => void;
}

interface IInAppNotificationsState {
  anchorEl: HTMLElement|null;
}

class InAppNotifications extends React.Component<IInAppNotificationsProps, IInAppNotificationsState> {
  state = {
    anchorEl: null
  } as IInAppNotificationsState

  private subscription: Subscription;

  componentDidMount() {
    if (this.props.notifications.length === 0)
      this.props.loadNotifications();

    SystemNotificationsService.initialize();
    NotificationsChannel.listen();

    this.subscription = NotificationsChannel.notification$.subscribe(notification => {
      this.props.addNotification(notification, (key: string) =>
        this.renderNewNotification(notification, key)
      );
    });
  }

  componentWillUnmount() {
    NotificationsChannel.stopListening();
    SystemNotificationsService.teardown();

    if (this.subscription)
      this.subscription.unsubscribe();
  }

  render() {
    const { anchorEl } = this.state;
    const { classes, notifications, loading } = this.props;

    return (
      <>
        <Tooltip title="Notifications" placement="bottom">
          <IconButton onClick={this.openPopover}>
            <Badge color="error" badgeContent={notifications.filter(n => !n.read).length} overlap="circle" anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
              <Notifications />
            </Badge>
          </IconButton>
        </Tooltip>

        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={this.closePopover}
          classes={{
            paper: classes.popover
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <NotificationsList notifications={notifications} loading={loading} markAsRead={this.markAsRead} />
        </Popover>
      </>
    )
  }

  private openPopover = (event: SyntheticEvent) => {
    this.setState({
      anchorEl: event.currentTarget as HTMLElement
    });
  }

  private closePopover = () => {
    this.setState({
      anchorEl: null
    });
  }

  private markAsRead = (notifications: IInAppNotification[], close?: true) => {
    this.props.markNotificationsAsRead(notifications);

    if (close)
      this.closePopover();
  }

  private renderNewNotification(notification: IInAppNotification, key: string) {
    return (
      <NewNotification
        notification={notification}
        close={() => this.props.closeNotification(key)}
        markAsRead={() => this.markAsRead([notification])}
      />
    );
  }
}

export default withStyles(styles)(InAppNotifications);
