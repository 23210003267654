import { createSlice, combineReducers } from '@reduxjs/toolkit';
import reduceReducers from 'reduce-reducers';

import { AppThunk } from 'store/store.d';
import { UnitsState, reducer as units, actions as unitsActions } from 'store/entities/units';
import { CategoriesState, reducer as categories, actions as categoriesActions  } from 'store/entities/categories';
import { ItemsState, reducer as items, actions as itemsActions } from 'store/entities/items';
import { FormulasState, reducer as formulas, actions as formulasActions } from 'store/entities/formulas';
import * as selectors from './selectors';


// combined associatedItems state
export interface AssociatedItemsState {
  loading: boolean;
  loaded: boolean;
  units: UnitsState;
  categories: CategoriesState;
  items: ItemsState;
  formulas: FormulasState;
}

const { reducer: originalReducer, actions: originalActions } = createSlice({
  name: 'associatedItems',
  initialState: {
    loading: false,
    loaded: false
  } as AssociatedItemsState,
  reducers: {
    startLoading(state) {
      state.loading = true;
      state.loaded = false;
    },
    finishLoading(state) {
      state.loading = false;
      state.loaded = true;
    }
  }
});

// reducer for the nested entities (aka "associated items")
const nestedEntitiesReducer = combineReducers<AssociatedItemsState>({
  loading: s => s || null,
  loaded: s => s || null,
  items,
  units,
  categories,
  formulas
});

// combine original reducer and nested reducers
const reducer = reduceReducers<AssociatedItemsState>(originalReducer, nestedEntitiesReducer);

// extend actions with a thunk
const actions = {
  ...originalActions,
  loadAssociatedItems: (): AppThunk => async dispatch => {
    dispatch(originalActions.startLoading());

    await Promise.all([
      // each dispatch here actually returns a Promise which always fulfills, no need to catch rejections
      dispatch(itemsActions.read()),
      dispatch(categoriesActions.read()),
      dispatch(unitsActions.read()),
      dispatch(formulasActions.read())
    ]);

    dispatch(originalActions.finishLoading());
  }
};

const { buildFormulasSelector, buildCompositeItemsSelector, buildBasicItemsSelector } = selectors;

// export them all
export {
  reducer,
  actions,
  selectors,
  buildBasicItemsSelector,
  buildCompositeItemsSelector,
  buildFormulasSelector
};
