import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions, selectors } from "store/associatedItems";


export function useAssociatedItems() {
  const dispatch = useDispatch();
  const associatedItemsState = useSelector(selectors.getFullState);
  const { loading, loaded } = associatedItemsState;

  useEffect(() => {
    if (!loaded && !loading)
      dispatch(actions.loadAssociatedItems());
  }, [loading, loaded]);

  return associatedItemsState;
}
