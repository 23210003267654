import { IItem } from 'types/entities';
import ItemsService from 'services/entities/items.service';
import { createEntitySlice, EntitiesState } from 'store/helpers/entities';


export const { actions, reducer, selectors } = createEntitySlice<IItem>({
  name: 'item',
  service: ItemsService,
  selectors: {
    getFullState: state => state.associatedItems.items
  }
});

export type ItemsState = EntitiesState<IItem>;
