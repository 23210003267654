import { useDispatch, useSelector } from "react-redux";


export function useReadAction(actionCreators, selectors, { immediate = true } = {}) {
  const { loading, fetched, error } = useSelector(selectors.getFullState);
  const all = useSelector(selectors.getEntities);
  const byKey = useSelector(selectors.entitiesByKey);
  const dispatch = useDispatch();

  if (immediate && !loading && !fetched)
    dispatchRead();

  return [{
    all,
    byKey,
    loading,
    fetched,
    error
  }, dispatchRead] as [any, any];


  function dispatchRead() {
    dispatch(actionCreators.read());
  }
}
