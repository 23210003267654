import { CaseReducerActions, createSlice, ValidateSliceCaseReducers } from '@reduxjs/toolkit';

import { EntityService } from 'services/entity.service';
import { EntitiesState } from './state';
import {
  createDefaultReducers,
  createDefaultState,
  GenericReducers,
  IDefaultReducers
} from './reducers';
import { createDefaultThunks } from './actions';
import { createDefaultSelectors, IDefaultSelectors, GenericSelectors } from './selectors';

// TODO: remove redux-thunk and reselect; they are included in redux-toolkit now


export type CustomSelectors<T extends Entity, ExtendedT extends T = T> = GenericSelectors & Partial<IDefaultSelectors<T, ExtendedT>>;
export type CustomReducers<T extends Entity> = GenericReducers<T> & Partial<IDefaultReducers<T>>;

interface IEntitySliceOptions<
  T extends Entity,
  Selectors extends CustomSelectors<T, ExtendedT>,
  Reducers extends CustomReducers<T>,
  ExtendedT extends T // extended type needed for some entities that return extended objects with selectors
>{
  name: string;
  service: EntityService<T>;
  showValidationErrors?: boolean;
  allowPartialUpdate?: boolean;
  skipDefaultSelectors?: boolean;
  liveEntityTracking?: boolean;
  reducers?: ValidateSliceCaseReducers<EntitiesState<T>, Reducers>;
  selectors?: IDefaultSelectors<ExtendedT> & Selectors;
}

export function createEntitySlice<
  T extends Entity,
  Selectors extends CustomSelectors<T> = CustomSelectors<T>,
  Reducers extends CustomReducers<T> = CustomReducers<T>,
  ExtendedT extends T = T
>(
  options: IEntitySliceOptions<T, Selectors, Reducers, ExtendedT>
) {
  const {
    name, service, reducers, selectors: customSelectors,
    showValidationErrors, allowPartialUpdate, skipDefaultSelectors, liveEntityTracking
  } = options;

  const slice = createSlice({
    name,
    initialState: createDefaultState<T>(),
    reducers: {
      ...createDefaultReducers<T>() as any, // force `any` because we get some strange TS error here; re-assign the types below
      ...reducers
    }
  });

  const selectors = {
    ...customSelectors,
    ...(!skipDefaultSelectors && createDefaultSelectors<T, ExtendedT>(name, customSelectors))
  };

  const actions = {
    ...slice.actions as CaseReducerActions<IDefaultReducers<T> & Reducers>,
    ...createDefaultThunks<T>(name, slice.actions, service, {
      showValidationErrors,
      allowPartialUpdate,
      liveEntityTracking,
      selectors
    })
  };

  const reducer = slice.reducer;

  return {
    reducer,
    actions,
    selectors
  };
}

