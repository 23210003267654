import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import useStyles from './wasteCalculatorStyles';
import { formatPercentage } from "common/utils/format.util";


export default function WasteCalculator() {
  const [state, setState] = useState(NaN);
  const classes = useStyles();


  const calculatedValues = [.1, .12, .15, .2];

  const updateValue = useCallback(e => {
    const x = e.target.value;
    setState(x ? Math.min(1e6, Math.max(0, +x)) : NaN); // to avoid transforming empty string to zero
  }, [setState]);

  return (
    <div className={classes.calculatorWrapper}>
      <TextField
        variant="outlined"
        color="primary"
        label="Waste Calc"
        value={state >= 0 ? state : ""}
        onChange={updateValue}
        className={classes.textField}
        margin="none"
        size="small"
        type="number"
        InputProps={{
          classes: { input: classes.input }
        }}
      />

      <div className={classes.answersWrapper}>
        { state >= 0 && calculatedValues.map(p => (
          <div className={classes.answerBox}>
            <span className={classes.percentage}>
              { formatPercentage(p, 0) }
            </span>
            <span className={classes.answer}>
              { (state * (1 + p)).toFixed(2) }
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}
