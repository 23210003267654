import { warningColor } from "assets/jss/common";


export default theme => ({
  quantity: {
    width: 50,
    "& > input": {
      textAlign: "center"
    }
  },
  warningIcon: {
    marginLeft: theme.spacing(1),
    color: warningColor[0],
    verticalAlign: "middle",
    cursor: "help"
  }
});
