export default theme => ({
  input: {
    marginTop: theme.spacing(4),

    "& input": {
      padding: "14px"
    }
  },
  select: {
    width: "100%",
    maxWidth: 280,

    "& .customers-select__value-container": {
      padding: "8px 14px",
      fontFamily: theme.typography.fontFamily,
      fontSize: "medium",
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  userSelectCell: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
});
