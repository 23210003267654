import React from "react";
import { RouteChildrenProps, withRouter } from 'react-router';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Menu, MoreVert, ViewList } from "@material-ui/icons";
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import Toolbar from "@material-ui/core/Toolbar";

import NavLinks from "layouts/HomeLayout/NavLinks/NavLinks";
import { getTitleByLocation } from 'common/utils/routing.util';
import Button from "common/components/CustomButtons/Button";
import InAppNotifications from 'common/views/InAppNotifications';
import { styles } from "./homeNavbarStyle";


interface IHomeNavbarProps extends WithStyles<any> {
  minimizedSidebar: boolean;
  logOut: () => void;
  toggleSidebar: () => void;
  toggleSidebarMinimized: () => void;
}

const HomeNavbar: React.FunctionComponent<IHomeNavbarProps & RouteChildrenProps> = (props) => {
  const { classes, minimizedSidebar, toggleSidebar, toggleSidebarMinimized, logOut, location } = props;

  return (
    <AppBar className={classes.appBar} position="static" color="transparent" elevation={0}>
      <Toolbar className={classes.container}>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={toggleSidebar}
          >
            <Menu />
          </Button>
        </Hidden>
        <Hidden smDown implementation="css">
          <div className={classes.minimizeSidebar}>
            <Button
              justIcon
              round
              color="white"
              onClick={toggleSidebarMinimized}
            >
              { minimizedSidebar
                ? <ViewList className={classes.sidebarMiniIcon}/>
                : <MoreVert className={classes.sidebarMiniIcon}/>
              }
            </Button>
          </div>
        </Hidden>
        <div className={classes.content}>
          <Button href="javascript:void(0)" className={classes.title} color="transparent">
            {getTitleByLocation(location)}
          </Button>
        </div>
        <InAppNotifications />
        <NavLinks logOut={logOut} />
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(
  withRouter(HomeNavbar)
);
