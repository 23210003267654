import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// material-ui components
import Button from "@material-ui/core/Button";

import { useStyles } from "./buttonStyle";


const RegularButton = (props) => {
  const classes = useStyles();
  const {
    color,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    btnRef,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className
  });

  return (
    <Button {...rest} classes={muiClasses} className={btnClasses} ref={btnRef}>
      {children}
    </Button>
  );
};

export default RegularButton;
