import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import compact from "lodash/compact";

import { selectors, buildFormulasSelector } from "store/associatedItems";


let tmpId = 0;

export function useFormulasGroup(item, basicGroup) {
  const { itemsByKeys } = selectors;
  const formulasSelector = useMemo(
    () => buildFormulasSelector(item, basicGroup),
    [item.id, basicGroup]
  );
  const [localUpdated, setLocalUpdated] = useState({});
  const [localCreated, setLocalCreated] = useState([]);
  const itemsById = useSelector(itemsByKeys);
  const formulas = useSelector(formulasSelector);

  const existingFormulas = useMemo(() =>
    compact(
      formulas.map(f => {
        if (localUpdated[f.id] === null)
          return null;
        else {
          f = {
            ...f,
            ...localUpdated[f.id]
          };

          return {
            ...f,
            nestedItem: itemsById[f.nestedItemId],
            item: itemsById[f.itemId]
          };
        }
      })
    ),
    [formulas, localUpdated, itemsById]
  );

  const allFormulas = useMemo(
    () => [
      ...existingFormulas,
      ...localCreated.map(f => ({
        ...f,
        item: itemsById[f.itemId],
        nestedItem: itemsById[f.nestedItemId]
      }))
    ],
    [existingFormulas, localCreated, itemsById]
  );

  const createFormula = useCallback(
    () => setLocalCreated([
      ...localCreated,
      {
        tmpId: ++tmpId,
        factor: 1,
        itemId: item.id,
        nestedItemId: ''
      }
    ]),
    [localCreated, setLocalCreated]
  );

  const patchFormula = useCallback(
    (formula, patch) => {
      if (formula.tmpId)
        setLocalCreated(
          localCreated.map(f => {
            if (f.tmpId === formula.tmpId)
              return {
                ...f,
                ...patch
              };
            else
              return f;
          })
        );
      else if (formula.id)
        setLocalUpdated({
          ...localUpdated,
          [formula.id]: {
            ...localUpdated[formula.id],
            ...patch
          }
        });
    },
    [localCreated, localUpdated, setLocalCreated, setLocalUpdated]
  );

  const removeFormula = useCallback(
    formula => {
      if (formula.tmpId)
        setLocalCreated(
          localCreated.filter(f => f.tmpId !== formula.tmpId)
        );
      else if (formula.id)
        setLocalUpdated({
          ...localUpdated,
          [formula.id]: null
        });
    },
    [localCreated, localUpdated, setLocalCreated, setLocalUpdated]
  );

  const resetChanges = useCallback(() => {
    setLocalUpdated({});
    setLocalCreated([]);
  }, [setLocalUpdated, setLocalCreated]);

  return [
    {
      all: allFormulas,
      localUpdated,
      localCreated,
      hasChanges: localCreated.length > 0 || Object.keys(localUpdated).length > 0
    },
    {
      create: createFormula,
      patch: patchFormula,
      remove: removeFormula,
      cancel: resetChanges
    }
  ];
}
